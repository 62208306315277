<template>
  <v-container class="layout-container">
    <v-layout v-if="isDownloading" style="position: fixed; width: 100vw; height: 100vh; left: 0; top: 0; z-index: 9998; background: rgba(0,0,0,0.4)">
      <div class="text-xs-center" style="position: absolute; left: 50%; top: 50%; z-index: 9999; transform: translate(-50%, -50%)">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-layout>
    <v-layout wrap>
      <v-flex class="text-xs-left bottom-wrapper" xs12>
        <div class="bottom-content p-t-16 m-x-auto">
          <v-layout>
            <v-flex style="position: relative;"><h3 class="title p-y-16">설문지 리스트</h3></v-flex>
            <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
          </v-layout>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="getSurveyList"
            :pagination.sync="pagination"
            select-all
            item-key="surveyId"
            class="elevation-1 m-t-30"
            no-data-text="작성한 설문이 없습니다."
            :search="search"
          >
            <template v-slot:headers="props">
              <tr class="m-t-16">
                <th style="width: 25px; box-sizing: border-box;">
                  <v-checkbox
                    :input-value="props.all"
                    :indeterminate="props.indeterminate"
                    primary
                    hide-details
                    @click.stop="toggleAll"
                  ></v-checkbox>
                </th>
                <th
                  v-for="(header, index) in props.headers"
                  :key="header.text"
                  :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                    index === 0 ? 'text-xs-left min-width' : 'text-xs-center',
                  ]"
                  :style="header.width !== undefined ? 'width: ' + header.width + 'px' : ''"
                  @click="changeSort(header.value)"
                >
                  {{ header.text }}
                  <v-icon small>arrow_upward</v-icon>
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr>
                <td class="text-xs-center" :active="props.selected" @click="props.selected = !props.selected">
                  <v-checkbox
                    :input-value="props.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td class="text-xs-left title-column">
                  <span class="p-t-16 d-block">{{ props.item.contents }}</span>
                  <div class="p-y-10 m-t-10">
                    <span
                      class="text-xs-left cursor-pointer"
                      @click="showLinkDialog(props.item)"
                      style="border: 1px solid #aaa; padding: 5px; border-radius: 3px;"
                    ><i class="fas fa-check" style="color: #444;"></i>&nbsp;&nbsp;클릭하여 설문 링크 확인</span>
                  </div>
                  <span class="option-span m-t-10 d-block">설문 공개: {{ props.item.isUse ? '공개' : '비공개' }}</span>
                  <span class="option-span m-t-10 d-block">답변자별 최대 답변횟수: {{ props.item.maxResponseCnt > 0 ? props.item.maxResponseCnt + ' 회' : '제한없음' }}</span>
                  <span class="option-span m-t-10 m-b-16 d-block">질문 번호 자동: {{ props.item.isAutoSeq ? '가능' : '불가능' }}</span>
                </td>
                <td class="text-xs-center">{{ convertDate(props.item.creationDate).substr(0,10) }}<br>{{ convertDate(props.item.creationDate).substr(11,8) }}</td>
                <td class="text-xs-center">{{ props.item.isEditable ? '편집 중' : '제출 완료' }}</td>
                <td class="text-xs-center">{{ props.item.startDate }}<br>~<br>{{ props.item.endDate }}</td>
                <td class="text-xs-center">
                  답변: {{ props.item.respondentCnt }}<br>
                  중도탈락: {{props.item.dropOutsCnt }}
                </td>
                <td class="text-xs-center">
                  <v-menu
                    max-width="200"
                    content-class="my-survey-list-menu"
                    class="d-inline-block" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="props.item.respondentCnt === 0 && props.item.dropOutsCnt === 0" class="d-inline-block" flat v-on="on">{{ props.item.isEditable ? '편집 중' : (props.item.respondentCnt === 0 && props.item.dropOutsCnt === 0) ? '답변 없음' : '답변 결과' }}</v-btn>
                    </template>
                    <v-list max-width="200">
                      <v-list-tile v-if="props.item.respondentCnt > 0">
                        <v-list-tile-title
                          class="cursor-pointer"
                          @click="$router.push({ name: 'Statistics', params: { 'surveyId': props.item.surveyId, 'survey': props.item, 'isDropouts': 0 }})"
                        >통계보기 - 답변자</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile v-if="props.item.respondentCnt > 0">
                        <v-list-tile-title
                          class="cursor-pointer"
                          @click="download(props.item.surveyId, false)"
                        >다운로드 - 답변자</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile v-if="props.item.dropOutsCnt > 0">
                        <v-list-tile-title
                          class="cursor-pointer"
                          @click="$router.push({ name: 'Statistics', params: { 'surveyId': props.item.surveyId, 'survey': props.item, 'isDropouts': 1 }})"
                        >통계보기 - 중도탈락</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile v-if="props.item.dropOutsCnt > 0">
                        <v-list-tile-title
                          class="cursor-pointer"
                          @click="download(props.item.surveyId, true)"
                        >다운로드 - 중도탈락</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                  <v-menu
                    max-width="130"
                    content-class="my-survey-list-menu"
                    class="d-inline-block" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn flat class="d-inline-block" v-on="on">설문 관리</v-btn>
                    </template>
                    <v-list max-width="130">
                      <v-list-tile>
                        <v-list-tile-title
                          class="cursor-pointer"
                          :disabled="props.item.isEditable"
                          @click="movePage(props.item.surveyId, true)"
                        >설문 편집</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile>
                        <v-list-tile-title
                          class="cursor-pointer"
                          @click="copySurvey(props.item.surveyId, false)"
                        >설문 복사</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
                "{{ search }}" 에 관한 설문이 없습니다.
            </template>
          </v-data-table>
          <copy-link
            :link-survey="linkSurvey"
            :link-dialog-visible="linkDialogVisible"
            v-if="isLinkDialogVisible"
          ></copy-link>
          <div class="text-xs-right m-t-16">
            <delete-component
              :is-surveys="false"
              :select-id-list="selected.map(s => { return s.surveyId })"
              :btn-disabled="selected.length === 0"
            ></delete-component>
            <v-btn class="m-r-0" flat color="primary" @click="movePage()">설문 추가</v-btn>
          </div>
        </div>
        <a style="display: none;" id="downloadLink"></a>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { surveyMapActions, surveyMapGetters } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'
import { axiosConfig } from '../../api/index'
import DeleteComponent from '@/components/common/DeleteComponent'
import CopyLink from '@/components/admin/CopyLink'
import dateUtils from '../../utils/date'

export default {
  data () {
    return {
      search: '',
      pagination: {},
      // linkSurveyId: 0,
      isLinkDialogVisible: false,
      selected: [],
      isDownloading: false,
      headers: [
        {
          text: '설문 제목',
          align: 'left',
          sortable: false,
          value: 'contents'
        },
        {
          text: '　 생성일시',
          align: 'center',
          sortable: false,
          value: 'createDate',
          width: 120
        },
        {
          text: '　 상태값',
          align: 'center',
          sortable: false,
          value: 'isEditable',
          width: 120
        },
        {
          text: '　 기간',
          align: 'center',
          sortable: false,
          value: 'startDate',
          width: 100,
          minWidth: 116
        },
        {
          text: '　 답변건수',
          align: 'center',
          sortable: false,
          value: 'startDate',
          width: 50,
          minWidth: 0
        },
        {
          text: '　 편집',
          align: 'center',
          sortable: false,
          value: 'surveyEdit',
          width: 150
        },
      ]
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyList', 'getQuestionList', 'getSectionList']),
    ...accountMapGetters(['getToken'])
  },
  methods: {
    ...surveyMapActions(['fetchSurveyList', 'downloadSurveyAnswerExcel', 'downloadSurveyAnswerExcelForDropouts']),
    convertDate (date) {
      return dateUtils.setHoursFromDB(date)
    },
    copySurvey (id, isEdit) {
      let isTrue = confirm('이 설문을 복사하시겠습니까?')
      if (isTrue) {
        this.movePage(id, isEdit, true)
      }
    },
    showLinkDialog (survey) {
      this.linkDialogVisible(true)
      this.linkSurvey = survey
    },
    linkDialogVisible (isBoolean) {
      this.isLinkDialogVisible = isBoolean
    },
    dateFormatKorea (timeString) {
      if (timeString !== null && timeString.length === 4) {
        let hour = timeString.substring(0, 2)
        let minute = timeString.substring(2, 4)
        if (hour < 12) {
          hour = hour === '00' ? 12 : hour
          return `오전 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        } else {
          hour = hour - (hour > 12 ? 12 : 0)
          return `오후 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        }
      } else if (timeString !== null && timeString.length === 5) {
        let hour = timeString.substring(0, 2)
        let minute = timeString.substring(3, 5)
        if (hour < 12) {
          hour = hour === '00' ? 12 : hour
          return `오전 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        } else {
          hour = hour - (hour > 12 ? 12 : 0)
          return `오후 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        }
      } else {
        return timeString
      }
    },
    downloading (response) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
      const link = document.getElementById('downloadLink')
      link.href = url
      link.setAttribute('download', 'U2Survey_' + new Date().getTime() + '.xlsx')
      document.body.appendChild(link)
      link.click()
      this.isDownloading = false
    },
    download (surveyId, isDropouts) {
      this.isDownloading = true
      if (isDropouts) {
        this.downloadSurveyAnswerExcelForDropouts({
          surveyId: surveyId,
          config: {
            headers: {
              'Authorization': `Bearer ${this.getToken}`
            },
            'responseType': 'blob'
          }
        }).then((response) => {
          this.downloading(response)
        }).catch((err) => {
          this.isDownloading = false
          throw Error(err)
        })
      } else {
        this.downloadSurveyAnswerExcel({
          surveyId: surveyId,
          config: {
            headers: {
              'Authorization': `Bearer ${this.getToken}`
            },
            'responseType': 'blob'
          }
        }).then((response) => {
          this.downloading(response)
        }).catch((err) => {
          this.isDownloading = false
          throw Error(err)
        })
      }
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.getSurveyList.slice()
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    movePage (id, isEdit, isCopy) {
      if (id !== 1 && !id) {
        this.$router.push({name: 'SurveyAdmin', params: {surveyId: 0}})
      } else {
        this.$router.push({name: 'SurveyAdmin', params: {surveyId: id, isEdit: isEdit, isCopy: isCopy}})
      }
    }
  },
  mounted () {
    this.fetchSurveyList({
      config: axiosConfig.setHeader(this.getToken)
    }).catch((err) => { throw Error(err) })
  },
  components: {
    'delete-component': DeleteComponent,
    'copy-link': CopyLink
  }
}
</script>

<style lang="scss" scope>
@import '../../assets/style/variable.scss';
  .my-survey-list-menu.v-menu__content{
    min-width: 90px !important;
  }
  .elevation-1{
    position: relative;
    z-index: 1;
    background-color: #fff;
    min-width: 800px;
    .width-auto {
      width: auto;
    }
    .min-width {
      min-width: 210px;
    }
    table.v-table {
      tbody{
        td:not(:first-child){
          padding: 0 10px;
          .v-btn{
            padding: 0 10px;
          }
        }
      }
    }
  }
  .top-wrapper{
    position: relative;
    &:before{
      display: block; content: ''; clear: both;
      position: absolute; left: calc(((100vw - 1155px) / 2) * -1); top: -55px;
      background-color: #efefef;
      width: 200vw; height: calc(100% + 120px);
      z-index: 0;
    }
    .top-content{
      position: relative;
      z-index: 1;
    }
  }
  .bottom-wrapper {
    margin-top: 20px;
    .bottom-content {
      max-width: 1185px;
    }
    .create-survey-btn {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);
    }
  }
  .title-column {
    span:first-child{
      max-height: 54px;
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
    .option-span {
      position: relative;
      padding-left: 7px;
      &:before{
        display: block; content: ''; clear: both;
        position: absolute; left: 0; top: 50%; transform: translateY(-50%);
        width: 2px; height: 2px;
        background-color: #444;
        border-radius: 50%;
      }
    }
  }
  @media screen and (max-width: $tablet) {
    table.v-table.elevation-1 thead {
      th,
      td span,
      .v-btn{
        font-size: 12px;
      }
      th, td{
        padding: 0 10px;
      }
      table.v-table thead tr {
        height: 46px;
      }
    }
  }
  @media screen and (max-width: $mobile) {
    table.v-table.elevation-1 thead {
      th,
      td span,
      .v-btn{
        font-size: 10px;
      }
      th, td{
        padding: 0 7px;
      }
      table.v-table thead tr {
        height: 40px;
      }
    }
  }
</style>

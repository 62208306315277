import axios from 'axios'

// const api = axios.create({
//   auth: {
//     username: 'svUser',
//     password: 'svUser~!@#'
//   },
//   baseURL: 'https://ws.healthwallet.co.kr/u2survey'
// })

const api = axios.create({
  baseURL: 'https://u2s-admin-api.u2cloud.co.kr/u2s-api-admin'
})


const axiosConfig = {
  setHeader (token) {
    return {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  }
}

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let errorStatus = error.response.status
    if (errorStatus === 403) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/u2s-web-admin/admin-login'
    }
    return Promise.reject(error)
  }
)

export default api
export { axiosConfig }

import SurveyAPI from '../../../api/api-survey'

export default {
  async fetchSurveyList ({ commit }, { config }) {
    try {
      const response = await SurveyAPI.selectSurveyList(config)
      commit('setSurveyList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async resetQuestionList ({ commit }) {
    try {
      commit('setSectionList', [])
      commit('setOriginSectionList', [])
      commit('setQuestionList', [])
      commit('setOriginQuestionList', [])
    } catch (error) {
      throw Error(error)
    }
  },

  async setLinkQuestionList ({ commit }, param) {
    try {
      commit('setLinkQuestionList', param)
    } catch (error) {
      throw Error(error)
    }
  },

  async setQuestionListInStore ({ commit }, param) {
    try {
      commit('setQuestionList', param)
    } catch (error) {
      throw Error(error)
    }
  },

  async setSectionListInStore ({ commit }, param) {
    try {
      commit('setSectionList', param)
    } catch (error) {
      throw Error(error)
    }
  },

  async setOriginSectionListInStore ({ commit }, param) {
    try {
      commit('setOriginSectionList', param)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchSurveyData ({ commit }, { surveyId, config }) {
    try {
      const sectionsResponse = await SurveyAPI.selectSectionList(surveyId)
      const questionsResponse = await SurveyAPI.selectQuestionList(surveyId, config)
      commit('setSectionList', sectionsResponse.data)
      commit('setOriginSectionList', sectionsResponse.data)
      commit('setQuestionList', questionsResponse.data)
      commit('setOriginQuestionList', questionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchSectionList ({ commit }, { surveyId, config }) {
    try {
      const sectionsResponse = await SurveyAPI.selectSectionList(surveyId, config)
      commit('setSectionList', sectionsResponse.data)
      commit('setOriginSectionList', sectionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchQuestionList ({ commit }, { surveyId, config }) {
    try {
      const questionsResponse = await SurveyAPI.selectQuestionList(surveyId, config)
      commit('setQuestionList', questionsResponse.data)
      commit('setOriginQuestionList', questionsResponse.data)
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchAnswerListByRespondent ({ commit }, { respondent, config }) {
    try {
      const response = await SurveyAPI.selectAnswerListByRespondent(respondent, config)
      commit('setAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchAnswerByAnswerId ({ commit }, { answerId, config }) {
    try {
      const response = await SurveyAPI.selectAnswerByAnswerId(answerId, config)
      commit('setAnswer', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchRespondentSurveyAnswerList ({ commit }, { surveyId, config }) {
    try {
      const response = await SurveyAPI.selectRespondentSurveyAnswerList(surveyId, config)
      commit('setRespondentSurveyAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchRespondentSurveyAnswerListForDropouts ({ commit }, { surveyId, config }) {
    try {
      const response = await SurveyAPI.selectRespondentSurveyAnswerListForDropouts(surveyId, config)
      commit('setRespondentSurveyAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchRespondentSurveyAnswers ({ commit }, { answerId, config }) {
    try {
      const response = await SurveyAPI.selectRespondentSurveyAnswers(answerId, config)
      commit('setRespondentSurveyAnswers', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchRespondentSurveyAnswersForDropouts ({ commit }, { answerId, config }) {
    try {
      const response = await SurveyAPI.selectRespondentSurveyAnswersForDropouts(answerId, config)
      commit('setRespondentSurveyAnswers', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  setRespondentSurveyAnswersReset({ commit }) {
    commit('setRespondentSurveyAnswers', [])
    commit('setSurveyAnswerList', [])
    commit('setRespondentSurveyAnswerList', [])
  },

  async fetchSurveyAnswerList ({ commit }, { surveyId, config }) {
    try {
      const response = await SurveyAPI.selectSurveyAnswerList(surveyId, config)
      commit('setSurveyAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async fetchSurveyAnswerListForDropsouts ({ commit }, { surveyId, config }) {
    try {
      const response = await SurveyAPI.selectSurveyAnswerListForDropsouts(surveyId, config)
      commit('setSurveyAnswerList', response.data)
      return response
    } catch (error) {
      throw Error(error)
    }
  },

  async downloadSurveyAnswerExcel ({ commit }, { surveyId, config }) {
    try {
      return await SurveyAPI.selectSurveyAnswerExcel(surveyId, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async downloadSurveyAnswerExcelForDropouts ({ commit }, { surveyId, config }) {
    try {
      return await SurveyAPI.selectSurveyAnswerExcelForDropouts(surveyId, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async updateSurvey ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.updateSurvey(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async updateSection ({ commit }, { params, config }) {
    try { 
      return await SurveyAPI.updateSection(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async updateQuestion ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.updateQuestion(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async updateChoices ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.updateChoices(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  setOriginQuestionListForVue  ({ commit }, { params }) {
    commit('setOriginQuestionList', params)
  },

  // 사용자 답변 저장. 사용하지 않는 상태.
  // async createAnswer ({ commit }, { params, config }) {
  //   try {
  //     return await SurveyAPI.createAnswer(params)
  //   } catch (error) {
  //     throw Error(error)
  //   }
  // },

  async insertSurvey ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.insertSurvey(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async insertSection ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.insertSection(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async insertQuestion ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.insertQuestion(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async insertChoices ({ commit }, { params, config }) {
    try {
      return await SurveyAPI.insertChoices(params, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async deleteSurvey ({ commit }, { surveyId, config }) {
    try {
      return await SurveyAPI.deleteSurvey(surveyId, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async deleteSection ({ commit }, { sectionId, config }) {
    try {
      return await SurveyAPI.deleteSection(sectionId, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async deleteQuestion ({ commit }, { questionId, config }) {
    try {
      return await SurveyAPI.deleteQuestion(questionId, config)
    } catch (error) {
      throw Error(error)
    }
  },

  async deleteChoices ({ commit }, { choiceId, config }) {
    try {
      return await SurveyAPI.deleteChoices(choiceId, config)
    } catch (error) {
      throw Error(error)
    }
  }
}

<template>
  <v-layout class="layout-container">
    <v-flex class="m-t-30" xs12 sm8 offset-sm2>
      <v-card style="max-width: 750px; margin: auto; margin-top: 20%;" class="p-a-16">
        <h2 class="text-xs-center headline m-y-30">로그인</h2>
        <form class="p-a-16">
          <v-text-field
            v-model.trim="email"
            required
            label="이메일"
          ></v-text-field>
          <v-text-field
            v-model.trim="password"
            required
            type="password"
            label="비밀번호"
            @keyup.enter="submit"
          ></v-text-field>
          <v-checkbox class="m-t-0" v-model="isAutoLogin" label="자동 로그인"></v-checkbox>
          <v-btn @click="submit">Login</v-btn>
        </form>
        <div class="text-xs-right p-a-16">
          <!-- span @click="findIdPw" class="cursor-pointer">ID/PW 찾기</span-->
          <span @click="join" class="subheading cursor-pointer p-r-16">회원가입</span>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { accountMapGetters, accountMapActions } from '@/store/modules/account/account'

export default {
  data () {
    return {
      email: '',
      password: '',
      isAutoLogin: false
    }
  },
  computed: {
    ...accountMapGetters(['getToken', 'getLogin'])
  },
  methods: {
    ...accountMapActions(['login', 'logout']),
    join () {
      window.open('https://portal.u2cloud.co.kr/', '_blank')
    },
    submit () {
      this.login({
        params: {
          loginId: this.email,
          loginPw : this.password
        },
        isAutoLogin: this.isAutoLogin
      }).then(() => {
        this.$router.push({name: 'MySurveyList'})
      }).catch((err) => {
        alert(err.response.data.message)
      })
    }
  },
  beforeMount () {
    this.logout()
    if (this.getToken !== null) this.$router.push({name: 'MySurveyList'})
  }
}
</script>


export default {
  setToken (state, token, isAutoLogin) {
    if (token === null) {
      localStorage.removeItem('main-token-survey')
    } else {
      if (isAutoLogin) {
        sessionStorage.setItem('main-token-survey', token)
      } else {
        localStorage.setItem('main-token-survey', token)
      }
    }
    state.token = token
  },
  setLogin (state, payload) {
    state.isLogin = payload
  },
  setLoginId (state, payload, isAutoLogin) {
    let id
    if (payload === null) {
      localStorage.removeItem('survey-login-id')
    } else {
      id = payload.replace('u2bio12#', '')
      if (isAutoLogin) {
        sessionStorage.setItem('survey-login-id', id)
      } else {
        localStorage.setItem('survey-login-id', id)
      }
    }
    state.loginId = id
  }
}

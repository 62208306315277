import Vue from 'vue'
import Router from 'vue-router'
import SurveyAdmin from '@/views/SurveyAdmin'
import SurveyAdminFinish from '@/components/admin/SurveyAdminFinish'
import AdminJoin from '@/components/account/Join'
import AdminJoinFinish from '@/components/account/JoinFinish'
import AdminLogin from '@/components/account/Login'
import Statistics from '@/components/account/Statistics'
import MySurveyList from '@/components/account/MySurveyList'

Vue.use(Router)

export default new Router({
  base: '/',
  routes: [
    {
      path: '/survey-admin/survey-id/:surveyId',
      name: 'SurveyAdmin',
      meta: { isPublic: false },
      component: SurveyAdmin
    },
    {
      path: '/survey-admin-finish',
      name: 'SurveyAdminFinish',
      meta: { isPublic: false },
      component: SurveyAdminFinish
    },
    {
      path: '/join',
      name: 'AdminJoin',
      meta: { isPublic: true },
      component: AdminJoin
    },
    {
      path: '/join-finish',
      name: 'AdminJoinFinish',
      meta: { isPublic: true },
      component: AdminJoinFinish
    },
    {
      path: '/statistics/:surveyId/:isDropouts?',
      name: 'Statistics',
      meta: { isPublic: false },
      component: Statistics
    },
    {
      path: '/my-survey-list',
      name: 'MySurveyList',
      meta: { isPublic: false },
      component: MySurveyList
    },
    {
      path: '/admin-login',
      name: 'AdminLogin',
      meta: { isPublic: true },
      component: AdminLogin
    },
    {
      path: '/',
      name: 'SurveyMain',
      meta: { isPublic: true },
      component: AdminLogin
    }
  ]
})

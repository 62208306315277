<template>
  <v-layout wrap>
    <v-flex xs12>
      <quill-editor
        ref="myQuillEditor"
        v-model="title"
        :class="{ null: (title === undefined || title === '') && !isFirst }"
        :options="editorOption"
        :input="inputEvent()"
        @ready="onEditorReady($event)"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Editor',
  props: {
    isFirst: Boolean,
    titleData: String
  },
  data () {
    return {
      editorOption: {
        modules: {
          // imageResize: {
          //   modules: [ 'Resize', 'DisplaySize' ]
          // },
          toolbar: '#toolbar-container',
          toolbar: [
            ['image', 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }, { 'size': ['small', false, 'large', 'huge'] }] // toggled buttons
          ]
        },
      },
      title: this.titleData
    }
  },
  methods: {
    onEditorReady (quill) {
      quill.focus()
    },
    inputEvent () {
      this.$emit('update:titleData', this.title)
    }
  },
  components: {
    'quill-editor': quillEditor
  },
  watch: {
    titleData () {
      this.title = this.titleData
    }
  },
  updated () {
    this.$refs.myQuillEditor.quill.focus()
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  }
}
</script>

<style lang="scss">
.quill-editor{
  &:focus-within{
    box-shadow: 0px 0px 5px #91d6ff;
  }
  &:focus-within .ql-snow{
    border: 1px solid #91d6ff;
  }
  &:focus-within .ql-toolbar.ql-snow{
    border-bottom: 1px solid #ccc;
  }
  .ql-container.ql-snow{
    height: 170px;
  }
}
.quill-editor.null{
  position: relative;
  box-shadow: 0px 0px 5px #ec9797;
  .ql-snow{
    border-color: #ec9797;
  }
  .ql-toolbar.ql-snow{
    border-bottom-color: #ccc;
  }
  &:after{
    display: block; content: '\f071'; clear: both;
    position: absolute;
    right: 5px; bottom: 5px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #ec9797;
  }
}
</style>

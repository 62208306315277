import Vue from 'vue'
import Vuex from 'vuex'
import survey from './modules/survey/survey'
import account from './modules/account/account'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    survey,
    account
  }
})

<template>
  <v-layout class="input-flex">
    <v-textarea v-if="subAnswer === undefined"
      outline disabled
      class="text-input"
      :rules="textRules"
      @change="insertTxt(question)"
    ></v-textarea>
    <v-textarea v-else-if="question.questionType === 7 && subAnswer !== undefined"
      outline
      class="text-input"
      :rules="textRules"
      @change="insertTxt(question)"
      v-model.trim="subAnswer[0]"
    ></v-textarea>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerTextarea',
  props: {
    insertTxt: Function,
    question: Object,
    subAnswer: Object
  },
  data () {
    return {
      textRules: [
        v => !(/[¸|ː|§]/.test(v)) || '해당 특수문자는 사용할 수 없습니다.'
      ]
    }
  }
}
</script>

<style>
  .text-input{
    box-sizing: border-box;
  }
  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea{
    margin-top: 0;
  }
</style>

<template>
  <v-layout v-if="list !== null" class="respondant-section" style="overflow: auto; border: 1px solid #44444430;">
    <ul class="width-p-100" style="padding-left: 0; height: 100%;">
      <li class="data-table-wrap text-xs-left" v-if="list.length === 0">데이터 없음</li>
      <li
        v-else
        :class="isRespondent? 'cursor-pointer' : ''"
        v-for="(data, idx) in list"
        :key="idx"
        class="data-table-wrap text-xs-left"
        @click="isRespondent? showResult(data.answerId) : ''"
      >
        <span class="data-table-cell text-xs-center">{{idx + 1}}</span>
        <div v-if="isRespondent" class="data-table-cell">
          <span v-if="data.respondent !== ''">
            {{data.respondent}}<br>
          </span>
          <span v-else>
            (익명)<br>
          </span>
          <span>
            {{ returnTime(data.creationDate) }}
          </span>
        </div>
        <span v-else class="data-table-cell text-pre">{{data}}</span>
      </li>
    </ul>
  </v-layout>
</template>

<script>
import dateUtils from '../../utils/date'
export default {
  props: {
    list: Array,
    isRespondent: Boolean,
    showResult: Function
  },
  methods: {
    returnDate (responseDate) {
      return responseDate.substr(0, 10)
    },
    returnTime (responseDate) {
      return dateUtils.setHoursFromDB(responseDate)
    }
  }
}
</script>

<style lang="scss" scope>
@import '../../assets/style/variable.scss';

.respondant-section {
  max-height: 70vh;
}
@media screen and (max-width: $tablet) {
  .respondant-section {
    max-height: 300px;
  }
}

.data-table{
  &-wrap{
    display: table;
    width: 100%;
    border-bottom: 1px solid #ddd;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &-cell{
    display: table-cell;
    padding: 20px 20px;
    word-break: break-all;
    &:first-child{
      width: 60px;
      background-color: #eee;
    }
    &:last-child{
      width: calc(100% - 60px);
    }
  }
}
</style>

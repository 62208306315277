<template>
  <v-card style="position: fixed; width: 380px; height: auto; max-height: 70%; overflow-y: auto; left: 50%; top: 50%; transform: translate(-50%, -50%); background-color: #fff; z-index: 1; border: 1px solid #ddd;">
    <v-card-title>
      <v-flex xs12 text-xs-right>
        <v-icon @click="changeLinkedDialog(false)" color="normal" large center>close</v-icon>
      </v-flex>
      <h2 v-if="linkQuestionList.length > 0" class="title text-xs-left">'{{choices[idx].content}}' 답변 선택의 하위 질문으로 만들 번호를 선택하세요</h2>
      <p style="text-align: left;" v-else>해당 질문의 하위 질문으로 사용할 수 있는 질문이 없습니다.<br><br>- 이전 질문에서 하위 질문으로 선택한 질문은 해당 질문에서 하위 질문으로 사용할 수 없습니다.<br>- 해당 질문의 이전 페이지 및 질문은 하위 질문으로 사용할 수 없습니다.</p>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 text-xs-left v-for="linkQuestion in linkQuestionList" :key="linkQuestion.questionId">
            <div style="overflow-y: hidden;">
              <v-checkbox
                v-model="choices[idx].linkedQ"
                style="vertical-align: bottom; text-overflow: ellipsis; overflow: hidden; max-width: 30%; height: 1.75em;  white-space: nowrap;"
                class="d-inline-block m-t-0"
                :value="linkQuestion.questionId"
                :label="linkQuestion.displayNo === '' || linkQuestion.displayNo == 0 || !linkQuestion.displayNo ? '' : linkQuestion.displayNo.toString()"
                hide-details
              ></v-checkbox>
              <div @click="linkTitleClick(linkQuestion.questionId, idx)" class="d-inline-block cursor-pointer" v-html="linkQuestion.questionTitle" style="vertical-align: top; margin-top: 7px; margin-left: 10px; text-overflow: ellipsis; overflow: hidden; max-width: 70%; height: 1.55em;  white-space: nowrap;"></div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :class="{'blue lighten-4': true }" @click="changeLinkedDialog(false)">입력</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'AnswerLinkedQuestion',
  props: {
    question: Object,
    questions: Array,
    idx: Number,
    choices: Array,
    currentQuestion: Number,
    changeLinkedBtn: Function,
    changeLinkedDialog: Function,
    currentSection: Number,
    sectionList: Array,
    setSequence: Function,
    survey: Object
  },
  data () {
    return {
      linkQuestionList: []
    }
  },
  methods: {
    linkTitleClick (linkId, clickIdx) {
      let findIdx = this.choices[clickIdx].linkedQ.indexOf(linkId)
      if (findIdx === -1) {
        this.choices[clickIdx].linkedQ.push(linkId)
      } else {
        this.choices[clickIdx].linkedQ.splice(findIdx, 1)
      }
    },
    initQuestionList () {
      this.linkQuestionList = []
      let thisSection = this.sectionList.filter(section => { return section.sectionId === this.currentSection })[0]
      let thisQuestions = this.questions.filter(q => { return q.sectionId === this.sectionList[thisSection.sectionSeq - 1].sectionId && q.questionType !== 8 && (this.survey.isAutoSeq || (q.displayNo !== undefined && q.displayNo !== '')) })
      for (let i = 0; i < thisQuestions.length; i++) {
        if (this.currentQuestion !== -1 && i >= this.question.seqInSection && !_.isEqual(thisQuestions[i].questionId, this.question.questionId)) {
          this.changeLinkedBtn(true)
          if (!this.linkQuestionList.includes(thisQuestions[i])) this.linkQuestionList.push(thisQuestions[i])
        }
      }
      for (let i = (thisSection.sectionSeq - 1); i < this.sectionList.length; i++) {
        thisQuestions = this.questions.filter(q => { return q.sectionId !== thisSection.sectionId && q.sectionId === this.sectionList[i].sectionId && q.questionType !== 8 && (this.survey.isAutoSeq || (q.displayNo !== undefined && q.displayNo !== '')) && (!_.isEqual(q.questionId, this.question.questionId)) })
        if (thisQuestions.length === 0) continue
        for (const q of thisQuestions) {
          this.changeLinkedBtn(true)
          if (!this.linkQuestionList.includes(q)) this.linkQuestionList.push(q)
        }
      }
    },
    setQuestionList () {
      for (const linkQuestion of this.linkQuestionList) {
        for (const q of this.questions) {
          if (this.question.questionId === q.questionId || !q.choiceItems || q.choiceItems.length === 0) continue
          for (const choice of q.choiceItems) {
            if (choice.linkedQ === undefined || choice.linkedQ.length === 0) continue
            choice.linkedQ.forEach(link => {
              if (linkQuestion.questionId === link) this.linkQuestionList = _.differenceWith(this.linkQuestionList, [linkQuestion], _.isEqual)
            })
          }
        }
      }
    }
  },
  created () {
    this.setSequence(true)
    this.initQuestionList()
    this.setQuestionList()
  }
}
</script>

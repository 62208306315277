<template>
  <v-layout wrap>
    <v-flex xs12>
      <div>
        <v-text-field
          label="이미지 선택"
          @click='onPickFile'
          v-model='fileName'
          prepend-icon="attach_file"
        ></v-text-field>
        <!-- Hidden -->
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept="image/*"
          multiple
          @change="onFilePicked">
      </div>
      <v-layout row wrap>
        <v-flex xs12 text-xs-left>
          <v-btn-toggle v-model="toggle_exclusive">
            <v-btn flat>
              <v-icon>format_align_left</v-icon>
            </v-btn>
            <v-btn flat>
              <v-icon>format_align_center</v-icon>
            </v-btn>
            <v-btn flat>
              <v-icon>format_align_right</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-radio-group class="d-block" v-model="isPxControll" hide-details>
            <v-radio label="픽셀 조정" :value="true"></v-radio>
            <v-radio label="퍼센트 조정" :value="false"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-layout v-if="isPxControll">
        <v-flex xs6>
          <v-text-field hide-details :readonly="url === null" placeholder="이미지를 선택해주세요." label="넓이 (px)" v-model="widthValidator" max="1080" min="0" type="number"></v-text-field>
          <p style="font-size: 12px; margin: 5px 0; text-align: left; color: #aaa;">사이즈는 최대 1080px 이하만 가능합니다.</p>
        </v-flex>
      </v-layout>
      <v-layout v-else>
        <v-flex xs6>
          <v-text-field hide-details :readonly="url === null" placeholder="이미지를 선택해주세요." label="설문 화면 대비 넓이 (%)" v-model="percentageValidator" max="100" min="0" type="number"></v-text-field>
          <p style="font-size: 12px; margin: 5px 0; text-align: left; color: #aaa;">사이즈는 최대 100% 이하만 가능합니다.</p>
        </v-flex>
      </v-layout>
      <img id="preview-image" style="max-width: 100%;" :src="url">
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  props: {
    titleData: String,
    isSelected: Boolean
  },
  data () {
    return {
      fileName: '',
      url: null,
      fileObject: null,
      imgWidth: null,
      imgHeight: null,
      percentage: '',
      isPxControll: true,
      // num: 0,
      toggle_exclusive: 0
    }
  },
  computed: {
    exclusive() {
      if (this.toggle_exclusive === 0) {
        return 'ql-align-left'
      } else if (this.toggle_exclusive === 1) {
        return 'ql-align-center'
      } else if (this.toggle_exclusive === 2) {
        return 'ql-align-right'
      } else {
        return ''
      }
    },
    heightPercentage () {
      return this.imgHeight / this.imgWidth
    },
    widthPercentage () {
      return this.imgWidth / this.imgHeight
    },
    widthValidator: {
      set (widthValue) {
        let tmp = parseInt(widthValue.trim())
        if (tmp <= 0) this.imgWidth = 0
        else if (tmp >= 1080) this.imgWidth = 1080
        else this.imgWidth = tmp
      },
      get () {
        return this.imgWidth
      }
    },
    percentageValidator: {
      set (percentValue) {
        let tmp = parseInt(percentValue.trim())
        if (tmp <= 0) this.percentage = 0
        else if (tmp >= 100) this.percentage = 100
        else this.percentage = tmp
      },
      get () {
        return this.percentage
      }
    }
  },
  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },
    imageResize () {
      let img = new Image()
      img.src = this.url
      img.addEventListener('load', () => {
        this.imgWidth = img.width > 1080 ? 1080 : img.width
        this.imgHeight = this.imgWidth * (img.height / img.width)
        this.percentage = 100
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        canvas.width = this.imgWidth
        canvas.height = this.imgHeight
        ctx.drawImage(img, 0, 0, this.imgWidth, this.imgHeight)
        this.url = canvas.toDataURL()
      })
    },
    onFilePicked (event) {
      const files = event.target.files
      if (files[0] !== undefined) {
        this.fileName = files[0].name
        // Check validity of file
        if (this.fileName.lastIndexOf('.') <= 0) {
          return
        }
        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.url = fr.result
          this.fileObject = files[0] // this is an file that can be sent to server...
          this.imageResize()
        })
      } else {
        this.fileName = ''
        this.fileObject = null
        this.url = null
      }
    },
    setSize () {
      if (this.isPxControll) {
        this.$emit('update:titleData', '<p class="'+ this.exclusive +'"><img width="' + this.imgWidth + '" src="' + this.url + '" /></p>')
      } else {
        this.$emit('update:titleData', '<p class="'+ this.exclusive +'"><img width="' + this.percentage + '%" src="' + this.url + '" /></p>')
      }
    },
    resizeWidth () {
      this.imgHeight = Math.round(this.imgWidth * this.heightPercentage)
    },
    setInitImage () {
      this.isPxControll = true
      this.toggle_exclusive = 0
      if (this.titleData === '') {
        this.fileName = ''
        this.url = null
        this.fileObject = null
        this.imgWidth = null
        this.imgHeight = null
        this.percentage = ''
        this.isPxControll = true
      } else {
        const srcRegex = /<img.*?src="(.*?)"/.exec(this.titleData)
        const widthRegex = /<img.*?width="(.*?)"/.exec(this.titleData)
        const heightRegex = /<img.*?height="(.*?)"/.exec(this.titleData)
        const excluRegex = /<p.*?class="(.*?)"/.exec(this.titleData)
        if (srcRegex !== null && srcRegex !== undefined) this.url = srcRegex[1]
        if (excluRegex !== null && excluRegex !== undefined) {
          if (excluRegex[1] === 'ql-align-center') {
            this.toggle_exclusive = 1
          } else if (excluRegex[1] === 'ql-align-right') {
            this.toggle_exclusive = 2
          } else {
            this.toggle_exclusive = 0
          } 
        }
        if (widthRegex !== null && widthRegex !== undefined) {
          if (widthRegex[1].includes('%')) {
            let imgObj = new Image()
            this.percentage = parseInt(widthRegex[1].substring(0, widthRegex[1].length - 1))
            this.isPxControll = false
            imgObj.src = this.url
            imgObj.addEventListener('load', () => {
              this.imgWidth = imgObj.width
              this.imgHeight = imgObj.height
              return ''
            })
          } else {
            this.imgWidth = widthRegex[1]
          }
        }
        if (heightRegex !== null && heightRegex !== undefined) this.imgHeight = heightRegex[1]
      }
    }
  },
  watch: {
    isSelected () {
      this.setInitImage()
    }
  },
  mounted () {
    this.setInitImage()
  }
}
</script>

<style lang="scss" scope>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.v-radio{
  float: left;
  display: block;
  width: auto;
}
</style>


<template>
  <v-card style="box-shadow: none;" v-if="question !== undefined" >
    <v-card-title>
      <v-flex xs12 text-xs-right>
        <v-icon @click="changeOpenValue(false); changeMenuDialog(false); changeMovePageDialog(false);" color="normal" large center>close</v-icon>
      </v-flex>
      <h2 class="headline">질문 입력 <span class="body-1">{{type}}</span></h2>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout v-if="question.questionType === 8">
          <div class="width-p-100 m-b-10" text-xs-left>
            <label class="d-block p-y-10" v-for="(line, idx) in lineData.line" :key="idx" :for="'type' + line.choiceValue">
              <input :id="'type' + line.choiceValue" type="radio" name="lineData" :value="line.choiceValue" v-model="lineData.value">
              <div class="d-inline-block width-p-80 m-l-16 m-b-5" v-html="line.content"></div>
            </label>
          </div>
        </v-layout>
        <v-layout wrap v-else>
          <v-text-field
            v-if="question.questionType !== 5 && question.questionType !== 8 && question.questionType !== 9 && !survey.isAutoSeq"
            v-model="questionNo"
            :class="{ null: questionNo === '' && !isFirst }"
            label="질문 번호"
          >
          </v-text-field>
          <v-flex v-if="question.questionType === 9">
            <image-editor
              :title-data.sync="titleData"
              :is-selected="isSelected"
              ref="imageEditor"
            ></image-editor>
          </v-flex>
          <v-flex v-else>
            <editor
              :title-data.sync="titleData"
              :isFirst="isFirst"
            ></editor>
          </v-flex>
          <v-layout v-if="isAnswers && question.questionType !== 31 && (question.questionType !== 4 && !(question.questionType > 40))" class="m-y-10" wrap>
            <v-flex v-for="(choice, n) in choices" :key="n" row class="m-a-0">
              <v-layout row>
                <v-flex :class="`xs${((!isEditable && (question.questionType === 2 || question.questionType === 1)) || (question.questionType === 6 && isEditable)) ? 9 : 12}`">
                  <v-text-field @keyup.enter="add(n + 1)" class="choice-input" :class="{ null: (choice.content === '' && !isFirst) || isNull }" v-model.trim="choice.content" label="답변 내용" :autofocus="n === focusIdx"></v-text-field>
                </v-flex>
                <v-flex :class="`align-middle xs${((!isEditable && (question.questionType === 2 || question.questionType === 1)) || (question.questionType === 6 && isEditable)) ? 3 : 12}`" v-if="!(!isEditable && question.questionType === 6)" text-xs-right>
                  <v-btn v-if="isEditable" @click="add(n + 1)" icon small sm1 class="m-x-0 m-r-10 text-md-right">
                    <v-icon>add</v-icon>
                  </v-btn>
                  <v-btn v-if="isEditable" @click="remove(n + 1)" icon small sm1 class="m-x-0 text-md-right">
                    <v-icon>remove</v-icon>
                  </v-btn>
                  <v-btn :disabled="!isEditable" v-if="question.questionType !== 6 && !isLinkedBtn" :class="{ 'blue lighten-4': choice.hasSubQuestion, 'm-t-16': !isEditable }" class="m-r-0 p-a-0" small @click="choice.hasSubQuestion = !choice.hasSubQuestion" flat><i class="fas fa-pen"></i>&nbsp;기타</v-btn>
                  <v-btn :disabled="!isEditable" v-if="isLinkedBtn" :class="{ 'v-btn--disabled': questionList.length <= 1, 'm-t-16': !isEditable }" class="m-r-0 p-a-0" small @click="answerLinkedMethod(n)" flat>질문 설정</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <answer-linked-question
              v-if="isLinkedDialog && choices.length > 0"
              :question="question"
              :questions="questionList"
              :choices="choices"
              :current-section="currentSection"
              :idx="linkId"
              :section-list="sectionList"
              :change-linked-btn="changeLinkedBtn"
              :change-linked-dialog="changeLinkedDialog"
              :current-question="currentQuestion"
              :set-sequence="setSequence"
              :survey="survey"
            ></answer-linked-question>
          </v-layout>
          <v-flex v-else-if="(question.questionType === 4 || question.questionType > 40) && isEditable" class="p-t-16" wrap>
            <v-layout>
              <div class="m-b-10 text-xs-left">
                <label class="d-inline-block p-y-10 m-r-16 p-r-8" v-for="(btnType, idx) in btnData.btnTypes" :key="idx" :for="'type' + btnType.choiceValue">
                  <input :id="'type' + btnType.choiceValue" type="radio" name="btnData" :value="btnType.choiceValue" v-model="btnData.value">
                  <div class="d-inline-block m-l-10" v-html="btnType.content"></div>
                </label>
              </div>
            </v-layout>
            <v-select
              v-if="btnData.value !== 41"
              :items="[2,3,4,5,6,7,8,9,10]"
              :class="{ null: maxScore === 0 && !isFirst }"
              label="최대 점수"
              v-model="maxScore"
              content-class="selected-v-menu"
              solo
            ></v-select>
            <v-select
              v-else
              :items="[5]"
              :class="{ null: maxScore === 0 && !isFirst }"
              label="최대 점수"
              v-model="maxScore"
              content-class="selected-v-menu"
              solo
            ></v-select>
          </v-flex>
        </v-layout>
        <v-flex xs12 v-if="(question.questionType === 1 || question.questionType === 2)" text-xs-left>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <div class="d-inline-block" v-on="on">
                <v-checkbox class="d-inline-block" :disabled="questionList.length <= 1 || !isEditable" v-model="isLinkedBtn" label="하위 질문 선택지로 전환"></v-checkbox>
              </div>
            </template>
            <span>기타 버튼이 하위 질문 선택 버튼으로 전환됩니다</span>
          </v-tooltip>
          <span style="font-size: 0.95em; color: #bbb; vertical-align: text-bottom;" v-if="questionList.length <= 1">선택 가능한 질문이 없습니다</span>
        </v-flex>
        <v-flex xs12 text-xs-left>
          <v-checkbox class="m-t-0" v-model="question.isUse" v-if="isEditable" label="화면상 질문 노출"></v-checkbox>
        </v-flex>
        <v-flex xs12 text-xs-left>
          <v-checkbox v-if="question.questionType !== 5 && question.questionType !== 8 && question.questionType !== 9 && isEditable" class="m-t-0 p-a-0 d-inline-block" v-model="question.isRequired" label="필수답변"></v-checkbox>
        </v-flex>
        <v-flex xs12 text-xs-left>
          <v-btn v-if="currentQuestion !== -1 && survey.isEditable" @click="pageMove" class="m-t-0 m-l-0 d-inline-block page-move-btn" flat><i class="fas fa-dolly" style="margin-right: 10px"></i>페이지이동</v-btn>
          <move-page-dialog
            v-if="isMovePageDialog"
            :current-section-id.sync="currentSectionId"
            :section-list="sectionList"
            :question="question"
            :change-move-page-dialog="changeMovePageDialog"
            :survey="survey"
          ></move-page-dialog>
        </v-flex>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :class="{'primary': true }" @click="send(question.questionType); changeMenuDialog(false);">{{btnTitle}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ImageEditor from '@/components/common/ImageEditor.vue'
import Editor from '@/components/common/Editor.vue'
import AdminEditor from '@/components/admin/AdminEditor.vue'
import AnswerLinkedQuestion from '@/components/admin/AnswerLinkedQuestion.vue'
import movePageDialog from '@/components/admin/MovePageDialog.vue'
import _ from 'lodash'

const typeArr = [
  {
    typeId: 1,
    typeTitle: '객관식 한 개 선택'
  },
  {
    typeId: 2,
    typeTitle: '객관식 복수 선택'
  },
  {
    typeId: 3,
    typeTitle: '주관식 짧은 답변'
  },
  {
    typeId: 4,
    typeTitle: '객관식 점수 선택'
  },
  {
    typeId: 41,
    typeTitle: '객관식 점수 선택 (숫자)'
  },
  {
    typeId: 42,
    typeTitle: '객관식 점수 선택 (하트)'
  },
  {
    typeId: 43,
    typeTitle: '객관식 점수 선택 (별)'
  },
  {
    typeId: 5,
    typeTitle: '정보글'
  },
  {
    typeId: 6,
    typeTitle: '드롭다운'
  },
  {
    typeId: 7,
    typeTitle: '주관식 긴 답변'
  },
  {
    typeId: 8,
    typeTitle: '구분선'
  },
  {
    typeId: 9,
    typeTitle: '이미지'
  },
  {
    typeId: 31,
    typeTitle: '핸드폰 번호'
  }
]
const lineArr = [
  {
    choiceValue: 1,
    hasSubQuestion: false,
    content: '<div class="cst_line"><span class="type_a d-block"></span></div>'
  },
  {
    choiceValue: 2,
    hasSubQuestion: false,
    content: '<div class="cst_line"><span class="type_b d-block"></span></div>'
  },
  {
    choiceValue: 3,
    hasSubQuestion: false,
    content: '<div class="cst_line"><span class="type_c d-block"></span></div>'
  }
]
const AnswerBtnSelect = [
  {
    choiceValue: 4,
    content: '<div class="btn_wrapper"><span class="thumb-btn" flat hide-details></span></div>'
  },
  {
    choiceValue: 41,
    content: '<div class="btn_wrapper"><span class="grade-btn" flat hide-details></span></div>'
  },
  {
    choiceValue: 42,
    content: '<div class="btn_wrapper"><span class="star-btn" flat hide-details></span></div>'
  },
  {
    choiceValue: 43,
    content: '<div class="btn_wrapper"><span class="heart-btn" flat hide-details></span></div>'
  }
]

export default {
  name: 'CreateQuestion',
  props: {
    question: Object,
    isAnswers: Boolean,
    currentQuestion: Number,
    updateQuestion: Function,
    changeQuestionContent: Function,
    currentSection: Number,
    questionList: Array,
    sortedQuestionList: Array,
    isOpen: Boolean,
    changeOpenValue: Function,
    sectionList: Array,
    setSequence: Function,
    originQuestion: Object,
    changeMenuDialog: Function,
    isEditable: Boolean,
    survey: Object
  },
  data () {
    return {
      isFirst: true,
      type: '',
      isNull: false,
      choiceValue: 1,
      choices: [{choiceValue: 0, hasSubQuestion: false, linkedQ: []}],
      titleData: '',
      questionNo: '',
      btnTitle: '입력',
      maxScore: 0,
      lineData: {
        value: 1,
        line: lineArr
      },
      btnData: {
        value: 4,
        btnTypes: AnswerBtnSelect
      },
      isLinkedBtn: false,
      isLinkedDialog: false,
      isMovePageDialog: false,
      linkId: 0,
      focusIdx: 0,
      currentSectionId: 0,
      seq: -1,
      isMovePageQuestion: false,
      isSelected: false
    }
  },
  methods: {
    add (index) {
      if (this.isEditable) {
        this.choiceValue += 1
        this.choices.splice(index, 0, {choiceValue: 0, hasSubQuestion: false, linkedQ: []})
        this.focusIdx = index
      }
    },
    remove (index) {
      if (this.choices.length > 1) {
        this.choices.splice(index - 1, 1)
      } else {
        alert('최소한 1개의 선택지는 존재해야 합니다.')
        return ''
      }
    },
    pageMove () {
      this.changeMovePageDialog(true)
    },
    changeValue (isBoolean) {
      this.isFirst = isBoolean
    },
    changeMovePageDialog (isBoolean, selectedSectionId) {
      if (selectedSectionId !== undefined) {
        this.currentSectionId = selectedSectionId
        this.seq = this.questionList.filter(q => { return q.sectionId === selectedSectionId }).length
        this.isMovePageQuestion = true
      }
      this.isMovePageDialog = isBoolean
    },
    changeLinkedDialog (isBoolean) {
      this.isLinkedDialog = isBoolean
    },
    changeLinkedBtn (isBoolean) {
      this.isLinkedBtn = isBoolean
    },
    send (type) {
      let questionObj = _.cloneDeep(this.question)
      if (type === 9) this.$refs.imageEditor.setSize()
      if ((type === 4 || type > 40) && this.choices.length < 2 && this.maxScore === 0) {
        this.changeValue(false)
        return ''
      } else if (type !== 8 && ((this.titleData === '' || this.titleData === undefined) || ((this.questionNo === '' || this.questionNo === undefined) && !this.survey.isAutoSeq))) {
        if (!((type === 5 || type === 9) && this.titleData !== '' && this.titleData !== undefined)) {
          this.changeValue(false)
          return ''
        }
      } else if (this.isAnswers) {
        if (type === 4 || type > 40) {
          questionObj.questionType = this.btnData.value
          if (questionObj.questionType === 41) {
            this.maxScore = 5
          }
          if (this.originQuestion === undefined || this.originQuestion === null || this.originQuestion.choiceItems === undefined) {
            this.choices = []
          } else {
            this.choices = _.cloneDeep(this.originQuestion.choiceItems)
          }
          if (this.maxScore === 1 || this.maxScore === undefined) {
            this.changeValue(false)
            return ''
          } else if (this.choices.length > this.maxScore) {
            this.choices.splice(this.maxScore, (this.choices.length - this.maxScore))
          } else if (this.choices.length < this.maxScore) {
            let startNum = 0
            if (this.choices.length > 0) {
              startNum = this.choices.length
            } else {
              this.choices = []
            }
            for (let i = startNum; i < this.maxScore; i++) {
              this.choices[i] = { choiceValue: i, hasSubQuestion: false, content: i + 1, linkedQ: '' }
            }
          }
        } else if (this.choices.length < 2) {
          this.changeValue(false)
          alert('선택지는 두 개 이상이어야 합니다.')
          this.isNull = true
          return ''
        } else {
          this.choicesNullchecker(this.choices)
          if (this.isNull) {
            this.changeValue(false)
            alert('선택지는 빈 값일 수 없습니다.')
            return ''
          }
          this.isNull = false
        }
        questionObj.maxChoiceValue = 0
        questionObj.choiceItems = _.cloneDeep(this.choices)
      } else if (type === 8) {
        this.titleData = this.lineData.line[(this.lineData.value) - 1].content
      }
      questionObj.questionTitle = this.titleData
      questionObj.displayNo = this.questionNo
      if (questionObj.sectionId !== undefined && questionObj.sectionId !== this.currentSectionId) questionObj.sectionId = this.currentSectionId
      if (this.seq !== -1) {
        questionObj.seqInSection = this.seq
        this.changeQuestionContent(questionObj, this.currentQuestion, true)
      } else {
        this.changeQuestionContent(questionObj, this.currentQuestion, false)
      }
      this.changeOpenValue(false)
    },
    async choicesNullchecker (choices) {
      this.isNull = false
      await choices.forEach(choice => {
        if (choice.content === '' || choice.content === undefined || choice.content === null) {
          this.isNull = true
        }
      })
    },
    answerLinkedMethod (idx) {
      this.isLinkedDialog = !this.isLinkedDialog
      this.linkId = idx
    },
    init (initQuestion) {
      this.isSelected = !this.isSelected
      this.type = initQuestion.questionType
      this.isFirst = true
      this.titleData = initQuestion.questionTitle
      this.isLinkedBtn = false
      this.questionNo = ''
      this.isLinkedDialog = false
      if (this.survey.isAutoSeq) {
        let questionListInSection = this.sortedQuestionList.filter(el => el.sectionId === this.currentSection && el.questionType !== 5 && el.questionType !== 8 && el.questionType !== 9)
        let questionIdx = _.findIndex(questionListInSection, {questionId: initQuestion.questionId})
        if (questionIdx === -1) questionIdx = questionListInSection.length
        this.questionNo = (questionIdx + 1).toString()
      }
      if (initQuestion.questionId === undefined || initQuestion.questionId <= 0) {
        this.btnTitle = '추가'
      } else {
        this.btnTitle = '편집'
      }
      if (this.type === 8) {
        if (initQuestion.questionTitle !== undefined && initQuestion.questionTitle !== '') {
          this.lineData.value = this.lineData.line.filter(el => { return el.content === initQuestion.questionTitle })[0].choiceValue
        } else {
          this.lineData.value = 1
        }
      } else if (initQuestion.choiceItems !== undefined && initQuestion.choiceItems.length > 0) {
        initQuestion.choiceItems.forEach(choice => {
          if (choice.linkedQ !== undefined) {
            if (choice.linkedQ.toString() === '') {
              choice.linkedQ = []
            } else if (!choice.linkedQ.toString().includes(',')) {
              choice.linkedQ = choice.linkedQ.toString().split().map(item => { return parseInt(item.trim()) })
            } else if (choice.linkedQ.toString().includes(',')) {
              choice.linkedQ = choice.linkedQ.toString().split(',').map(item => { return parseInt(item.trim()) })
            }
          }
        })
        this.choices = initQuestion.choiceItems
        this.maxScore = this.choices.length
        this.choices.forEach(choice => {
          choice.linked = false
        })
      } else {
        this.choices = [{choiceValue: 0, hasSubQuestion: false, linked: false, linkedQ: []}]
        this.maxScore = 0
        this.choiceValue = 1
      }
    }
  },
  watch: {
    isOpen () {
      this.init(_.cloneDeep(this.question))
      typeArr.filter(t => {
        if (t.typeId === this.question.questionType) {
          this.type = t.typeTitle
          this.btnData.value = this.question.questionType
          return ''
        }
      })
      if (this.isOpen) this.currentSectionId = this.question.sectionId
    }
  },
  components: {
    'input-box': '<>',
    'editor': Editor,
    'admin-editor': AdminEditor,
    'answer-linked-question': AnswerLinkedQuestion,
    'image-editor': ImageEditor,
    'move-page-dialog': movePageDialog
  }
}
</script>

<style lang="scss">
.page-move-btn{
  padding-left: 0;
  color: rgba(0,0,0,.54) !important;
  font-size: 16px;
  font-weight: normal;
  &::before{
    display: none;
  }
  i {
    font-size: 18px;
  }
}
.selected-v-menu {
  z-index: 20000 !important;
}
</style>

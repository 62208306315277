<template>
  <v-layout wrap>
    <v-flex xs12 v-for="(choice, n) in choices" :key="n" row class="m-a-0">
      <!--div @click="changeChoiceFocus(null, false)" :class="{toolBarBg: choice.isFocus}"></div-->
      <v-flex xs7>
        <!-- <quill-editor
          ref="myQuillEditor1"
          v-model="choice.content"
          :class="{subQuill: true, null: (choice === undefined || title === '') && !isFirst, noneToolBar: !choice.isFocus }"
          :options="editorOption"
          :input="$emit('update:titleData', title)"
          @focus="changeChoiceFocus(choice, true)"
          @ready="onEditorReady($event); changeChoiceFocus(choice, true);"
        /> -->
      </v-flex>
      <v-flex xs5 text-xs-right>
        <v-btn @click="add(n + 1)" icon small sm1 class="m-x-0 m-r-10 text-md-right">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn @click="remove(n + 1); changeChoiceFocus(null, false)" icon small sm1 class="m-x-0 text-md-right">
          <v-icon>remove</v-icon>
        </v-btn>
        <v-btn :class="{ 'grey--text': !choice.hasSubQuestion }" class="m-r-0 p-a-0" small @click="choice.hasSubQuestion = !choice.hasSubQuestion; changeChoiceFocus(null, false)" flat>기타</v-btn>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
// import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AdminEditor',
  props: {
    question: Object,
    isFirst: Boolean,
    choices: Array,
    add: Function,
    remove: Function,
    changeChoiceFocus: Function
  },
  data () {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }] // toggled buttons
          ]
        }
      },
      title: this.titleData,
      isBoolean: true
    }
  },
  // methods: {
  //   onEditorBlur (quill) {
  //   },
  //   onEditorFocus (quill) {
  //   },
  //   onEditorReady (quill) {
  //     quill.focus()
  //   },
  //   onEditorChange ({ quill, html, text }) {
  //   }
  // },
  components: {
    // 'quill-editor': quillEditor
  },
  watch: {
    titleData () {
      this.title = this.titleData
    }
  },
  computed: {
    editor () {
      // return this.$refs.myQuillEditor.quill
      return 'this.$refs.myQuillEditor.quill'
    }
  }
}
</script>

<style lang="scss" scope>
.quill-editor{
  position: relative;
  &:focus-within{
    box-shadow: 0px 0px 5px #91d6ff;
  }
  &:focus-within .ql-snow{
    border: 1px solid #91d6ff;
  }
  &:focus-within .ql-toolbar.ql-snow{
    border-bottom: 1px solid #ccc;
  }
  .ql-container.ql-snow{
    height: 170px;
  }
  &.subQuill{
    .ql-container.ql-snow{
      height: auto;
      .ql-editor{
        padding: 10px;
        max-height: 80px;
      }
    }
    &.noneToolBar {
      border-top: 1px solid #ccc;
      .ql-toolbar{
        display: none;
      }
    }
    .ql-toolbar{
      position: absolute;
      top: -42px; left: 0;
      width: 100%;
      background-color: #fff;
    }
  }
}
// .toolBarBg {
//   position: fixed;
//   display: block; content: ''; clear: both;
//   width: 100%; height: 100%;
//   left: 0; top: 0;
// }
.quill-editor.null{
  position: relative;
  box-shadow: 0px 0px 5px #ec9797;
  .ql-snow{
    border-color: #ec9797;
  }
  .ql-toolbar.ql-snow{
    border-bottom-color: #ccc;
  }
  &:after{
    display: block; content: '\f071'; clear: both;
    position: absolute;
    right: 5px; bottom: 5px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #ec9797;
  }
}
</style>

<template>
  <v-layout class="display-table">
    <v-flex class="text-xs-center display-table-cell" style="min-height: 300px;">
      <i class="fas fa-check-circle" style="color: #1976d2;"></i>
      <dl>
        <dt class="display-1 m-t-30">설문 제출이 완료되었습니다</dt>
        <dd class="headline d-inline-block cursor-pointer p-a-16" @click="showLinkDialog({surveyId: $route.params.surveyId})">클릭하여 설문 링크 확인하기</dd>
        <dd>
          <v-btn
            @click="movePageMain"
            class="headline m-t-30 p-a-16 cursor-pointer back-btn"
          ><i class="fas fa-undo-alt m-r-16"></i>설문 리스트로 돌아가기</v-btn>
        </dd>
      </dl>
      <copy-link
        :link-survey="linkSurveyId"
        :link-dialog-visible="linkDialogVisible"
        v-if="isLinkDialogVisible"
      ></copy-link>
    </v-flex>
  </v-layout>
</template>

<script>
import CopyLink from '@/components/admin/CopyLink'

export default {
  data () {
    return {
      linkSurveyId: 0,
      isLinkDialogVisible: false
    }
  },
  methods: {
    showLinkDialog (survey) {
      this.linkDialogVisible(true)
      this.linkSurveyId = survey
    },
    linkDialogVisible (isBoolean) {
      this.isLinkDialogVisible = isBoolean
    },
    movePageMain () {
      let isTrue = confirm('설문 리스트 페이지로 이동하시겠습니까?')
      if (isTrue) {
        this.$router.push({name: 'MySurveyList'})
      }
    }
  },
  components: {
    'copy-link': CopyLink
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.display-table{
  display: table;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  &-cell{
    display: table-cell;
    vertical-align: middle;
  }
}
.fa-check-circle{
  font-size: 150px;
}
.back-btn{
  box-sizing: content-box;
  background-color: transparent !important;
  border: 1px solid #ddd;
  border-radius: 3px;
  &::before{
    display: none;
  }
}
@media screen and (max-width: $mobile) {
  .fa-check-circle{
    font-size: 90px;
  }
}
</style>

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@babel/polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuetify from 'vuetify'
import store from './store'
import Quill from 'quill'
import VueQuillEditor from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module--fix-imports-error'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@/assets/style/common.scss'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(Vuetify, { iconfont: 'fa' })
Quill.register('modules/imageResize', ImageResize)
Vue.use(VueQuillEditor, {
  modules: {
    ImageResize: true,
    formula: true,
    syntax: true,
    toolbar: '#toolbar-container'
  },
  placeholder: '내용을 입력하세요.',
  theme: 'snow'
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  store.dispatch('account/checkToken').then((response) => {
    if (to.meta.isPublic) {
      next()
    } else {
      if (!response) {
        alert('로그인 필요')
        if (from.name !== 'AdminLogin') next({ name: 'AdminLogin' })
      } else {
        next()
      }
    }
  })
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  Vuetify,
  store
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

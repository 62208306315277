<template>
  <v-layout class="survey-layout" :class="{visible: !isVisible}">
    <div class="survey-card-bg "></div>
    <v-card class="survey-content" v-if="paramId === 0">
      <v-card-text>
        <h3 v-if="!isSelected" class="subheading font-weight-bold text-xs-left p-y-16 grey--text">설문지 새로 만들기</h3>
        <v-layout class="m-x-0" row>
          <v-flex>
            <v-text-field
              :class="{null: (survey.contents === '' && isClick)}"
              v-model="titleContent"
              label="설문 제목" clearable
              @click="isClick = false;"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 text-xs-left>
          <v-checkbox v-model="survey.isUse" label="설문지 공개"></v-checkbox>
          <v-checkbox v-model="survey.isAutoSeq" class="m-t-0" label="질문 번호 자동 생성"></v-checkbox>
          <div class="d-block m-t-0 m-b-16">
            <v-checkbox hide-details v-model="survey.isMaxResponseCnt" class="d-inline-block m-t-0 m-r-10" label=" 답변횟수 제한"></v-checkbox>
            <v-text-field
              v-if="survey.isMaxResponseCnt"
              :class="{null: (selectSurvey.maxResponseCnt === '')}"
              class="d-inline-block m-t-0 p-t-0"
              style="max-width: 200px;"
              v-model.trim.number="surveyInputCnt"
              min="0"
              @input="$emit('update:inputCnt', surveyInputCnt)"
              label="답변자별 최대 답변횟수"
              type="Number"
              hide-details
            ></v-text-field>
          </div>
        </v-flex>
        <survey-date-range
          :start-date="startDate"
          :end-date="endDate"
          @setDateRange="setDateRange"
        ></survey-date-range>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isSelected"
            @click="addSurvey()"
            :class="{'blue lighten-4': true }"
          >만들기</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { surveyMapActions, surveyMapGetters } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'
import { axiosConfig } from '../../api/index'
import SurveyDateRange from './SurveyDateRange'
import _ from 'lodash'
// import axios from 'axios'

export default {
  name: 'CreateSurvey',
  props: {
    selectSurvey: Object,
    sectionList: Array,
    questionList: Array,
    isCopySurvey: Boolean,
    isEditable: Boolean,
    startDate: String,
    endDate: String,
    inputCnt: Number
  },
  data () {
    return {
      // active: null,
      copyProcessCnt: 0,
      selectId: 0,
      isVisible: false,
      isSelected: false,
      isClick: false,
      survey: {
        contents: '',
        isUse: true,
        isMaxResponseCnt: false,
        maxResponseCnt: this.count,
        isEditable: true,
        isAutoSeq: true
      },
      titleContent: '',
      surveyInputCnt: this.inputCnt
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyList', 'getQuestionList', 'getSectionList', 'getOriginQuestionList']),
    ...accountMapGetters(['getToken']),
    count () {
      if (this.survey.isMaxResponseCnt) {
        return parseInt(this.surveyInputCnt)
      } else {
        return 0
      }
    },
    link () {
      let url = 'surveyId/' + this.selectId + '/res/admin'
      if (this.selectId === 0) {
        return ''
      } else {
        return (window.location.origin + '/u2s-web/param/' + btoa(url).replaceAll('/', '_'))
      }
    },
    paramId () {
      return this.$route.params.surveyId ? parseInt(this.$route.params.surveyId) : 0
    },
    paramIsEdit () {
      return !(!this.paramId || this.paramId === 0)
    }
  },
  methods: {
    ...surveyMapActions(['insertSurvey', 'insertQuestion', 'insertChoices', 'fetchSurveyList', 'insertSection', 'fetchSectionList', 'fetchQuestionList', 'updateSurvey']),
    selectSurveySend () {
      this.surveySelect()
    },
    setDateRange (param) {
      this.$emit('setDateRange', param)
    },
    setIsVisible (isVisible) {
      this.isVisible = isVisible
    },
    copyLink () {
      const copyText = document.getElementById('linkInput')
      document.getElementById('linkInput').disabled = false
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      alert('복사완료')
      document.getElementById('linkInput').disabled = true
    },
    surveyCopy (id) {
      this.fetchSurveyList({
        // surveyId: this.selectId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        let newDate = new Date()
        let newDateSetDate = newDate.setDate(newDate.getDate() + 7)
        this.survey = _.cloneDeep(this.getSurveyList.filter(survey => survey.surveyId === id)[0])
        this.survey.contents = this.survey.contents + '_copy'
        this.survey.isAutoSeq = this.survey.isAutoSeq
        this.survey.isEditable = true
        this.survey.startDate = new Date().toISOString().substr(0, 10)
        this.survey.endDate = new Date(newDateSetDate).toISOString().substr(0, 10)
        this.survey.maxResponseCnt = this.count
        this.insertSurvey({
          params: this.survey,
          config: axiosConfig.setHeader(this.getToken)
        }).then((response) => {
          this.selectId = response.data
          this.isVisible = true
          this.sectionsCopy(id)
        }).catch((err) => { throw Error(err) })
      }).catch((err) => { throw Error(err) })
    },
    async submitSections (section) {
      section.sectionOldId = section.sectionId
      section.surveyId = this.selectId
      await this.insertSection({
        params: section,
        config: axiosConfig.setHeader(this.getToken)
      }).then((response) => {
        this.copyProcessCnt -= 1
        let questionListInSection = this.getQuestionList.filter(el => el.sectionId === section.sectionOldId)
        if (questionListInSection && questionListInSection.length > 0) {
          questionListInSection = questionListInSection.map(el => { el.sectionId = response.data; return el })
          for (let i = 0; i < questionListInSection.length; i++) {
            this.submitQuestions(questionListInSection[i])
          }
        }
      }).catch((err) => { throw Error(err) })
    },
    async submitQuestions (question) {
      this.insertQuestion({
        params: question,
        config: axiosConfig.setHeader(this.getToken)
      }).then((response) => {
        this.copyProcessCnt -= 1
        if (question.choiceItems !== undefined && question.choiceItems.length > 0) {
          for (let i = 0; i < question.choiceItems.length; i++) {
            let choice = question.choiceItems[i]
            choice.questionId = response.data
            if (choice.linkedQ !== undefined && choice.linkedQ.length > 0) {
              choice.linkedQ = choice.linkedQ.toString()
            } else {
              choice.linkedQ = ''
            }
            choice.choiceItemSeq = i
            choice.choiceValue = i
            this.submitChoicesMethod(choice)
          }
        }
      })
    },
    async submitChoicesMethod (choice, cntObj) {
      this.insertChoices({
        params: choice,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.copyProcessCnt -= 1
      }).catch((err) => { throw Error(err) })
    },
    async sectionsCopy (id) {
      await this.fetchSectionList({
        surveyId: id,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.questionsCopy(id)
      }).catch((err) => { throw Error(err) })
    },
    async questionsCopy (id) {
      await this.fetchQuestionList({
        surveyId: id,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.copyProcessCnt = this.getSectionList.length + this.getQuestionList.length + _.flattenDeep(this.getQuestionList.filter(el => el.choiceItems).map(el => el.choiceItems)).length
        let total = this.getSectionList.length
        for (let i = 0; i < total; i++) {
          this.submitSections(this.getSectionList[i])
        }
      }).catch((err) => { throw Error(err) })
    },
    addSurvey () {
      this.survey.contents = this.titleContent
      this.survey.startDate = this.startDate
      this.survey.endDate = this.endDate
      this.survey.maxResponseCnt = this.count
      if (this.survey.contents !== '') {
        this.isClick = false
        this.insertSurvey({
          params: this.survey,
          config: axiosConfig.setHeader(this.getToken)
        }).then((response) => {
          this.selectId = response.data
          this.selectSurveySend()
        }).catch((err) => { throw Error(err) })
      } else {
        this.isClick = true
        alert('설문을 추가하시려면 설문 제목을 작성하세요.')
      }
    },
    surveySelect () {
      if (this.isCopySurvey) return ''
      if (this.selectId !== 0) {
        this.$emit('setSelectedId', this.selectId)
      } else {
        this.titleContent = ''
        this.survey = { contents: '', isUse: true, maxResponseCnt: 0, isMaxResponseCnt: false, isEditable: true, isAutoSeq: true }
        this.$emit('readyMethod')
      }
    }
  },
  watch: {
    copyProcessCnt () {
      if (this.isCopySurvey && this.copyProcessCnt === 0) {
        this.$emit('setSelectedId', this.selectId)
        this.$router.replace({name: 'SurveyAdmin', params: {surveyId: this.selectId, isEdit: true, isCopy: false}})
      }
    },
    titleContent () {
      if (this.titleContent === '' || this.titleContent === null) {
        this.selectId = 0
        this.isClick = false
        this.isSelected = false
        this.survey.contents = ''
        this.survey.isUse = true
        this.survey.isAutoSeq = true
      } else {
        if (this.survey.contents !== '') {
          this.isSelected = true
        }
      }
    }
  },
  mounted () {
    if (this.isCopySurvey) {
      this.surveyCopy(this.paramId)
    } else {
      if (this.paramId == 0) {
        this.surveySelect()
      } else if (this.paramId !== 0) {
        this.selectId = this.paramId
        if (this.paramIsEdit) {
          this.selectSurveySend()
        }
      }
    }
  },
  components: {
    'survey-date-range': SurveyDateRange
  }
}
</script>

<style lang="scss" scope>
.survey-layout {
  display: none;
  position: fixed;
  width: calc(100vw - 20px);
  height: calc(100vh - 90px - 50px - 20px);
  left: 0; top: 0;
  z-index: 7;
  &.visible {
    display: block;
    .survey-card-bg {
      position: absolute;
      width: 100%; height: 100%;
      min-height: 250px;
      left: 0; top: 0;
      background-color: rgba(0,0,0,0.5);
      z-index: 1;
    }
    .survey-content {
      position: absolute;
      width: 500px; height: auto;
      min-height: 250px;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 2;
    }
  }
}
.v-window__container{
  min-height: 155px;
}
.v-tabs__div{
  margin: auto;
  .v-tabs__item{
    &--active{
      h3 {
        color: #6aaaff !important;
      }
    }
  }
}
.link-box{
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: ease-in-out 0.3s;
  background-color: rgba(255, 255, 255, 0.6);
  &:hover{
    border-color: #bbb;
  }
}
</style>

<template>
  <v-app id="app">
    <div class="survey-card-bg"></div>
    <admin-header v-if="!$router.history.current.meta.isPublic" class="m-b-30" :class="{ 'list-header' : $router.history.current.name === 'SurveyAdmin' }"></admin-header>
    <div id="inner-content">
      <router-view/>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
import 'vuetify/dist/vuetify.min.css'
import Header from './components/account/Header.vue'
import Footer from './components/account/Footer.vue'

export default {
  components: { 'admin-header': Header, Footer },
  name: 'App'
}
</script>

<style lang="scss">
@import '@/assets/style/variable.scss';

#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media(min-width: $tablet) {
  #inner-content {
    position: fixed;
    width: 100%;
    left: 50%; top: 90px;
    transform: translateX(-50%);
    height: calc(100vh - 50px - 90px);
    overflow-y: auto;
  }
}
#inner-content {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
}
.survey-card-bg{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0; top: 0;
  z-index: 0;
  &:before{
    display: block; content: ''; clear: both;
    position: fixed; width: 100%; height: 100%;
    background: url('../static/images/high-angle-multiple-election-questionnaires.jpg') center no-repeat;
    background-size: cover;
    opacity: 0.5;
  }
}
</style>


<template>
  <v-card class="m-t-16">
    <v-stepper v-if="sections && sections.length > 0 && el != null" v-model="el">
      <v-flex v-if="isEditable" text-xs-right>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteSection(el)" fab dark small color="#5AA3DD">
              <i class="fas fa-trash-alt"></i>
            </v-btn>
          </template>
          <span>해당 페이지 삭제</span>
        </v-tooltip>
      </v-flex>
      <v-stepper-items>
        <v-flex v-if="sections[el-1] && !(sections[el-1].sectionTitle === undefined || sections[el-1].sectionTitle === '')" text-xs-left>
          <h1 class="headline" text-xs-left row><span>{{sections[el-1].sectionTitle}}</span></h1>
        </v-flex>
        <v-stepper-content
          v-for="(section, index) in sections"
          :key="'step-' + index"
          :step="index + 1"
          xs12
          class="stepper-content"
        >
          <draggable :handle="isMobile ? '.question-handle' : ''" :disabled="!isEditable" :list="questions" v-if="questions.length !== 0">
            <transition-group>
              <div class="move-icon-wrapper" draggable="true" @dragend="resetLink(question, true)" row v-for="(question, idx) in questions" :key="'adminStep' + question.questionId">
                <question-cmp
                v-if="section.sectionId === question.sectionId"
                :question="question"
                :current-section="currentSection"
                :question-idx="idx"
                :question-click="questionClick"
                :question-del-method="questionDelMethod"
                :section="section"
                :is-editable="isEditable"
                :questions="questions"
                :question-copy="questionCopy"
                :survey="survey"
                >
                </question-cmp>
              </div>
            </transition-group>
          </draggable>
          <v-btn
            depressed fab
            :color="`${el > 1 ? 'blue' : ''} lighten-2`"
            @click="prev(section)"
          >
            <i style="color: #fff; font-size: 20px;" class="fas fa-arrow-left"></i>
          </v-btn>
          <v-btn
            depressed fab
            :color="`${el < sections.length ? 'blue' : ''} lighten-2`"
            @click="next(section)"
          >
            <i style="color: #fff; font-size: 20px;" class="fas fa-arrow-right"></i>
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>
<script>

import Question from '@/components/admin/Question.vue'
import QuestionTypeList from '@/components/admin/QuestionTypeList.vue'
import draggable from 'vuedraggable'

export default {
  name: 'Steppers',
  props: {
    questions: Array,
    sections: Array,
    currentSection: Number,
    currentQuestion: Number,
    questionClick: Function,
    questionDelMethod: Function,
    questionCopy: Function,
    sectionDel: Function,
    isEditable: Boolean,
    resetLink: Function,
    survey: Object
  },
  data () {
    return {
      el: null,
      questionDialog: false
    }
  },
  computed: {
    isMobile () {
      let UserAgent = navigator.userAgent;
      if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null){ return true }else{ return false }
    }
  },
  methods: {
    changeBoolean (isBoolean) {
      this.questionDialog = isBoolean
    },
    next () {
      if (this.sections.length > this.el) {
        this.$emit('update:currentSection', this.sections[this.el].sectionId)
      }
    },
    prev () {
      if (this.el > 1) {
        this.$emit('update:currentSection', this.sections[this.el - 2].sectionId)
      }
    },
    setSection () {
      let section = this.sections.filter(section => { return section.sectionId === this.currentSection })[0]
      if (this.sections.indexOf(section) === -1) {
        this.el = 1
      } else {
        this.el = this.sections.indexOf(section) + 1
      }
    },
    deleteSection (idx) {
      let result
      if (this.sections[this.el - 1].sectionTitle !== '') {
        result = confirm(this.sections[this.el - 1].sectionTitle + ' 페이지를 삭제하시겠습니까?\n페이지를 삭제할 경우 복구할 수 없습니다.')
      } else {
        result = confirm(this.el + ' 페이지를 삭제하시겠습니까?\n페이지를 삭제할 경우 복구할 수 없습니다.')
      }
      if (result) {
        if (this.sections.length <= 1) {
          alert('페이지는 최소 1개 이상 존재해야 합니다.')
        } else {
          if (idx > 1) {
            this.sectionDel(this.el - 1)
            this.$emit('update:currentSection', this.sections[this.el - 2].sectionId)
          } else if (idx === 1) {
            this.$emit('update:currentSection', this.sections[1].sectionId)
            this.sectionDel(0)
          }
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.el = 1
    })
  },
  watch: {
    currentSection () {
      this.setSection()
    },
    sections () {
      this.setSection()
    }
  },
  components: {
    'question-cmp': Question,
    'question-type-list': QuestionTypeList,
    draggable
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
  .v-stepper__header{height: auto}
  ::v-deep .v-input--selection-controls__ripple {
    z-index : 1;
  }
  h1 {
    margin: 1.5em 0 0;
    text-align: left;
    color: #fff;
    height: 2.8em;
    font-weight: normal;
    max-width: calc(100% - 4em);
    span {
      padding: 0.4em 2em 0.65em 1em;
      background-color: #5AA3DD;
      position: relative;
      word-wrap: break-word;
      text-overflow: ellipsis;
      height: 2.8em;
      display: inline-block;
      line-height: 2em;
      box-sizing: border-box;
      &:before,
      &:after  {
        display: block;
        content: '';
        clear: both;
        position: absolute;
        right: -1.5em;
      }
      &:before {
        bottom: 0;
        border-bottom:1.56em solid #5AA3DD;
        border-right: 1.56em solid transparent;
        border-left: 1.56em solid  transparent;
      }
      &:after {
        bottom: 1.25em;
        border-top: 1.56em solid #5AA3DD;
        border-right: 1.56em solid transparent;
        border-left: 1.56em solid transparent;
      }
    }
  }
  .sortable-ghost {
    opacity: 0.3;
  }
  .stepper-content{
    box-sizing: border-box;
  }

  @media (max-width: $mobile) {
    .v-stepper__content {
      padding: 10px 10px 30px;
    }
  }
</style>

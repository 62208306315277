import api from './index'

export default {
  selectSurveyList: (config) => api.get(`/library/survey/list`, config),
  selectSectionList: (surveyId, config) => api.get(`/library/section/survey/${surveyId}`, config),
  selectQuestionList: (surveyId, config) => api.get(`/library/question/survey/${surveyId}`, config),
  selectAnswerListByRespondent: (respondent, config) => api.get(`/answer/respondent/${respondent}`, config),
  selectAnswerByAnswerId: (answerId, config) => api.get(`/answer/id/${answerId}`, config),
  selectRespondentSurveyAnswerList: (surveyId, config) => api.get(`/answer/survey/${surveyId}/type/respondent/list`, config),
  selectRespondentSurveyAnswerListForDropouts: (surveyId, config) => api.get(`/answer/survey/${surveyId}/type/respondent/dropouts-list`, config),
  selectRespondentSurveyAnswers: (answerId, config) => api.get(`/answer/type/respondent/${answerId}`, config),
  selectRespondentSurveyAnswersForDropouts: (answerId, config) => api.get(`/answer/type/dropouts/${answerId}`, config),
  selectSurveyAnswerList: (surveyId, config) => api.get(`/answer/survey/${surveyId}/type/question`, config),
  selectSurveyAnswerListForDropsouts: (surveyId, config) => api.get(`/answer/survey/${surveyId}/type/question/dropouts`, config),
  selectSurveyAnswerExcel: (surveyId, config) => api.get(`/answer/download-excel/survey/${surveyId}`, config),
  selectSurveyAnswerExcelForDropouts: (surveyId, config) => api.get(`/answer/download-excel/survey/dropouts/${surveyId}`, config),
  insertSurvey: (params, config) => api.post('/library/survey', params, config),
  insertSection: (params, config) => api.post('/library/section', params, config),
  insertQuestion: async (params, config) => await api.post('/library/question', params, config),
  insertChoices: async (params, config) => await api.post('/library/choice-item', params, config),
  updateSurvey: (params, config) => api.put('/library/survey', params, config),
  updateSection: (params, config) => api.put('/library/section', params, config),
  updateQuestion: async (params, config) => await api.put('/library/question', params, config),
  updateChoices: async (params, config) => await api.put('/library/choice-item', params, config),
  deleteSurvey: (surveyId, config) => api.delete(`/library/survey/${surveyId}`, config),
  deleteSection: (sectionId, config) => api.delete(`/library/section/${sectionId}`, config),
  deleteQuestion: (questionId, config) => api.delete(`/library/question/${questionId}`, config),
  deleteChoices: (choiceId, config) => api.delete(`/library/choice-item/${choiceId}`, config)
}

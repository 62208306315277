<template>
  <div style="text-align: left;">
    <h2 style="margin-left:3vw; text-align:center" class="m-b-10">U2Survey 이용약관</h2>

    <p class="m-t-10">제 1 조 (목적)</p>

    <p>본 약관은 유투바이오 (이하 &lsquo;회사)가 운영하는 U2Survey (이하 &lsquo;서비스)를 이용함에 있어 이용자의 권리/의무 및 책임사항, 기타 필요사항을 규정함을 목적으로 합니다.</p>

    <p class="m-t-10">제 2 조 (용어 정의)</p>

    <p>본 약관에서 사용하는 용어는 다음과 같이 정의합니다.</p>

    <ol>
      <li>서비스 : 설문조사 양식을 만들고 링크를 공유하여 답변을 수집할 수 있는 온라인 설문조사 서비스</li>
      <li>이용자 : 회사가 제공하는 서비스를 이용하고자 절차에 의거 계약한 U2Survey 회원</li>
      <li>서비스신청 : 회사가 정한 별도의 기준과 절차에 따라 서비스 이용을 신청하는 것</li>
      <li>정기구독 : 정기결제 등으로 이용자가 월정액의 금액을 지불하며 서비스를 이용하는 것</li>
      <li>구독신청 : 본인인증 및 결제수단 등록 등의 절차를 통해 서비스 정기구독을 신청하는 것</li>
      <li>구독해지 : 회사가 정한 일정한 요건에 따라 일정기간 동안 서비스 이용을 보류 조치하는 것</li>
      <li>구독취소 : 이용자가 서비스의 구독을 취소하고 서비스의 이용을 중단하는 것</li>
    </ol>

    <p class="m-t-10">제 3 조 (약관의 명시와 개정)</p>

    <ol>
      <li>회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 홈페이지 이용 약관에 게시합니다.</li>
      <li>회사는 약관규제에 관한 법률, 개인정보보호법, 정보통신망이용촉진 및 정보보호 등에 관한 법률(이하 정보통신망법), 전자상거래 등에서의 소비자 보호에 관한 법률, 전기통신사업법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
      <li>회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전까지 공지합니다.<br />
      다만, 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일 전에 공지하고 일정기간 서비스내 공지사항을 통해 따로 명확히 통지하도록 합니다.</li>
      <li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 않으면 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 약관의 규제에 관한 법률 제12조 제1호 단서에 따라 이용자가 개정약관에 동의한 것으로 봅니다.</li>
      <li>이용자가 개정약관의 적용에 동의하지 않을 경우 회사는 개정약관의 내용을 해당 이용자에게 적용할 수 없으며, 이 경우 이용자는 이용신청을 해지할 수 있습니다.</li>
      <li>이용자는 약관의 변경에 대하여 주의 의무를 다하여야 하며 약관 미준수로 인한 이용자의 피해는 회사가 책임지지 않습니다.</li>
      <li>이 약관이 이용자에게 적용되는 기간은 이용자의 서비스 신청일부터 해지일 까지를 원칙으로 하되, 단, 채권 또는 채무관계가 있을 경우에는 채권, 채무의 완료일까지로 합니다.</li>
    </ol>

    <p class="m-t-10">제 4 조 (서비스 이용 신청)</p>

    <ol>
      <li>이용자는 회사가 정한 별도의 기준과 절차에 따라 약관에 동의하여 구독신청을 하거나 서비스 이용 신청을 완료 시 서비스 이용이 가능합니다.</li>
      <li>회사는 제 1항과 같이 서비스 이용을 신청한 이용자 중 다음 각호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용 신청을 해지할 수 있습니다.</li>
    </ol>

    <ul>
      <li>이용자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 회사의 이용자 재가입 승낙을 얻은 경우에는 예외로 함</li>
      <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
      <li>타인 명의의 이메일을 이용하여 가입한 경우</li>
      <li>이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</li>
      <li>회사의 업무상 또는 기술상 문제로 인해 가입승낙을 유보하는 경우</li>
      <li>이용자가 서비스를 이용하여 법령에 위반되는 행위를 하거나 이와 유사한 행위를 하는 경우</li>
    </ul>

    <ol start="3">
      <li>회사는 제1항에 따른 신청에 있어서 이용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</li>
    </ol>

    <p class="m-t-10">제 5 조 (개인정보보호 수집 및 보호 의무)</p>

    <ol>
      <li>회사는 정보통신망법, 개인정보보호법, 전기통신사업법 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.</li>
      <li>회사는 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 그 수집범위 및 목적을 사전 고지한다. 단, 회사에서 제공하는 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.</li>
      <li>회원은 관계법령에 따라 서비스 이용 중 관리하는 개인정보에 대해 관리 책임을 부담하며, 관계법령을 준수해야 합니다.</li>
      <li>기타 본 약관에서 정하지 아니하는 개인정보보호 관련 사항은 사이트의 개인정보 처리방침 등 정책에 따릅니다.</li>
    </ol>

    <p class="m-t-10">제 6 조 (이용자에 대한 통지)</p>

    <p style="margin-left: 2vw; text-align:left">회사가 이용자에 대한 서비스 관련 내용의 통지를 하는 경우, 1주일이상 제품의 공지사항에 게시합니다.</p>

    <p class="m-t-10">제 7 조 (서비스 제공의 의무)</p>

    <ol>
      <li>회사는 정보통신망법, 개인정보보호법, 전기통신사업법 등 관련법과 이 약관이 금지하는 행위를 하지 않으며 이용자에게 제공하는 서비스를 안정적으로 제공하기 위하여 최선을 다해야 합니다.</li>
      <li>회사는 안정적인 서비스를 지속적으로 제공하기 위하여 설비에 장애가 생기거나 멸실 되었을 때 지체 없이 수리 또는 복구를 합니다. 다만, 천재지변이나 비상사태 등 부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수 있습니다.</li>
      <li>회사는 서비스 제공과 관련 취득한 이용자의 개인정보를 본인의 동의 없이 타인에게 누설하거나 배포할 수 없으며 서비스 관련 업무 이외의 목적으로 사용할 수 없습니다. 다만 관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우는 그러하지 않습니다.</li>
      <li>회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안 시스템과 개인정보보호 책임자를 갖추어야 합니다.</li>
      <li>회사는 이용자의 사전 동의 없이 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
      <li>회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에 편의를 제공하도록 노력합니다.</li>
      <li>회사의 서비스 이용시에 회사의 귀책사유 없는 이용자의 시스템 장애 발생, 이용자의 불찰로 인한 개인정보유출에 대한 피해 등은 회사가 책임을 지지 않습니다.</li>
      <li>회사의 서비스는 서비스의 본질적인 부분이 아닌 경우 사전 예고 없이 화면과 기능이 수정될 수 있습니다.</li>
      <li>회사는 서비스 제공목적에 맞는 서비스 이용 여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다..</li>
      <li>회사는 서비스 제공이 불가능한 경우 다음과 같이 처리합니다.</li>
    </ol>

    <ul>
      <li>서비스 제공이 불가능한 경우 : 천재지변, 시스템(통신) 장애 및 점검, 서비스 양도 및 폐지(휴지) 등 천재지변 등을 제외한 서비스 제공이 불가능한 경우 이용자(고객)에게 사전 공지(안내)를 합니다.</li>
      <li>천재지변 등을 제외한 서비스 제공이 불가능한 경우 이용자(회원)에게 사전 공지(안내) 없이 서비스를 중단 하였을 시, 그로 인해 피해가 발생한 이용자(회원)에게 피해에 따른 손해를 확인 후 보상 및 할인 할 수 있습니다. 회사는 사업의 전부 및 일부를 휴지 또는 폐지 하고자 할 때에는 그 휴지 또는 폐지 예정일의 30일전까지 그 내용을 이용자(고객)에게 이메일, 홈페이지 등을 이용하여 공지합니다.</li>
    </ul>

    <p class="m-t-10">제 8 조 (이용자의 금지행위 의무)</p>

    <ol>
      <li>이용자는 현행 법령, 회사가 제공하는 서비스에 정한 약관, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항, 기타 서비스 이용에 관한 규정을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.</li>
      <li>이용자는 서비스를 이용함에 있어서 공공질서 또는 미풍양속을 해치는 행위, 또는 다음 각 호에 해당하는 행위를 해서는 안되며, 위반시 회사는 이용자의 서비스이용을 정지하고 일방적으로 서비스 이용 신청을 해지할 수 있습니다.</li>
    </ol>

    <ul>
      <li>다른 이용자의 이용 아이디를 부정 사용하는 행위</li>
      <li>해킹 행위 또는 컴퓨터 바이러스의 유포 행위</li>
      <li>타인의 의사에 반하여 광고성 정보 등 유사한 내용을 지속적으로 전송하는 행위</li>
      <li>회사와 타인의 지적 재산권 등을 침해하는 행위(불법 복제물 복제&middot;전송 등을 포함)</li>
      <li>범죄행위를 목적으로 하거나 범죄행위를 교사하거나, 반국가적 범죄의 수행 등을 목적으로 하는 행위서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위(신청 또는 변경 시 허위내용을 등록하는 행위 포함)</li>
      <li>회사와 타인의 명예를 손상시키거나 모욕하는 행위 또는 일체의 업무를 방해하는 행위</li>
      <li>외설 또는 폭력적인 메시지, 팩스, 음성, 메일, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시, 전송하는 행위 등 선량한 풍속 또는 기타 사회질서를 해하거나 관계법령에 위반하는 행위</li>
      <li>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위 및 제3자에게 임의로 서비스를 임대하는 행위</li>
    </ul>

    <p class="m-t-10">제 9 조 (서비스의 변경)</p>

    <ol>
      <li>회사는 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.</li>
      <li>서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경된 서비스의 내용 및 변경일자 등을 그 변경 후에 해당 서비스 초기 화면 또는 공지사항에 게시합니다.</li>
      <li>회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.</li>
    </ol>

    <p class="m-t-10">제 10 조 (서비스 이용의 제한 및 정지)</p>

    <ol>
      <li>회사는 원활한 서비스 제공을 위하여 이 약관을 위반하거나 서비스의 정상적인 운영을 방해하는 경우 서비스 이용을 제한하거나 정지할 수 있습니다.</li>
      <li>회사는 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 서비스 내의 금액, 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.</li>
      <li>이용자가 3항에 해당되어 타 서비스에 악용될 우려가 있다는 객관적 정황이 있는 경우에는 그 다른 서비스도 이용정지 할 수 있습니다. </li>
      <li>제3항의 이용정지 경우 사회적 공익을 저해할 목적이나 범죄적 목적으로 서비스를 이용하고 있다고 판단되는 경우 이용자에게 회사는 사전통보 없이 서비스를 즉시 중단할 수 있으며 그에 따른 데이터도 복구를 전제로 하지 않고 삭제할 수 있습니다.</li>
      <li>회사는 이용정지 등의 원인이 된 사유가 완전히 해소되기 전까지는 이용 정지등 조치를 해제하지 아니 할 수 있으며, 그 이용 정지 등의 사유가 해소된 것이 확인된 경우에는 이용정지조치를 즉시 해제합니다.</li>
      <li>회사는 이용자에게 강제적인 서비스 신청 해지 사유가 발생할 경우 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여한다. 단 해당 기간 동안 회사는 이용자에게 서비스를 중지할 수 있습니다.</li>
      <li>2회 이상 동일한 이용정지 사유가 발생하는 이용자에게는 회사는 이용자에게 사전통보 없이 서비스를 즉시 중단할 수 있으며, 제8항의 소명기회 부여를 생략하고 이용자에게 이용정지사유 2회 이상 발생으로 인한 사유임을 알려 서비스 해지 조치를 할 수 있습니다. 이 경우 해당 거래 관련 자료는 관련 법령에 따라 필요한 기간 동안 보관합니다.</li>
    </ol>

    <p class="m-t-10">제 11 조 (서비스 이용 변경)</p>

    <ol>
      <li>이용자가 서비스 이용을 변경하고자 할 때에는 본인이 직접 서비스 구독 변경을 해야 합니다.</li>
      <li>회사는 1항의 규정에 의하여 구독 변경이 접수되면 즉시 이용을 변경하거나 당월의 이용기간이 지난 후 이용을 변경한다. 단, 별도의 채무나 채권 관계가 있을 경우에는 그러하지 아니합니다.</li>
    </ol>

    <p class="m-t-10">제 12 조 (서비스 이용 해지)</p>

    <ol>
      <li>이용자가 서비스 이용을 해지하고자 할 때에는 본인이 직접 서비스 구독 취소를 해야 합니다.</li>
      <li>회사는 1항의 규정에 의하여 구독 취소가 접수되면 즉시 이용을 해지하거나 이용기간이 지난 후 이용을 해지합니다. 단, 별도의 채무나 채권 관계가 있을 경우에는 그러하지 아니합니다.</li>
      <li>서비스 이용 해지 후로부터 탈퇴 시까지 개인정보 및 기타 서비스 이용기록을 보호 및 보관합니다. 단, 회원탈퇴 시 즉시 삭제처리 되며, 관련법규에 의하여 보존 의무가 없는 자료만 의무보관기간에 따라 분리 보관합니다.</li>
      <li>회사는 이용자가 다음 중 하나에 해당하는 것이 객관적으로 명백하거나 관련 증거가 확보된 경우 별도의 소명기회 부여 없이 당해 서비스를 해지할 수 있습니다.</li>
    </ol>

    <ul>
      <li>서비스 이용 신청 또는 구독신청 시, 타인의 명의로 신청하였거나 신청 시 제출한 자료 및 정보가 허위 또는 누락되었음이 확인된 경우</li>
      <li>회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나, 전기통신사업법 제30조에 위반하여 제3자에게 서비스를 이용하도록 제공한 경우</li>
      <li>제10조의 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우</li>
    </ul>

    <p class="m-t-10">제 13 조 (서비스 요금 및 환불)</p>

    <ol>
      <li>회사가 제공하는 서비스는 무료서비스와 유료서비스가 있습니다. 회사가 제공하는 별도의 유료서비스에 대해서는 해당 정보에 명시된 요금을 지불하여야 사용 가능합니다.</li>
      <li>회사가 제공하는 유료서비스를 이용하고자 하는 회원은 본 약관에서 정의하고 있는 유료서비스 이용약관에 동의하고 회사에 이용요금(또는 회비)를 납부하여야 합니다.</li>
    </ol>

    <p class="m-t-10">제 14조 (약관 외 준칙)</p>

    <ol>
      <li>본 약관에 명시되지 않은 사항에 대하여는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.</li>
      <li>회사와 이용자 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.</li>
    </ol>

    <p><br />
    &nbsp;</p>

    <p>부칙</p>

    <p>제1조(시행일)</p>

    <p class="p-b-10">이 약관은 2023년 08월 07일부터 시행합니다.</p>

  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
<style>
li {
  list-style: none;
}
</style>
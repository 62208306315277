<template>
  <v-layout class="flex-flow-wrap btn-wrapper" row>
    <v-flex v-if="isEditable">
      <v-btn
        class="grade-btn font-weight-black"
        v-for="n in question.choiceItems.length"
        :key="n"
        icon large flat
        hide-details
      >{{n}}
        <span :class="{'msg': msg(n) !== ''}">{{msg(n)}}</span>
      </v-btn>
    </v-flex>
    <v-flex v-if="isEditable === undefined || !isEditable">
      <v-btn
        class="grade-btn font-weight-black m-b-16"
        :class="{'active': answer >= n - 1}"
        v-for="n in question.choiceItems.length"
        :key="n"
        @click="changeValue(question.choiceItems[n - 1].choiceValue)"
        icon large flat
        hide-details
      >{{n}}
        <span :class="{'msg': msg(n) !== ''}">{{msg(n)}}</span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerThumb',
  props: {
    question: Object,
    currentAnswer: Array,
    // changeValue: Function,
    isEditable: Boolean
  },
  data () {
    return {
      answer: this.changeValue
    }
  },
  methods: {
    msg (n) {
      let returnMsg = ''
      if (n === 1) {
        returnMsg = '전혀 동의하지 않음'
      } else if (n === Math.ceil(this.question.choiceItems.length / 2)) {
        returnMsg = '보통'
      } else if (n === this.question.choiceItems.length) {
        returnMsg = '매우 동의함'
      }
      return returnMsg
    }
  },
  mounted () {
    if (this.question.answers !== undefined && this.question.answers[0] !== undefined) {
      this.answer = this.question.answers[0]
    }
  },
  watch: {
    currentAnswer () {
      this.answer = this.currentAnswer[0]
    }
  }
}
</script>

<style lang="scss" scope>
@import '../../assets/style/variable.scss';

.grade-btn{
  position: relative;
  margin-left: 0;
  margin-right: 20px;
}

button.grade-btn{
  width: 85px !important;
  height: 40px;
  margin-right: 0;
  border: 1px solid #444;
  border-left:  none;
  border-radius: 0;
  &:first-child{
    border-left: 1px solid #444;
  }
  &:before{
    border-radius: 0;
  }
}

.msg{
  position: absolute;
  left: 50%; bottom: -40px;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: normal;
  &:before{
    display: block; content: ''; clear: both;
    position: absolute; left: 50%; top: -12px;
    width: 1px; height: 10px;
    background-color: $black;
  }
}

@media screen and (max-width: $mobile) {
  .grade-btn{
    margin-right: 10px;
    &.v-btn--large{
      width: 30px;
      height: 30px;
    }
    .v-icon{
      font-size: 18px;
    }
  }
  button.grade-btn{
    width: 50px !important;
    height: 40px;
    margin-right: 0;
    border: 1px solid #444;
    border-left:  none;
    border-radius: 0;
    &:first-child{
      border-left: 1px solid #444;
    }
    &:before{
      border-radius: 0;
    }
  }
}
</style>

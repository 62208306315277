<template>
  <v-card style="position: fixed; width: 380px; height: auto; max-height: 70%; overflow-y: auto; left: 50%; top: 50%; transform: translate(-50%, -50%); background-color: #fff; z-index: 1; border: 1px solid #ddd;">
    <v-card-title>
      <v-flex xs12 text-xs-right>
        <v-icon @click="changeMovePageDialog(false)" color="normal" large center>close</v-icon>
      </v-flex>
      <h2 v-if="sectionList.length > 1" class="title text-xs-left">{{survey.isAutoSeq ? '' : (`'` + question.displayNo + `'`)}} 질문을 이동할 페이지를 골라주세요.</h2>
      <p v-else>이동할 수 있는 페이지가 없습니다 </p>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <div style="overflow-y: hidden;">
          <v-radio-group v-model="selectedSectionId" hide-details>
            <v-radio
              class="p-b-10"
              v-for="(section) in sectionList" :key="section.sectionId"
              :label="section.sectionTitle === '' ? section.sectionSeq.toString() : section.sectionTitle"
              :value="section.sectionId"
            >
            </v-radio>
          </v-radio-group>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :class="{'blue lighten-4': true }" @click="changeMovePageDialog(false, selectedSectionId)">입력</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MovePageDialog',
  props: {
    question: Object,
    sectionList: Array,
    currentSectionId: Number,
    changeMovePageDialog: Function,
    survey: Object
  },
  data () {
    return {
      selectedSectionId: this.currentSectionId
    }
  }
}
</script>

<template>
  <v-container class="layout-container">
    <v-layout v-if="getRespondentSurveyAnswerList.length > 0" class="m-y-16 p-y-16" wrap>
      <v-flex class="section-element col-table-cell" xs3 style="box-sizing: border-box;">
        <data-table
          :list="getRespondentSurveyAnswerList"
          :is-respondent="true"
          :show-result="showResult"
        ></data-table>
      </v-flex>
      <v-flex class="response-answer col-table-cell" xs9 style="box-sizing: border-box;">
        <v-card style="padding: 50px 0;" v-if="questions.length === 0">데이터 없음</v-card>
        <div
          v-else
          v-for="(question, idx) in questions"
          :key="question.questionId"
          class="p-a-16 response-answer-element"
          :class="{ 'even-row' : idx % 2 === 0 }"
        >
          <v-flex
            class="text-xs-left m-b-16 p-l-16" xs12>
            <v-layout v-if="survey && survey.isAutoSeq" wrap>
              <v-flex xs1 style="font-weight: bold;">Q. </v-flex>
              <v-flex xs11><span class="subheading" v-html="question.questionTitle"></span></v-flex>
            </v-layout>
            <v-layout v-else wrap>
              <v-flex xs12>
                <span style="font-weight: bold; display: inline-block; width: auto; padding-right: 10px;">{{ question.displayNo }}.</span>
                <div style="display: inline-block; width: auto;" v-html="question.questionTitle"></div>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex style="font-weight: bold;" v-if="survey && survey.isAutoSeq" xs1>A. </v-flex>
              <v-flex :class="survey && survey.isAutoSeq ? 'xs11' : 'xs12'">
                <span
                  v-for="(answer, index) in getRespondentSurveyAnswers"
                  :key="question.questionId + index"
                  class="text-xs-left text-pre">
                  <div
                    class="d-inline-block width-p-100"
                    v-if="answer.answerIdx === question.answerIdx && answer.textValue !== '' && answer.textValue !== undefined && answer.textValue !== null && (question.questionType === 3 || question.questionType === 31 || question.questionType === 7)"
                  >{{ answer.textValue }}</div>
                  <div
                    class="d-inline-block width-p-100"
                    v-else-if="answer.answerIdx === question.answerIdx && (question.questionType === 3 || question.questionType === 31 || question.questionType === 7)"
                  ></div>
                  <div v-else-if="answer.choiceValue !== undefined && answer.answerIdx === question.answerIdx && question.choiceItems !== undefined">
                    <div
                        v-for="choice in question.choiceItems"
                        :key="choice.lcid"
                      > 
                      <span class="p-r-16" v-if="choice.choiceValue === answer.choiceValue && (answer.textValue == undefined || answer.textValue == null || answer.textValue === '')">
                        {{ choice.content }}
                      </span>
                      <span class="p-r-16" v-else-if="choice.choiceValue === answer.choiceValue && answer.textValue !== ''">
                        {{ choice.content }}=> {{ answer.textValue }}
                      </span>
                    </div>
                  </div>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-else class="m-y-30 p-y-16" wrap>
      <v-flex class="title text-xs-center width-p-100 section-element col-table-cell" xs12 style="box-sizing: border-box;">
        데이터 없음
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LineChart from '@/components/admin/LineChart'
import PieChart from '@/components/admin/PieChart'
import BarChart from '@/components/admin/BarChart'
import DataTable from '@/components/admin/DataTable'
import _ from 'lodash'
import { surveyMapGetters, surveyMapActions } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'
import { axiosConfig } from '../../api'

export default {
  props: {
    survey: Object,
    isDropouts: Boolean
  },
  methods: {
    ...surveyMapActions(['fetchRespondentSurveyAnswers', 'fetchRespondentSurveyAnswerList', 'fetchRespondentSurveyAnswersForDropouts']),
    showResult (answerId) {
      if (this.isDropouts) {
        this.fetchRespondentSurveyAnswersForDropouts({
          answerId: answerId,
          config: axiosConfig.setHeader(this.getToken)
        })
      } else {
        this.fetchRespondentSurveyAnswers({
          answerId: answerId,
          config: axiosConfig.setHeader(this.getToken)
        })
      }
    }
  },
  computed: {
    ...surveyMapGetters(['getRespondentSurveyAnswerList', 'getRespondentSurveyAnswers', 'getQuestionList']),
    ...accountMapGetters(['getToken']),
    questions () {
      return this.getQuestionList.filter(q => {
        return _.findIndex(this.getRespondentSurveyAnswers, ['answerIdx', q.answerIdx]) >= 0
      })
    }
  },
  mounted () {
    if (this.getRespondentSurveyAnswerList !== undefined || this.getRespondentSurveyAnswerList.length === 0) return ''
    this.showResult (getRespondentSurveyAnswerList[0].answerId)
  },
  components: {
    'line-chart': LineChart,
    'pie-chart': PieChart,
    'bar-chart': BarChart,
    'data-table': DataTable
  }
}
</script>

<style lang="scss" scope>
  @import '../../assets/style/variable.scss';
  .response-answer {
    .even-row {
      background-color: #44444410;
    }
  }
  @media screen and (max-width: $tablet) {
    .col-table-cell{
      display: block;
      width: 100%;
      margin-bottom: 10px;
      flex-basis: 100% !important;
      max-width: 100% !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

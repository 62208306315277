<template>
  <v-layout class="flex-flow-wrap">
    <v-radio-group v-if="question.answers === undefined" hide-details>
      <v-radio
        :disabled="true"
        v-for="n in question.choiceItems.length"
        :key="n"
        :label="question.choiceItems[n-1].content"
        :value="question.choiceItems[n-1].choiceValue"
      >
      </v-radio>
    </v-radio-group>
    <v-radio-group row v-model="questionCopy.answers[0]" v-else-if="question.questionType !== 4" hide-details>
      <v-radio
        v-for="n in question.choiceItems.length"
        :key="n"
        @change="changeValue(question.choiceItems[n-1].choiceValue)"
        :label="question.choiceItems[n-1].content"
        :value="question.choiceItems[n-1].choiceValue"
      >
      </v-radio>
    </v-radio-group>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerRadio',
  props: {
    question: Object
    // questionCopy: Object,
    // changeValue: Function
  }
}
</script>

<script>
import VueChartJs from 'vue-chartjs'

export default {
  extends: VueChartJs.Pie,
  props: {
    test: Array,
    tester: Array
  },
  mounted () {
    this.renderChart({
      labels: this.test,
      datasets: this.tester
    }, { responsive: true, maintainAspectRatio: false }
    )
  }
}
</script>

<template>
  <v-card v-if="section !== undefined" class="section-wrapper" id="section-wrapper" xs12>
    <v-flex class="p-a-16 p-b-10 page-card card-tag">
      <v-text-field id="section-title-input-box" v-model="section.sectionTitle" label="페이지 제목"></v-text-field>
      <v-layout>
        <v-checkbox class="m-t-0 p-r-10" v-model="section.isUse" label="페이지 노출"></v-checkbox>
        <!-- <v-flex text-xs-right>
          <v-btn
            small outline
            color="indigo"
            class="m-t-0 m-r-0"
            draggable="false"
            @click="sectionUpdate(section)"
          >페이지 편집</v-btn>
        </v-flex> -->
      </v-layout>
    </v-flex>
    <v-flex class="height-p-100 card-tag">
      <v-subheader>페이지 정렬 순서</v-subheader>
      <draggable :disabled="!isEditable || isMobile" :list="sectionList" id="drag-box" class="drag-box">
        <transition-group
          class="section-list"
          :style="{width: pageListWidth + 'px', minWidth: (2 * 106) + 'px'}"
          tag="div"
          name="transition-section-list">
          <div
            class="d-inline-block"
            style="box-sizing: border-box;"
            v-for="(section, index) in sectionList"
            :key="section.sectionId"
            @dragend="resetLink(section, false)"
          >
            <v-btn
              outline
              :color="section.sectionId === currentSection ? '#5AA3DD' : '#999999'"
              class="section"
              :class="{currentSectionClass : section.sectionId === currentSection}"
              @click="changeSection(null, section.sectionId); setScroll(index);"
            >
              <span v-if="section.sectionTitle === undefined || section.sectionTitle === ''">{{section.sectionTitle === '' ? section.sectionSeq.toString() : section.sectionTitle}}</span>
              <span v-else>{{section.sectionTitle}}</span>
            </v-btn>
          </div>
          <div
            v-if="isEditable"
            class="d-inline-block"
            style="box-sizing: border-box;"
            :key="9999"
          >
            <v-btn
              outline
              class="section"
              draggable="false"
              @click="changeSection(true, null)"
            >
              <v-icon dark>add</v-icon>
            </v-btn>
          </div>
        </transition-group>
      </draggable>
      <!-- <v-flex class="p-r-10" text-xs-right>
        <v-btn @click="changeSection(true, null)" fab dark small color="#5AA3DD" flat>
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-flex> -->
    </v-flex>
  </v-card>
</template>

<script>

import draggable from 'vuedraggable'
import { surveyMapGetters } from '@/store/modules/survey/survey'
// import _ from 'lodash'

export default {
  name: 'CreateSection',
  props: {
    sectionList: Array,
    currentSection: Number,
    changeSection: Function,
    resetLink: Function,
    isEditable: Boolean
  },
  components: {
    draggable
  },
  computed: {
    ...surveyMapGetters(['getSectionList']),
    section () {
      return this.sectionList.filter(section => { return section.sectionId === this.currentSection })[0]
    },
    pageListWidth () {
      if (this.isEditable) {
        return (this.sectionList.length * 106) + 106
      } else {
        return (this.sectionList.length * 106)
      }
    },
    isMobile () {
      let UserAgent = navigator.userAgent;
	    if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null){ return true }else{ return false }
    }
  },
  methods: {
    setScroll (index) {
      const card1Width = document.getElementsByClassName('card-tag')[0].offsetWidth
      const card2Width = document.getElementsByClassName('card-tag')[1].offsetWidth
      const dragBoxHalfWidth = document.getElementById('drag-box').offsetWidth / 2
      let sectionBtnHalfWidth
      let sectionLeft
      let cardWidth = 0
      if (index === -1) {
        sectionLeft = document.getElementsByClassName('currentSectionClass')[0].offsetLeft
        sectionBtnHalfWidth = document.getElementsByClassName('currentSectionClass')[0].offsetWidth / 2
      } else {
        sectionLeft = document.getElementsByClassName('section')[index].offsetLeft
        sectionBtnHalfWidth = document.getElementsByClassName('section')[index].offsetWidth / 2
      }
      if (card1Width !== card2Width) cardWidth = card1Width
      document.getElementById('drag-box').scrollLeft = (sectionLeft - dragBoxHalfWidth - cardWidth + sectionBtnHalfWidth)
    }
  },
  mounted () {
    if (this.getSectionList && this.getSectionList.length > 0) {
      this.setScroll(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';

.v-stepper__header{height: auto}
::v-deep .v-input--selection-controls__ripple {
  z-index : 1;
}
.section-wrapper{
  width: 100%;
  &:after{
    display: block; content: ''; clear: both;
  }
  .card-tag{
    &:nth-child(1){
      float: left;
      position: relative;
      width: 300px;
      min-width: 255px;
      &:after{
        display: block;
        content: '';
        clear: both;
        position: absolute;
        right: 0; top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 30px);
        border-left: 1px dashed #ccc;
      }
    }
    &:nth-child(2){
      float: right;
      width: calc(100% - 340px);
    }
  }
}
.drag-box {
  overflow-x: auto;
  max-width: 668px;
  width: 100%;
  .section-list {
    height: 45px;
    overflow-y: hidden;
    margin-left: 10px;
    .section {
      display: inline-block;
      width: 50px !important;
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin-right: 10px;
      text-transform: none;
      span{
        text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
      }
    }
    .fixed-section {
      &:before{
        opacity: 0;
      }
    }
  }
}
.sortable-ghost {
  opacity: 0.4;
}

@media (max-width: $mobile) {
  .section-wrapper{
    display: block;
    .card-tag{
      display: block;
      box-sizing: border-box;
      &:nth-child(1){
        float: none;
        width: 100%;
        &:after{
          right: auto; top: auto;
          bottom: 0; left: 10px;
          transform: translateY(0);
          width: calc(100% - 20px);
          height: 1px;
          border-top: 1px dashed #ccc;
        }
      }
      &:nth-child(2){
        float: none;
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <v-layout wrap>
    <v-flex xs12 lg6>
      <v-menu
        ref="menu1"
        v-model="menu1"
        content-class="data-range-menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :disabled="originSurvey != undefined && originSurvey.submitDate != null"
        lazy
        transition="scale-transition"
        offset-y
        full-width
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="childStartDate"
            label="설문 시작일"
            persistent-hint
            :disabled="originSurvey != undefined && originSurvey.submitDate != null"
            prepend-icon="event"
            @blur="childStartDate = parseDate(formatDate(childStartDate))"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker :min="minDate" v-model="childStartDate" no-title @input="menu1 = false"></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 lg6>
      <v-menu
        ref="menu2"
        v-model="menu2"
        content-class="data-range-menu"
        :close-on-content-click="false"
        :disabled="originSurvey != undefined && originSurvey.submitDate != null"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="childEndDate"
            label="설문 종료일"
            content-class="data-range-menu"
            persistent-hint
            :disabled="originSurvey != undefined && originSurvey.submitDate != null"
            prepend-icon="event"
            @blur="childEndDate = parseDate(formatDate(childEndDate))"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker :min="endMinDate" v-model="childEndDate" no-title @input="menu2 = false"></v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</template>

<script>

import { surveyMapGetters } from '@/store/modules/survey/survey'

export default {
  props: {
    startDate: String,
    endDate: String
  },
  data () {
    return {
      menu1: false,
      menu2: false,
      childStartDate: this.startDate,
      childEndDate: this.endDate,
      minDate: new Date().toISOString().substr(0, 10),
      startDateFormatted: this.formatDate(new Date().toISOString().substr(0, 10))
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyList']),
    selectId () {
      return parseInt(this.$route.params.surveyId)
    },
    originSurvey () {
      return this.getSurveyList.filter(survey => { return survey.surveyId === this.selectId })[0]
    },
    endMinDate () {
      return this.childStartDate
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  },
  watch: {
    childStartDate () {
      if (this.childStartDate > this.childEndDate) {
        this.childEndDate = this.childStartDate
      }
      this.$emit('setDateRange', { start: this.childStartDate, end: null })
    },
    childEndDate () {
      this.$emit('setDateRange', { start: null, end: this.childEndDate })
    }
  }
}
</script>

<style>
.data-range-menu.v-menu__content{
  min-width: 290px !important;
}
</style>

export default {
  getDayOfWeek: date => {
    const week = ['일', '월', '화', '수', '목', '금', '토']
    return week[new Date(date).getDay()]
  },

  getDayOfWeekForIndex: index => {
    const week = ['일', '월', '화', '수', '목', '금', '토']
    return week[index]
  },

  dateFormatDefault: date => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    return localDate.toISOString().substr(0, 10)
  },

  dateFormatKorea: date => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formatDate = localDate.toISOString()
    return `${formatDate.substring(0, 4)}년 ${parseInt(formatDate.substring(5, 7))}월 ${parseInt(formatDate.substring(8, 10))}일`
  },

  convertDateDash: date => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formatDate = localDate.toISOString()
    return `${formatDate.substring(0, 4)}-${formatDate.substring(5, 7)}-${formatDate.substring(8, 10)}`
  },

  getStartEndDateThisWeek: date => {
    const startDate = new Date()
    const endDate = new Date()
    let localStartDate
    let localEndDate
    const todayNumber = new Date(date).getDay()

    switch (todayNumber) {
    case 0:
      startDate.setDate(startDate.getDate() - 6)
      endDate.setDate(endDate.getDate())
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 1:
      startDate.setDate(startDate.getDate())
      endDate.setDate(endDate.getDate() + 6)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 2:
      startDate.setDate(startDate.getDate() - 1)
      endDate.setDate(endDate.getDate() + 5)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 3:
      startDate.setDate(startDate.getDate() - 2)
      endDate.setDate(endDate.getDate() + 4)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 4:
      startDate.setDate(startDate.getDate() - 3)
      endDate.setDate(endDate.getDate() + 3)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 5:
      startDate.setDate(startDate.getDate() - 4)
      endDate.setDate(endDate.getDate() + 2)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }

    case 6:
      startDate.setDate(startDate.getDate() - 5)
      endDate.setDate(endDate.getDate() + 1)
      localStartDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))
      localEndDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))
      return { startDate: localStartDate.toISOString().substring(0, 10), endDate: localEndDate.toISOString().substring(0, 10) }
    }
  },

  timeFormatDefault: timeString => {
    if (timeString.length === 4) {
      const hour = timeString.substring(0, 2)
      const time = timeString.substring(2, 4)
      return `${hour}:${time}`
    } else if (timeString.length === 8) {
      const hour = timeString.substring(0, 2)
      const time = timeString.substring(3, 5)
      return `${hour}:${time}`
    } else {
      return timeString
    }
  },

  timeFormat12H: timeString => {
    if (timeString !== null && timeString.length === 4) {
      let hour = timeString.substring(0, 2)
      const minute = timeString.substring(2, 4)
      if (hour < 12) {
        hour = hour === '00' ? 12 : hour
        return `오전 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      } else {
        hour = hour - (hour > 12 ? 12 : 0)
        return `오후 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      }
    } else if (timeString !== null && timeString.length === 5) {
      let hour = timeString.substring(0, 2)
      const minute = timeString.substring(3, 5)
      if (hour < 12) {
        hour = hour === '00' ? 12 : hour
        return `오전 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      } else {
        hour = hour - (hour > 12 ? 12 : 0)
        return `오후 ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      }
    } else {
      return timeString
    }
  },

  dateFormatWithDay: date => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    const formatDate = localDate.toISOString()
    const week = ['일', '월', '화', '수', '목', '금', '토']
    return `${formatDate.substring(0, 4)}-${formatDate.substring(5, 7)}-${formatDate.substring(8, 10)}(${week[date.getDay()]})`
  },

  changeDateFromNoDash: strDate => {
    if (strDate.length === 8) {
      return new Date(strDate.substring(0, 4) + '-' + strDate.substring(4, 6) + '-' + strDate.substring(6, 8))
    }
  },

  toFullDateString: (dateString, timeString) => `${dateString.replace(/-/g, '')}${timeString.replace(':', '')}00`,

  dateFormatGetYear: dateString => `${dateString.substring(0, 4)} 년`,

  getStartEndDateThisYear: date => {
    let selectedYear
    selectedYear = date.getFullYear()
    return { startDate: `${selectedYear}-01-01`, endDate: `${selectedYear}-12-31` }
  },

  convertDateFormatKorea: dateString => {
    if (!dateString) {
      return ''
    }
    if (dateString.length === 8) {
      return `${dateString.substring(0, 4)}년 ${parseInt(dateString.substring(4, 6))}월 ${parseInt(dateString.substring(6, 8))}일`
    } else {
      return `${dateString.substring(0, 4)}년 ${parseInt(dateString.substring(5, 7))}월 ${parseInt(dateString.substring(8, 10))}일`
    }
  },

  convertDateFormatKoreaFull: dateString => {
    return `${dateString.substring(0, 4)}년 ${parseInt(dateString.substring(4, 6))}월 ${parseInt(dateString.substring(6, 8))}일 ${parseInt(dateString.substring(8, 10))}시 ${parseInt(dateString.substring(10, 12))}분`
  },

  convertDateFormatDefault: dateString => {
    if (dateString.length === 8) {
      return `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(6, 8)}`
    } else {
      return dateString
    }
  },
  addDays: (date, days) => {
    date.setDate(date.getDate() + days)
    return date
  },
  convertDateFormatDB: dateString => `${dateString.replace(/-/g, '')}`,

  convertTimeFormatToDB: (meridiem, hour, minute) => {
    let hourConverted = 0
    hourConverted = hour + (meridiem === this.$t('components.STRING_AM') ? 0 : 12)
    hourConverted = hourConverted - ((hourConverted % 12) === 0 ? 12 : 0)

    return `${hourConverted.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
  },

  convertDateFormatFromDB: dateString => {
    if (dateString.length === 8) {
      return `${dateString.substring(0, 4)}년 ${dateString.substring(4, 6)}월 ${dateString.substring(6, 8)}일`
    } else {
      return dateString
    }
  },

  setHoursFromDB: dateString => {
    let myDate = new Date(dateString.substring(0,19))
    const localDate = new Date(myDate.getTime() - (myDate.getTimezoneOffset() * 60000))
    const strDate = localDate.toISOString().substring(0, 10)
    const strTime = localDate.toISOString().substring(11, 19)
    return strDate + ' ' + strTime
  }
}

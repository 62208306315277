<template>
  <div>
    <div class="bg-layer"></div>
    <v-card class="link-dialog">
      <v-layout class="display-table">
        <span @click="linkDialogVisible(false)" class="display-1 close-btn cursor-pointer"><i class="fas fa-times"></i></span>
        <v-flex class="text-xs-center display-table-cell">
          <div class="m-y-30">
            <span class="display-1 d-block">{{ linkSurvey.contents }}</span>
            <span class="headline d-block">설문 링크 생성</span>
          </div>
          <v-btn class="m-y-16 subheading cursor-pointer p-a-16" color="grey darken-1" dark @click="copyLink(anonymityNum, anonymityNum)"><i class="fas fa-link m-r-10"></i>클릭하여 설문 링크 복사 <span class="font-weight-bold">[익명]</span></v-btn>
          <input class="text-xs-center d-block cursor-pointer" :style="inputHiddenStyleStr" type="text" :id="'linkInput' + anonymityNum">
          <div class="p-a-16" :style="btnStyleStr">
            <h2 class="subheading text-xs-center m-y-16">지정 사용자 URL 생성하기</h2>
            <v-layout class="m-b-16">
              <v-flex xs8>
                <v-text-field
                  placeholder="답변자 식별 번호"
                  v-model.trim="respondentId"
                  :error-messages="errMsg"
                  :hide-details="isHideDetails"
                  @keypress.enter="addLink()"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-btn @click="addLink()">추가하기</v-btn>
              </v-flex>
            </v-layout>
          </div>
          <v-layout class="linkList-wrap m-t-16" wrap>
            <v-flex
              xs12
              v-for="(link,idx) in linkList.slice().reverse()"
              :key="idx"
              class="d-inline-block p-a-16 cursor-pointer"
              style="border-bottom: 1px solid #ddd; position: relative;"
              :style="idx === 0 ? 'border-top: 1px solid #ddd;' : ''"
              >
              <input class="text-xs-left d-block cursor-pointer" :style="inputHiddenStyleStr" type="text" :id="'linkInput' + idx">
              <span class="d-block text-xs-left" @click="copyLink(link, idx)"><i class="fas fa-link m-r-10"></i>클릭하여 설문 링크 복사 [{{link}}]</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
const rootUrl = /* window.location.origin + */ 'https://survey.u2cloud.co.kr/u2s-web/param/'
export default {
  props: {
    linkSurvey: Object,
    linkDialogVisible: Function
  },
  data () {
    return {
      respondentId: '',
      errMsg: '',
      isHideDetails: true,
      anonymityNum: 9999999,
      linkList: [],
      btnStyleStr: 'position: relative; background-color: rgb(255 255 255 / 60%); border: 1px solid #ddd; border-radius: 4px; box-sizing: border-box;',
      inputHiddenStyleStr: 'style="outline: none; opacity: 0; width: 1px; height: 1px; position: absolute; left: 50%; top: calc(50% - 20px); transform: translate(-50%, -50%)"'
    }
  },
  computed: {
    trimRespondentId () {
      return this.respondentId.replaceAll(' ', '')
    }
  },
  methods: {
    copyLink (link, idx) {
      let id = 'linkInput' + idx
      let copyText = document.getElementById(id)
      copyText.value = this.createLink(link)
      if (copyText.value) {
        copyText.disabled = false
        copyText.select()
        copyText.setSelectionRange(0, 99999) /* For mobile devices */
        document.execCommand('copy')
        alert('복사완료')
        copyText.disabled = true
      } else {
        alert('오류 발생. 링크 복사 불가')
      }
    },
    addLink () {
      if (this.trimRespondentId === '') {
        this.isHideDetails = false
        this.errMsg = '빈 식별 번호는 익명 설문 링크를 이용해주세요.'
      } else if (this.linkList.includes(this.trimRespondentId)) {
        this.isHideDetails = false
        this.errMsg = '이미 url을 생성한 답변자 식별 번호입니다.'
      } else {
        this.isHideDetails = true
        this.errMsg = ''
        this.linkList.push(this.trimRespondentId)
      }
    },
    movePageMain () {
      let isTrue = confirm('설문 리스트 페이지로 이동하시겠습니까?')
      if (isTrue) {
        this.$router.push({name: 'MySurveyList'})
      }
    },
    createLink (id) {
      let url = 'surveyId/' + this.linkSurvey.surveyId
      if (id === this.anonymityNum) {
        id = ''
      }
      if (this.linkSurvey.surveyId) return rootUrl + btoa(url).replaceAll('/', '_') + '/res/' + id
      else return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.bg-layer{
  position: fixed;
  left: 0; top: 0;
  z-index: 1;
  width: 100vw; height: 100vh;
  background-color: rgba(0,0,0,0.5);
}
.link-dialog {
  position: fixed;
  left: 50%; top: 50%;
  z-index: 2;
  width: 100%; height: auto;
  max-width: 600px;
  max-height: 780px;
  padding: 20px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  .close-btn{
    position: absolute;
    right: 0; top: 0;
    z-index: 3;
  }
  .linkList-wrap{
    max-height: 250px;
    overflow-y: auto;
  }
}
.display-table{
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  &-cell{
    display: table-cell;
    vertical-align: middle;
  }
}
</style>

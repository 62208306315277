<template>
  <div>
    <h2 style="margin-left:3vw; text-align:center" class="m-b-10">U2Survey 개인정보처리방침</h2>

    <p class="p-t-10">U2Bio(이하 &ldquo;회사&rdquo;)는 정보주체의 최초 회원 가입 또는 서비스 이용 시 사용자로부터 아래와 같은 개인정보를 수집하고 있습니다</p>

    <p>제 1조. 수집하는 개인정보 항목 </p>

    <p>- 서비스 이용 구독 시 수집하는 개인정보(필수): 이름, 휴대전화번호, DI, U2Cloud ID)</p>

    <p style="margin-left:2.5px">&nbsp;</p>

    <p>제 2조. 개인정보의 수집 및 이용목적 </p>

    <p>- 회원관리 및 본인확인: 고객관리 시스템 서비스 이용에 따른 본인확인, 개인식별, 불량고객의 부정 이용 방지와 비인가 사용방지, 불만처리 등 민원처리, 고지사항 전달</p>

    <p>&nbsp;</p>

    <p>제 3조. 개인정보 보유 및 이용기간</p>

    <p>- 서비스 이용계약 종료 시(단,관련법령에 따라 별도 보관되는 정보는 예외)</p>

    <p>&nbsp;</p>

    <p>제 4조. 개인정보의 제3자 제공</p>

    <ol>
      <li>회사는 회원의 개인정보를 제 2조에서 고지한 범위 내에서 사용하며, 동의 범위를 초과하여 이용하거나 원칙적으로 제3자에게 제공하지 않습니다. </li>
    </ol>

    <p style="margin-left: 2vw">다만 다음은 예외로 합니다.</p>

    <ul>
      <li>사용자들이 사전에 동의한 경우</li>
      <li>정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로 명백히 정보주체 또는 제 3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
      <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
      <li>통계작성&middot;학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</li>
      <li>개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의&middot;의결을 거친 경우</li>
      <li>회사가 제공하는 서비스를 통하여 거래가 성사된 경우, 거래 당사자간의 원활한 의사소통을 위하여 관련된 정보를 필요한 범위 내에서 거래당사자에게 제공하는 경우</li>
    </ul>

    <p>&nbsp;</p>

    <p><span style="color:#ed7d31">제 5조. 개인정보보호 책임자 및 담당자&nbsp;</span></p>

    <p>[개인정보보호 책임자]</p>

    <ul>
      <li>성명 : 김지원</li>
      <li>소속 : IT연구소</li>
      <li>연락처 : 02-910-2140</li>
    </ul>

    <p>&nbsp;</p>

    <p>[개인정보 처리 담당자]</p>

    <ul>
      <li>성명 : 박성호</li>
      <li>소속 : 플랫폼팀</li>
      <li>연락처 : 02-910-2144</li>
      <li>이메일 : itlab@u2bio.co.kr</li>
    </ul>

    <p>&nbsp;</p>

    <p>&nbsp;</p>

    <p>제 6조. 개인정보의 파기에 관한 사항</p>

    <ol>
      <li>회사는 「개인정보의 처리목적」이 달성된 후에는 즉시 파기합니다. 파기절차 및 방법은 다음과 같습니다.</li>
      <li>파기 절차 : 개인정보는 목적 달성 후 즉시 또는 별도의 공간에 옮겨져 내부 방침 및 기타 관련법령에 따라 일정기간 저장된 후 파기됩니다. 별도의 공간으로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</li>
      <li>파기 기한 : 보유기간이 만료되었거나 개인정보의 처리 목적 달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다.</li>
      <li>파기 방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
    </ol>

    <p>&nbsp;</p>

    <p>제 7조. 개인정보의 안전성 확보 조치에 관한 사항</p>

    <ul>
      <li>회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 관리적ㆍ기술적 및 물리적 조치를 하고 있습니다.</li>
      <li>개인정보취급자의 최소화 및 교육</li>
    </ul>

    <p style="margin-left: 2vw">개인정보취급자는 반드시 필요한 인원에 한하여 지정ㆍ관리하고 있으며 개인정보취급자를 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.</p>

    <ul>
      <li>개인정보보호 내부관리운영계획의 수립 및 시행</li>
    </ul>

    <p style="margin-left: 2vw">개인정보의 안전한 처리를 위하여 내부관리운영계획을 수립하고 시행하고 있습니다.</p>

    <ul>
      <li>개인정보에 대한 접근 제한</li>
    </ul>

    <p style="margin-left: 2vw">개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여ㆍ변경ㆍ말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>

    <ul>
      <li>개인정보의 암호화</li>
    </ul>

    <p style="margin-left: 2vw">개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p>

    <ul>
      <li>보안프로그램 설치 및 주기적 점검ㆍ갱신</li>
    </ul>

    <p style="margin-left: 2vw">해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신ㆍ점검하고 있습니다.</p>

    <ul>
      <li>비인가자에 대한 출입 통제</li>
    </ul>

    <p style="margin-left: 2vw">개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p>

    <p style="margin-left: 2vw">&nbsp;</p>

    <p style="margin-left: 1vw">&nbsp;</p>

    <p style="margin-left: 1vw">제 8조 정보주체 및 법정대리인의 권리&middot;의무 및 그 행사방법에 관한 사항</p>

    <p style="margin-left: 1vw">회원은 회사에 대하여 언제든지 자신의 개인정보에 대하여 열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할 수 있습니다.</p>

    <p style="margin-left: 1vw">회원이 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용하지 않습니다.</p>

    <ul>
      <li>로그인 &rarr; 내정보 &rarr; 수정 또는 탈퇴</li>
    </ul>

    <p>&nbsp;</p>

    <p>제 9조. 개인의료정보 관련 민원 및 불만처리에 관한 사항</p>

    <p style="margin-left: 1vw">사용자는 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 접수하실 수 있습니다. 기타 개인정보침해에 대한 신고나 상담이 필요한 경우 아래 기관에 문의하여 주시기 바랍니다.</p>

    <ul>
      <li>개인정보침해신고센터(<a href="http://privacy.kisa.or.kr/" style="color:#0563c1; text-decoration:underline" target="_blank;">http://privacy.kisa.or.kr</a>) : (국번없이) 118</li>
      <li>개인정보분쟁조정위원회(<a href="https://www.kopico.go.kr/" style="color:#0563c1; text-decoration:underline" target="_blank;">https://www.kopico.go.kr</a>) : (국번없이) 118 (내선2번)</li>
      <li>대검찰청사이버범죄수사단(<a href="http://www.spo.go.kr/" style="color:#0563c1; text-decoration:underline" target="_blank;">http://www.spo.go.kr</a>) : 02-3480-3571</li>
      <li>경찰청 사이버테러대응센터(<a href="http://www.netan.go.kr/" style="color:#0563c1; text-decoration:underline" target="_blank;">http://www.netan.go.kr</a>) : 1566-0112</li>
      <li>중앙행정심판위원회(<a href="http://www.simpan.go.kr/" style="color:#0563c1; text-decoration:underline" target="_blank;">http://www.simpan.go.kr</a>) : 1588-1577 (상세 업무는 전화번호 안내 참조)</li>
    </ul>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
<style>
li {
  list-style: none;
}
</style>
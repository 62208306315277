<template>
  <v-layout class="display-table">
    <v-flex class="text-xs-center display-table-cell" style="min-height: 300px;">
      <i class="fas fa-check-circle" style="color: #1976d2;"></i>
      <dl>
        <dt class="display-1 m-t-30">관리자 가입 완료</dt>
        <dd @click="movePageLogin" class="headline m-t-10 m-b-30 cursor-pointer">로그인하러 가기</dd>
      </dl>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'JoinFinish',
  methods: {
    movePageLogin () {
      this.$router.push({name: 'AdminLogin'})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
.display-table{
  display: table;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  &-cell{
    display: table-cell;
    vertical-align: middle;
  }
}
.fa-check-circle{
  font-size: 150px;
}
@media screen and (max-width: $mobile) {
  .fa-check-circle{
    font-size: 90px;
  }
}
</style>

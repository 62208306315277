<template>
  <v-container class="layout-container">
    <v-layout v-if="isLoading" style="position: fixed; width: 100vw; height: 100vh; left: 0; top: 0; z-index: 9998; background: rgba(0,0,0,0.4)">
      <div class="text-xs-center" style="position: absolute; left: 50%; top: 50%; z-index: 9999; transform: translate(-50%, -50%)">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-layout>
    <v-layout v-else class="m-y-30 p-y-16 chart-wrapper" wrap>
      <v-flex class="section-element p-r-16" xs12 style="box-sizing: border-box;">
        <v-tabs
          centered
          color="white"
          icons-and-text
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            :href="'#tab-' + idx"
          > {{ tab }}
          </v-tab>
          <v-tab-item value="tab-0">
            <statistics-question
              :isDropouts="isDropouts"
            ></statistics-question>
          </v-tab-item>
          <v-tab-item class="white" value="tab-1">
            <statistics-respondent
              :isDropouts="isDropouts"
              :survey="survey"
            ></statistics-respondent>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { axiosConfig } from '../../api/index'
import { surveyMapGetters, surveyMapActions } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'
import StatisticsQuestion from '@/components/account/StatisticsQuestion'
import StatisticsRespondent from '@/components/account/StatisticsRespondent'

export default {
  data () {
    return {
      isLoading: true,
      survey: {},
      model: 'tab-0'
      // results: []
    }
  },
  computed: {
    ...surveyMapGetters(['getRespondentSurveyAnswerList', 'getRespondentSurveyAnswers', 'getQuestionList']),
    ...accountMapGetters(['getToken']),
    isDropouts () {
      return this.$route.params && this.$route.params.isDropouts !== undefined && this.$route.params.isDropouts !== null && parseInt(this.$route.params.isDropouts) === 1
    },
    tabs () {
      if (this.$route.params && this.isDropouts) {
        return['전체 결과', '중도탈락자별 결과']
      } else {
        return ['전체 결과', '답변자별 결과']
      }
    }
  },
  methods: {
    ...surveyMapActions(['fetchRespondentSurveyAnswerList', 'fetchRespondentSurveyAnswerListForDropouts', 'setRespondentSurveyAnswersReset', 'fetchRespondentSurveyAnswers', 'fetchRespondentSurveyAnswersForDropouts', 'fetchQuestionList'])
  },
  mounted () {
    // this.results = []
    this.survey = this.$route.params.survey
    this.setRespondentSurveyAnswersReset()
    if (this.isDropouts) {
      this.fetchRespondentSurveyAnswerListForDropouts({
        surveyId: this.$route.params.surveyId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.fetchQuestionList({
          surveyId: this.$route.params.surveyId,
          config: axiosConfig.setHeader(this.getToken)
        }).then(() => {
          if (this.getRespondentSurveyAnswerList.length === 0) {
            this.isLoading = false
          } else {
            this.fetchRespondentSurveyAnswersForDropouts({
              answerId: this.getRespondentSurveyAnswerList[0].answerId,
              config: axiosConfig.setHeader(this.getToken)
            }).then(() => {
              this.isLoading = false
            })
          }
        })
      }).catch((err) => {
        this.isLoading = false
        throw Error(err)
      })
    } else {
      this.fetchRespondentSurveyAnswerList({
        surveyId: this.$route.params.surveyId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.fetchQuestionList({
          surveyId: this.$route.params.surveyId,
          config: axiosConfig.setHeader(this.getToken)
        }).then(() => {
          if (this.getRespondentSurveyAnswerList.length === 0) {
            this.isLoading = false
          } else {
            this.fetchRespondentSurveyAnswers({
              answerId: this.getRespondentSurveyAnswerList[0].answerId,
              config: axiosConfig.setHeader(this.getToken)
            }).then(() => {
              this.isLoading = false
            })
          }
        })
      }).catch((err) => {
        this.isLoading = false
        throw Error(err)
      })
    }
  },
  components: {
    'statistics-question': StatisticsQuestion,
    'statistics-respondent': StatisticsRespondent
  }
}
</script>

<style lang="scss" scope>
   .chart-wrapper {
     max-width: 1000px;
     margin-left: auto;
     margin-right: auto;
   }
</style>

import AccountAPI from '../../../api/api-account'

export default {
  checkToken () {
    if (localStorage.getItem('main-token-survey')) {
      return localStorage.getItem('main-token-survey')
    } else if (sessionStorage.getItem('main-token-survey')) {
      return sessionStorage.getItem('main-token-survey')
    } else {
      return null
    }
  },

  async fetchSurveySubscribeCnt ({ commit }, { params, config }) {
    try {
      return await AccountAPI.selectSurveySubscribeCnt(params, config)
    } catch (error) {
      if (error.response.status === 401) {
        throw Error(error.response.data.message)
      } else {
        throw Error(error)
      }
    }
  },

  async login ({ commit }, { params, isAutoLogin }) {
    try {
      const response = await AccountAPI.login(params)
      commit('setLoginId', params.loginId, isAutoLogin)
      commit('setToken', response.data.jsonWebToken, isAutoLogin)
      commit('setLogin', response.data.jsonWebToken != null)
      return response
    } catch (error) {
      throw error
    }
  },

  async signUp ({ commit } , { params }) {
    try {
      return await AccountAPI.signUp(params)
    } catch (err) {
      throw err
    }
  },

  async checkId ({ commit }, { loginId }) {
    try {
      return await AccountAPI.checkId(loginId)
    } catch (err) {
      throw err
    }
  },
  
  logout ({ commit }) {
    sessionStorage.removeItem('survey-login-id')
    sessionStorage.removeItem('main-token-survey')
    localStorage.removeItem('survey-login-id')
    localStorage.removeItem('main-token-survey')
  }
}

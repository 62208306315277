<template>
  <v-dialog persistent
    :value="isOpenCheckPassowrdDialog"
    v-if="isOpenCheckPassowrdDialog"
    class="dialog-default"
    max-width="400"
  >
    <div class="bg-white p-x-16 p-b-16 p-t-10 border-radius-20" flat>
      <v-layout row class="fullscreen-dialog__header width-p-100">
        <div class="header-side-left visibility-hidden cursor-pointer">
        </div>
        <v-spacer></v-spacer>
        <div class="header-title headline">비밀번호 입력</div>
        <v-spacer></v-spacer>
        <div class="header-side-right">
        </div>
      </v-layout>
      <div>
        <p class="subheading m-t-10 m-b-30">설문 제출을 위해서 비밀번호를 입력해주세요.</p>
        <v-text-field
          label="비밀번호"
          @keydown.enter="checkPassword()"
          :type="isPasswordMode ? 'password' : 'text'"
          :append-icon="isPasswordMode ? 'visibility_off' : 'visibility'"
          @click:append="isPasswordMode = !isPasswordMode"
          v-model="password"
        ></v-text-field>
      </div>
      <v-layout class="p-t-10">
        <v-flex class="m-r-16" xs6>
          <v-btn large class="width-p-100 bg-lightgray" @click="$emit('close')" depressed>취소</v-btn>
        </v-flex>
        <v-flex class="m-r-16" xs6>
          <v-btn large class="width-p-100" @click="checkPassword()" color="primary" depressed>제출</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-dialog>
</template>

<script>
import { accountMapGetters } from '@/store/modules/account/account'

export default {
  props: {
    isOpenCheckPassowrdDialog: Boolean
  },
  data () {
    return {
      isPasswordMode: true,
      password: null
    }
  },
  watch: {
    isOpenCheckPassowrdDialog () {
      if (!this.isOpenCheckPassowrdDialog) {
        this.isPasswordMode = true
        this.password = null
      }
    }
  },
  computed: {
    ...accountMapGetters(['getToken', 'getLoginId'])
  },
  methods: {
    checkPassword () {
      if (this.password && this.password.trim().length > 0) {
        this.$emit('checkSubscribeCnt', {
          loginId: this.getLoginId,
          loginPw: this.password
        })
      } else {
        alert('비밀번호를 입력해주세요.')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
</style>

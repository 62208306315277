<template>
  <v-layout class="layout-container">
    <v-flex class="m-t-30" xs12 sm8 offset-sm2>
      <v-card style="max-width: 750px; margin: auto;" class="p-a-16">
        <h2 class="text-xs-center headline m-y-30">{{ getToken === null ? '관리자 가입' : '회원 정보 수정' }}</h2>
        <form class="p-a-16">
          <v-layout  v-if="getToken === null">
            <v-flex xs10>
              <v-text-field
                v-model.trim="email"
                required
                :error-messages="errMsg && this.email === '' ? '이 필드는 빈 값일 수 없습니다.' : ''"
                :messages="(isChk ? (isPass ? '중복된 아이디' : '사용 가능한 아이디') : '')"
                label="이메일"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-btn @click="idChecker(); isChk = true">중복확인</v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-else>
            <v-flex xs12>
              <v-text-field
                v-model.trim="email"
                required
                :disabled="true"
                :messages="'변경할 수 없는 값입니다'"
                label="이메일"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-text-field
            v-model.trim="name"
            required
            label="성함"
            :error-messages="errMsg && this.name === '' ? '이 필드는 빈 값일 수 없습니다.' : ''"
          ></v-text-field>
          <v-text-field
            v-model.trim="password"
            required
            type="password"
            label="비밀번호"
            :error-messages="errMsg && this.password === '' ? '이 필드는 빈 값일 수 없습니다.' : ''"
          ></v-text-field>
          <v-text-field
            v-model.trim="repassword"
            required
            type="password"
            @blur="checkValue"
            :error-messages="(!passwordValue || this.password === this.repassword ? '' : '일치하지 않는 비밀번호') || (errMsg && this.repassword === '' ? '이 필드는 빈 값일 수 없습니다.' : '')"
            label="비밀번호 재입력"
          ></v-text-field>
          <v-btn v-if="getToken === null" @click="clear">입력 초기화</v-btn>
          <v-btn @click="submit">{{getToken !== null ? '수정' : '가입'}}</v-btn>
        </form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { accountMapGetters, accountMapActions } from '@/store/modules/account/account'

export default {
  name: 'Join',
  data () {
    return {
      name: '',
      password: '',
      repassword: '',
      email: '',
      passwordValue: false,
      errMsg: false,
      isPass: false,
      isChk: false
    }
  },
  computed: {
    ...accountMapGetters(['getToken']),
    submitBtn () {
      if (this.name !== '' && this.password !== '' && this.repassword !== '' && !this.passwordValue && this.email !== '') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // ...accountMapActions(['fetchUserInfo', 'signUp', 'checkId']),
    ...accountMapActions(['signUp', 'checkId']),
    submit () {
      this.idChecker()
      if (!this.isChk) {
        alert ('아이디 중복확인 필요')
        return ''
      } else {
        if (this.isPass) {
          alert('중복된 아이디 사용 불가')
          return ''
        }
      }
      if (this.submitBtn) {
        let userInfo = {
          name: this.name,
          loginPw: this.password,
          loginId: this.email
        }
        this.signUp({
          params: userInfo
        }).then((response) => {
          if (response.data > 0) {
            this.$router.push({name: 'AdminJoinFinish'})
          } else {
            alert('가입 실패')
          }
        })
      } else {
        this.errMsg = true
        this.checkValue()
        alert('필수항목을 모두 입력해주세요')
      }
    },
    idChecker () {
      if (this.email !== '') {
        this.checkId({
          loginId: this.email
        }).then((response) => {
          this.isPass = response.data
        })
      }
      return this.isPass
    },
    checkValue () {
      if ((this.errMsg || this.repassword !== '') && this.password !== this.repassword) {
        this.passwordValue = true
      } else {
        this.passwordValue = false
      }
    },
    clear () {
      this.name = ''
      this.password = ''
      this.repassword = ''
      if (this.getToken === null) this.email = ''
    }
  },
  mounted () {
    if (this.getToken !== null) {
      // this.fetchUserInfo({
      //   config: this.getToken
      // }).then((response) => {
      //   this.name = response.data.name
      //   this.email = response.data.email
      // }).catch((err) => {
      //   throw Error(err)
      // })
    }
  }
}
</script>

import { createNamespacedHelpers } from 'vuex'
import mutations from './mutations'
import actions from './actions'

const { mapGetters: accountMapGetters, mapActions: accountMapActions } = createNamespacedHelpers('account')

export { accountMapGetters, accountMapActions }

export default {
  namespaced: true,

  state: {
    token: null,
    loginId: '',
    isLogin: false
  },

  getters: {
    getLoginId: state => {
      state.loginId = localStorage.getItem('survey-login-id') ? localStorage.getItem('survey-login-id') : sessionStorage.getItem('survey-login-id')
      return state.loginId
    },
    getToken: state => {
      state.token = localStorage.getItem('main-token-survey') ? localStorage.getItem('main-token-survey') : sessionStorage.getItem('main-token-survey')
      return state.token
    },
    getLogin: state => {
      state.isLogin = (localStorage.getItem('main-token-survey') ? localStorage.getItem('main-token-survey') : sessionStorage.getItem('main-token-survey')) != null
      return state.isLogin
    }
  },

  mutations,

  actions

}

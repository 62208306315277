<template>
  <v-container v-if="isReady" class="layout-container">
    <v-layout class="m-y-16 p-y-16" wrap>
      <v-flex v-if="getSurveyAnswerList.length === 0" class="section-element bg-white col-table-cell" xs12 style="box-sizing: border-box;">
        <div class="headline p-y-16 m-y-30 text-xs-center">데이터 없음</div>
      </v-flex>
      <v-flex v-else-if="questions.length > 0" class="section-element col-table-cell" xs12 style="box-sizing: border-box;">
        <div
          v-for="(question, idx) in questions"
          :key="question.questionId"
          :class="{'p-a-16 m-b-30 answer-data': isVisible(question), 'even-row' : idx % 2 === 0, 'odd-row' : idx % 2 === 1 }"
        >
          <v-layout v-if="isVisible(question)" wrap>
            <v-flex xs12 class="main-wrapper text-xs-left p-a-16">
              <div class="headline title-layout" style="padding-top: 0;">{{ question.displayNo }}. </div>
              <div class="flex-flow-wrap">
                <span class="headline" v-html="question.questionTitle"></span>
                <span>
                ( {{ question.questionType === 1 ? '객관식 한 개 선택' : 
                question.questionType === 2 ? '객관식 복수 선택' : 
                question.questionType === 3 ? '주관식 짧은 답변' : 
                question.questionType === 4 || question.questionType > 40 ? '객관식 점수 선택' : 
                question.questionType === 6 ? '드롭다운' : 
                question.questionType === 7 ? '주관식 긴 답변' : '기타'}} )</span>
              </div>
            </v-flex>
            <v-flex v-if="question.questionType !== 3 && question.questionType !== 7" class="m-t-30" xs12>
              <bar-chart
                :labels="labels(question)"
                :datasets="choiceValueDatasets(question)"
                :options="options"
              ></bar-chart>
              <div
                v-for="(ans, index) in answers(question)"
                :key="question.questionId + index"
              >
                <div v-if="ans !== undefined && ans !== ''" class="text-xs-left m-t-30 m-b-10">{{ findChoiceTitle(question, ans) }}</div>
                <data-table
                  v-if="ans !== null || ans !== ''"
                  :showResult="function(){}"
                  :is-respondent="false"
                  :list="ans.textValues.split('§')"
                ></data-table>
              </div>
            </v-flex>
            <v-flex v-else>
              <data-table
                :showResult="function(){}"
                :is-respondent="false"
                :list="textValueDatasets(question, true)"
              ></data-table>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { surveyMapGetters, surveyMapActions } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'
import { axiosConfig } from '../../api/index'
import LineChart from '@/components/admin/LineChart'
import PieChart from '@/components/admin/PieChart'
import BarChart from '@/components/admin/BarChart'
import DataTable from '@/components/admin/DataTable'

export default {
  props: {
    isDropouts: Boolean
  },
  data () {
    return {
      options: {
        legend: {
          display: false
        },
        animation: {
  				duration: 1,
  				onComplete: function () {
  					let chartInstance = this.chart,
  					ctx = chartInstance.ctx;
            ctx.font = '16px serif'
  					ctx.fillStyle = 'black'
  					ctx.textAlign = 'right'
  					ctx.textBaseline = 'top'

  					this.data.datasets.forEach(function (dataset, i) {
  						let meta = chartInstance.controller.getDatasetMeta(i)
              let total = 0
              total = dataset.data.map((e) => { return total += e })
  						meta.data.forEach(function (bar, index) {
  							let data = dataset.data[index]
                if (data === 0) {
                  ctx.fillText('', bar._model.x, bar._model.y )
                } else {
                  data += ' (' +  Math.round((dataset.data[index] / total[meta.data.length - 1]) * 100) + '%)'
                  ctx.fillText(data, bar._model.x + 30, bar._model.y + 15)
                }
  						});
  					});
  				}
  			},
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 0,
              stepSize: 1,
              reverse: false,
              beginAtZero: true
            }
          }]
        }
      },
      isReady: false
    }
  },
  methods: {
    ...surveyMapActions(['fetchSurveyAnswerList', 'fetchSurveyAnswerListForDropsouts']),
    getRandomColor () {
      let letters = '0123456789ABCDEF'.split('');
      let color = '#';
      for (let i = 0; i < 6; i++ ) {
          color += letters[Math.floor(Math.random() * 16)];
      }
      return color + '50';
    },
    isVisible (question) {
      return (_.findIndex(this.getSurveyAnswerList, {'answerIdx': question.answerIdx}) >= 0)
    },
    answers (question) {
      let answer = this.getSurveyAnswerList.filter(el => { return el.answerIdx === question.answerIdx && el.textValues !== undefined && el.textValues !== '' })
      if (answer !== undefined && answer.length > 0) {
        return answer
      } else {
        return null
      }
    },
    findChoiceTitle (question, answer) {
      if (!question.choiceItems) {
        return ''
      } else {
        let choiceArr = question.choiceItems.filter(choice => { return answer.choiceValue === choice.choiceValue })
        return choiceArr === undefined || choiceArr.length === 0 ? '' : choiceArr[0].content
      }
    },
    labels (question) {
      let labelArr = []
      if (question.choiceItems !== undefined && question.choiceItems.length > 0) {
        question.choiceItems.forEach(choice => {
          if(question.questionType === 4 || question.questionType > 40) {
            labelArr.push(choice.content + '점')
          } else {
            let txt = choice.content
            if (txt.length > 15) {
              txt = choice.content.substring(0,12) + '..'
              labelArr.push(txt)
            } else {
              labelArr.push(txt)
            }
          }
        })
      }
      return labelArr
    },
    choiceValueDatasets (question) {
      return [{
        backgroundColor: this.getRandomColor(),
        data: this.dataset(question)
      }]
    },
    textValueDatasets (question) {
      let answer = this.getSurveyAnswerList.filter(el => { return el.answerIdx === question.answerIdx })
      if (answer === undefined || answer.length === 0 || answer[0].textValues === undefined) {
        return null
      } else {
        return answer[0].textValues.split('§')
      }
    },
    dataset (question) {
      if (question.choiceItems !== undefined && question.choiceItems.length > 0) {
        let answer = this.getSurveyAnswerList.filter(el => { return el.answerIdx === question.answerIdx })
        let choiceMap = question.choiceItems.map(el => 0)
        answer.forEach(el => {
          choiceMap[el.choiceValue] = el.cntChoiceValue === undefined ? choiceMap[el.choiceValue] : el.cntChoiceValue
        })
        if (answer !== undefined) {
          return choiceMap.map(el => (el === null || el === undefined ) ? 0 : el )
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyAnswerList', 'getQuestionList']),
    ...accountMapGetters(['getToken']),
    questions () {
      return this.getQuestionList.filter(q => {
        return q.questionType !== 5 && q.questionType !== 8 && q.questionType !== 9
      })
    },
    list () {
      return this.getSurveyAnswerList
    }
  },
  mounted () {
    if (this.isDropouts) {
      this.fetchSurveyAnswerListForDropsouts({
        surveyId: this.$route.params.surveyId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.isReady = true
      })
    } else {
      this.fetchSurveyAnswerList({
        surveyId: this.$route.params.surveyId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.isReady = true
      })
    }
  },
  components: {
    'line-chart': LineChart,
    'pie-chart': PieChart,
    'bar-chart': BarChart,
    'data-table': DataTable
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
  .layout-container {
    padding: 0;
    .m-y-16.p-y-16 {
      &:first-child{
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
  .answer-data {
    padding: 24px;
    background-color: #fff;
  }
</style>

<template>
  <v-btn
    :disabled="selectIdList.length === 0 || btnDisabled"
    class="m-x-0 d-inline-block m-y-0" flat
    style="box-sizing: border-box;"
    @click="surveyDelete()"
    color="error"
  >선택 삭제</v-btn>
</template>

<script>
import { axiosConfig } from '../../api/index'
import { surveyMapActions, surveyMapGetters } from '@/store/modules/survey/survey'
import { accountMapGetters } from '@/store/modules/account/account'

export default {
  props: {
    survey: Object,
    selectIdList: Array,
    surveySelect: Function,
    isSurveys: Boolean,
    btnDisabled: Boolean
  },
  computed: {
    ...surveyMapGetters(['getSurveyList', 'getQuestionList', 'getSectionList']),
    ...accountMapGetters(['getToken'])
  },
  methods: {
    ...surveyMapActions(['fetchSurveyList', 'deleteSurvey']),
    surveyDelete () {
      let isTrue
      if (this.selectIdList.length === 1) {
        isTrue = confirm(this.getSurveyList.filter(survey => { return survey.surveyId === this.selectIdList[0] })[0].contents + ' 설문을 삭제하면 설문지 내의 모든 페이지와 질문, 선택지가 삭제되고 복구가 불가능합니다.\n정말 삭제하시겠습니까?')
      } else {
        isTrue = confirm(this.getSurveyList.filter(survey => { return survey.surveyId === this.selectIdList[0] })[0].contents + ' 설문을 포함한 ' + this.selectIdList.length + '개의 설문을 삭제하면 각 설문지 내의 모든 페이지와 질문, 선택지가 삭제되고 복구가 불가능합니다.\n정말 삭제하시겠습니까?')
      }
      if (isTrue) {
        let count = 0
        for (const selectId of this.selectIdList) {
          this.deleteSurvey({
            surveyId: selectId,
            config: axiosConfig.setHeader(this.getToken)
          }).then((response) => {
            count ++
            this.setSurvey()
            if (count === this.selectIdList.length) {
              alert('삭제완료')
              if (this.isSurveys) {
              } else {
                window.location.reload()
              }
              return ''
            }
          }).catch((err) => { throw Error(err) })
        }
      }
    },
    setSurvey () {
      if (this.isSurveys) {
        this.fetchSurveyList({
          config: axiosConfig.setHeader(this.getToken)
        })
      }
    }
  }
}
</script>


import _ from 'lodash'

export default {
  setSurveyList (state, payload) {
    state.surveys = _.cloneDeep(payload)
  },

  setSectionList (state, payload) {
    state.sections = _.cloneDeep(payload)
  },
  setLinkQuestionList (state, payload) {
    state.linkQuestionList = _.cloneDeep(payload)
  },

  setOriginSectionList (state, payload) {
    state.originSectionList = _.cloneDeep(payload)
  },

  setQuestionList (state, payload) {
    state.questionList = _.cloneDeep(payload)
  },

  setOriginQuestionList (state, payload) {
    state.originQuestionList = _.cloneDeep(payload)
  },

  setAnswerList (state, payload) {
    state.answerList = _.cloneDeep(payload)
  },

  setAnswer (state, payload) {
    state.answer = _.cloneDeep(payload)
  },

  setRespondentSurveyAnswerList (state, payload) {
    state.respondentSurveyAnswerList = _.cloneDeep(payload)
  },

  setRespondentSurveyAnswers (state, payload) {
    state.respondentSurveyAnswers = _.cloneDeep(payload)
  },

  setSurveyAnswerList (state, payload) {
    state.surveyAnswerList = _.cloneDeep(payload)
  }

}

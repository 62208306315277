<template>
  <v-layout class="edit-survey-layout">
    <v-card class="survey-content">
      <v-card-title primary-title>
        <h3 class="grey--text">설문지 편집하기</h3>
      </v-card-title>
      <v-card-text>
        <v-flex class="p-y-16" text-xs-left>
          <v-layout class="m-x-0" row>
            <v-flex xs12>
                <v-card-title primary-title>
                  <v-text-field
                    :class="{null: (selectSurvey.contents === '')}"
                    style="padding-top:0;"
                    :rules="titleRules"
                    v-model.trim="selectSurvey.contents"
                  ></v-text-field>
                  <v-flex class="p-x-10" xs12 text-xs-left>
                    <v-checkbox class="m-t-0" v-model="selectSurvey.isUse" label="설문지 공개"></v-checkbox>
                    <v-text-field
                      :class="{null: (selectSurvey.maxResponseCnt === '')}"
                      style="padding-top:0;"
                      v-model.trim="selectSurvey.maxResponseCnt"
                      label="답변자별 최대 답변횟수"
                    ></v-text-field>
                  </v-flex>
                </v-card-title>
              </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <delete-component
              :survey="selectSurvey"
              :select-id="selectSurvey.surveyId"
              :is-surveys="false"
            ></delete-component>
            <v-btn
              :class="{'blue lighten-4': true }"
              @click="editSurvey"
            >편집</v-btn>
          </v-card-actions>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import { surveyMapActions, surveyMapGetters } from '@/store/modules/survey/survey'
import DeleteComponent from '@/components/common/DeleteComponent.vue'

export default {
  name: 'EditSurvey',
  props: {
    selectSurvey: Object
  },
  data () {
    return {
      titleRules: [ v => v.trim().length > 0 || '설문제목은 빈 값일 수 없습니다' ]
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyList', 'getQuestionList', 'getSectionList'])
  },
  methods: {
    ...surveyMapActions(['updateSurvey']),
    editSurvey () {
      // 이 컴포넌트는 사용하지 않아서 start, end date 아직 안 넣은 상태. 사용하게 되면 넣을 예정.
      this.updateSurvey({
        params: {lsid: this.selectSurvey.surveyId, contents: this.selectSurvey.contents, isUse: this.selectSurvey.isUse, maxResponseCnt: this.selectSurvey.maxResponseCnt, isEditable: this.selectSurvey.isEditable}
      }).then(() => {
        alert('수정 완료')
      }).catch((err) => { throw Error(err) })
    }
  },
  components: {
    'delete-component': DeleteComponent
  }
}
</script>

<style lang="scss" scope>
.edit-survey-layout {
  position: fixed;
  width: 100%;
  max-width: 530px;
  height: auto;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  *{
    box-sizing: border-box;
  }
  &.visible {
    display: block;
    .survey-content {
      position: absolute;
      width: 500px; height: 470px;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 2;
    }
  }
}
</style>


<template>
  <v-layout v-if="question.sectionId === currentSection" class="flex-flow-wrap p-y-16" wrap>
    <i v-if="isEditable" class="fas fa-arrows-alt question-handle move-icon"></i>
    <v-icon
      dark right
      @click="questionDelMethod(question, questionIdx)"
      class="del-btn"
      color="red accent-1"
      v-if="isEditable"
    > remove_circle
    </v-icon>
    <v-btn v-if="isEditable" @click="questionCopy(question, question.questionType)" flat class="copy-btn primary lighten-3">
      <i class="fas fa-copy"></i>
    </v-btn>
    <v-flex
      v-if="(question.questionType === 8 &&  question.questionTitle !== undefined) || ((survey.isAutoSeq || question.displayNo === '') && (question.questionTitle === undefined || question.questionTitle === '')) || (question.questionType === 5 || question.questionType === 8 || question.questionType === 9)"
      class="bg-color"
      :class="{'p-y-16': question.questionType === 8}"
      @click="questionClick(question.questionType, questionIdx)"
    >
      <answer-line
        v-if="question.questionType === 8 && question.questionTitle !== undefined && question.questionTitle !== ''"
        :question="question"
      >
      </answer-line>
      <v-alert
        text-xs-left v-else-if="!survey.isAutoSeq && question.displayNo === '' && (question.questionTitle === undefined || question.questionTitle === '')"
        :value="true"
        color="warning"
        icon="priority_high"
        outline
      >
        내용을 입력하여 주십시오
      </v-alert>
      <div class="p-y-16 ql-editor" v-else-if="question.questionType === 5 || question.questionType === 8 || question.questionType === 9" v-html="question.questionTitle"></div>
    </v-flex>
    <v-flex
      @click="questionClick(question.questionType, questionIdx)"
      class="main-wrapper bg-color ql-editor"
      v-if="question.questionType !== 5 && question.questionType !== 8 && question.questionType !== 9 && ((survey.isAutoSeq || question.displayNo !== '') && (question.questionTitle !== undefined || question.questionTitle !== ''))"
    >
      <v-flex
        v-if="(survey.isAutoSeq || question.displayNo !== '') && (question.questionTitle !== undefined || question.questionTitle !== '')"
        class="title title-layout font-weight-bold p-y-16" xs12 text-xs-left
      >
        <span :class="{notNull: question.isRequired}" class="link-btn-wrapper">
          {{question.displayNo}}
          <v-btn v-if="this.linkQuestionObj.isVisible" @click.stop="dialog = true" class="d-inline-block link-btn" fab small flat><i class="fas fa-link"></i></v-btn>
          </span>
        <v-dialog
          v-if="this.linkQuestionObj.isVisible"
          v-model="dialog"
          width="500"
        >
          <v-card class="p-a-16 link-popup">
            해당 질문은 &nbsp;
            '<div v-html="this.linkQuestionObj.displayNo"></div>) &nbsp;
            <div class="m-b-0" v-html="linkQuestionObj.questionTitle"></div>' &nbsp;
            질문의 하위 질문입니다.
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex class="flex-flow-wrap p-t-16" text-xs-left>
        <div style="height: auto;" xs11 class="ql-editor d-block" v-html="question.questionTitle"></div>
        <answer-radio v-if="question.choiceItems !== undefined && question.questionType === 1"
          :question="question"
          :question-idx="questionIdx"
        ></answer-radio>
        <answer-grade v-if="question.choiceItems !== undefined && question.questionType === 41"
          :question="question"
          :current-answer="currentAnswer"
          :is-editable="isEditable"
        ></answer-grade>
        <answer-star v-if="question.choiceItems !== undefined && question.questionType === 42"
          :question="question"
          :current-answer="currentAnswer"
          :is-editable="isEditable"
        ></answer-star>
        <answer-heart v-if="question.choiceItems !== undefined && question.questionType === 43"
          :question="question"
          :current-answer="currentAnswer"
          :is-editable="isEditable"
        ></answer-heart>
        <answer-thumb v-if="question.choiceItems !== undefined && question.questionType === 4"
          :question="question"
          :current-answer="currentAnswer"
          :is-editable="isEditable"
        ></answer-thumb>
        <answer-drop-down class="d-block" v-if="question.choiceItems !== undefined && question.questionType === 6"
          :question="question"
          :current-answer="currentAnswer"
        ></answer-drop-down>
        <v-layout class="flex-flow-wrap d-block" v-if="question.choiceItems !== undefined && question.questionType === 2">
          <answer-checkbox
            :question="question"
            :question-idx="questionIdx"
            :is-editable="isEditable"
          ></answer-checkbox>
          <answer-text
            :question="question"
            :insert-txt="insertTxt"
            :sub-answer="subAnswer"
          ></answer-text>
        </v-layout>
        <v-layout class="d-block input-flex" v-else-if="(survey.isAutoSeq || question.displayNo !== '') && question.questionTitle !== undefined && question.questionTitle !== ''">
          <answer-textarea
            v-if="question.questionType === 7 "
            :question="question"
            :insert-txt="insertTxt"
          ></answer-textarea>
          <answer-text
            v-else-if="question.questionType === 3"
            :question="question"
            :insert-txt="insertTxt"
          ></answer-text>
          <AnswerPhoneText
            v-else-if="question.questionType === 31"
            :question="question"
            :insert-txt="insertTxt"
          ></AnswerPhoneText>
        </v-layout>
      </v-flex>
    </v-flex>
  </v-layout>
</template>
<script>
import AnswerRadio from '@/components/common/AnswerRadio.vue'
import AnswerThumb from '@/components/common/AnswerThumb.vue'
import AnswerGrade from '@/components/common/AnswerGrade.vue'
import AnswerStar from '@/components/common/AnswerStar.vue'
import AnswerHeart from '@/components/common/AnswerHeart.vue'
import AnswerCheckbox from '@/components/common/AnswerCheckbox.vue'
import AnswerText from '@/components/common/AnswerText.vue'
import AnswerPhoneText from '@/components/common/AnswerPhoneText.vue'
import AnswerTextarea from '@/components/common/AnswerTextarea.vue'
import AnswerDropDown from '@/components/common/AnswerDropDown.vue'
import AnswerLine from '@/components/common/AnswerLine.vue'
import { surveyMapGetters } from '@/store/modules/survey/survey'
import _ from 'lodash'

export default {
  name: 'QuestionCmp',
  props: {
    question: Object,
    questionIdx: Number,
    section: Object,
    questionClick: Function,
    questionDelMethod: Function,
    questionCopy: Function,
    currentSection: Number,
    isEditable: Boolean,
    questions: Array,
    survey: Object
  },
  data: function () {
    return {
      editorOption: {
        modules: {
          toolbar: []
        }
      },
      currentAnswer: null,
      subAnswer: {},
      dialog: false
    }
  },
  methods: {
    insertTxt (question) {
      question.subAnswer = this.subAnswer
    },
    initComp () {
      if (this.question.choiceItems && this.question.choiceItems.length > 0) {
        let isHasSubQuestion = this.question.choiceItems.filter(el => el.hasSubQuestion).length > 0
        if (isHasSubQuestion) {
          if (!this.question.subAnswer) {
            this.question.subAnswer = {}
          } else {
            this.subAnswer = this.question.subAnswer
          }
        }
      }
    }
  },
  // watch: {
  //   originQuestionList () {
  //     this.linkModal()
  //   }
  // },
  mounted () {
    let vm = this
    vm.$nextTick(function () {
      this.initComp()
    })
  },
  computed: {
    ...surveyMapGetters(['getLinkQuestionList', 'getQuestionList']),
    linkQuestionObj () {
      let myLinkQuestion = this.getLinkQuestionList.filter(el => el.questionId === this.question.questionId)
      if (myLinkQuestion.length > 0) {
        const myParentQuestions = this.getQuestionList.filter(el => {
          return el.choiceItems && _.flattenDeep(el.choiceItems.map(el => el.linkedQ)).includes(this.question.questionId)
        })
        return {
          isVisible: true,
          displayNo: _.toString(myParentQuestions.map(el => el.displayNo)),
          questionTitle: _.toString(myParentQuestions.map(el => el.questionTitle))
        }
      } else {
        return {
          isVisible: false
        }
      }
    },
    displayIdx () {
      return _.findIndex(this.questions.filter(el => el.sectionId === this.currentSection && el.questionType !== 5 && el.questionType !== 8 && el.questionType !== 9), {questionId: this.question.questionId})
    },
    total () {
      return this.questions.filter((q, idx) => { return (q.questionType === 5 || q.questionType === 8 || q.questionType === 9) && (idx < this.questionIdx) }).length
    }
  },
  components: {
    AnswerPhoneText,
    'answer-radio': AnswerRadio,
    'answer-thumb': AnswerThumb,
    'answer-grade': AnswerGrade,
    'answer-star': AnswerStar,
    'answer-heart': AnswerHeart,
    'answer-checkbox': AnswerCheckbox,
    'answer-text': AnswerText,
    'answer-textarea': AnswerTextarea,
    'answer-drop-down': AnswerDropDown,
    'answer-line': AnswerLine
  }
}
</script>

<style lang="scss">
  .link-popup {
    * {
      display: inline-block !important;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
</style>
<style lang="scss" scoped>
@import '../../assets/style/variable.scss';
  .flex-flow-wrap{
    position: relative;
    flex-flow: wrap;
    .move-icon,
    .copy-btn,
    .del-btn{
      position: absolute;
      margin: 0; top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      transition: ease-in-out 0.15s;
    }
    .copy-btn{
      right: 50px;
      color: #5AA3DD;
      min-width: auto;
      width: 24px; height: 24px;
      padding: 0;
      border-radius: 50%;
      i {
        color: #fff;
        font-size: 12px;
      }
    }
    .move-icon{
      display: none;
      padding: 5px;
      border-radius: 50%;
      right: 85px;
      background-color: #ddd;
    }
    .del-btn{
      right: 20px;
    }
    .text-field{
      width: 100vw;
    }
  }
  .link-btn-wrapper {
    position: relative;
    .link-btn{
      position: absolute;
      left: 0; bottom: -50px;
      z-index: 99;
    }
  }
  @media (min-width: $tablet) {
    .flex-flow-wrap{
      .move-icon,
      .copy-btn,
      .del-btn{
        opacity: 0;
      }
      &:hover .move-icon,
      &:hover .copy-btn,
      &:hover .del-btn{
        opacity: 1;
      }
    }
  }
  .title * {
    margin: 0;
  }
  @media (max-width: $mobile) {
     .flex-flow-wrap{
        .move-icon{
          display: block !important;
          right: 80px;
        }
        .move-icon,
        .copy-btn{
          width: 22px; height: 22px;
          i {
            font-size: 10px;
          }
        }
      }
    .main-wrapper{
      .title-layout{
        padding-bottom: 0;
      }
      .title-layout {
        .notNull {
          display: inline-block;
          padding-right: 0;
          width: 100%
        }
      }
      .link-btn-wrapper {
        .link-btn{
          left: auto; right: 0; top: -7px;
          z-index: 99;
        }
      }
      .flex-flow-wrap{
        .title{
          padding-left: 10px;
          width: 100%;
        }
      }
    }
  }
</style>

<style lang="scss">
@import '../../assets/style/variable.scss';
  @media (min-width: $tablet) {
    .flex-flow-wrap{
      &:hover *{
        cursor: pointer !important;
      }
      .bg-color{
        transition: ease-in-out 0.3s;
      }
      &:hover .bg-color {
        border-radius: 5px;
        background-color: #eee;
      }
    }
  }
  .ql-editor img {
    max-width: 100%;
  }
</style>

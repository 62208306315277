<template>
<v-container class="header">
  <div class="header-content align-y-center">
    <v-layout class="align-y-center" row>
      <v-flex class="" xs3>
        <img style="max-width: 213px;" src="@/assets/images/logo.png" alt="">
      </v-flex>
      <!-- <v-flex text-xs-left xs3>
        <div
          flat large
          @click="$router.go(-1)"
          text-xs-left
          class="flat-btn cursor-pointer d-inline-block"
        >
          <i class="fas fa-chevron-left" style="font-size: 30px;"></i>
        </div>
      </v-flex> -->
      <v-flex text-xs-right xs9>
        <ul class="nav">
          <li
            v-for="(menu, idx) in items"
            :key="idx"
            @click="movePage(menu.link)"
            class="cursor-pointer"
          >{{ menu.title }}</li>
        </ul>
      </v-flex>
    </v-layout>
  </div>
</v-container>
</template>

<script>
import { accountMapGetters, accountMapActions } from '@/store/modules/account/account'

export default {
  computed: {
    ...accountMapGetters(['getToken', 'getLogin']),
    items () {
      return [
        {
          title: '설문지 리스트',
          link: this.getLogin ? 'MySurveyList' : 'AdminLogin'
        },
        {
          title: this.getLogin ? 'Logout' : 'Login',
          link: this.getLogin ? 'AdminLogout' : 'AdminLogin'
        }
      ]
    }
  },
  methods: {
    ...accountMapActions(['logout']),
    movePage (pageName) {
      if (this.$route.name === pageName) return ''
      if (pageName === 'AdminLogout') {
        this.logout().then(() => {
          this.$router.push({name: 'SurveyMain'})
        }).catch((err) => { throw Error(err) })
      } else {
        this.$router.push({name: pageName})
      }
    }
  },
  mounted () {
    if (!this.getLogin) {
      this.$router.push({name: 'SurveyMain'})
    }
  }
}
</script>

<style lang="scss">
.menu-el{
  translate: ease-in-out 0.2s;
  &:hover {
    background-color: #ddd;
  }
}
.header {
  height: 90px;
  flex: initial;
  &::before{
    display: block; content: ''; clear: both;
    position: fixed;
    width: 100vw; height: 90px;
    left: 0; top: 0;
    z-index: 9990;
    background-color: rgba(0,0,0,0.1);
  }
  &-content {
    position: fixed;
    left: 50%; top: 20px;
    width: 100vw;
    max-width: 1185px;
    transform: translate(-50%, -0%);
    z-index: 9999;
    .flat-btn {
      position: relative;
      top: 50%; right: 0;
      transform: translateY(-50%);
    }
    .nav{
      width: 100%;
      position: relative;
      li {
        display: inline-block;
        margin-left: 20px;
        font-size: 18px;
      }
    }
  }
}
</style>

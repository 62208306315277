<script>
import VueChartJs from 'vue-chartjs'

export default {
  extends: VueChartJs.Line,
  props: {
    labels: Array,
    datasets: Array,
    options: Object
  },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets,
      options: this.options
    }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>

import { createNamespacedHelpers } from 'vuex'
import mutations from './mutations'
import actions from './actions'

const { mapGetters: surveyMapGetters, mapActions: surveyMapActions } = createNamespacedHelpers('survey')

export { surveyMapGetters, surveyMapActions }

export default {
  namespaced: true,

  state: {
    surveys: [],
    sections: [],
    questionList: [],
    answerList: [],
    originSectionList: [],
    originQuestionList: [],
    answer: {},
    respondentSurveyAnswerList: [],
    respondentSurveyAnswers: [],
    surveyAnswerList: [],
    linkQuestionList: []
  },

  getters: {
    getSurveyList: state => {
      return state.surveys
    },
    getLinkQuestionList: state => {
      return state.linkQuestionList
    },
    getSectionList: state => {
      return state.sections
    },
    getQuestionList: state => {
      return state.questionList
    },
    getOriginSectionList: state => {
      return state.originSectionList
    },
    getOriginQuestionList: state => {
      return state.originQuestionList
    },
    getAnswerList: state => {
      return state.answerList
    },
    getAnswerByAnswerId: state => {
      return state.answer
    },
    getRespondentSurveyAnswerList: state => {
      return state.respondentSurveyAnswerList
    },
    getRespondentSurveyAnswers: state => {
      return state.respondentSurveyAnswers
    },
    getSurveyAnswerList: state => {
      return state.surveyAnswerList
    }
  },

  mutations,

  actions

}

import api from './index'

export default {
  selectSurveySubscribeCnt: (params, config) => api.post('/account/subscribe', params, config),
  login: (params) => api.post('/account/login', params).catch(function (error) { if (error.response) { throw error } }),
  signUp: (params) => api.post('/account/sign-up', params),
  checkId: (loginId) => api.get(`/account/check/${loginId}`)
  // selectUserInfo: (config) => api.get(`어쩌고ㅓ...`, config)
}


<template>
 <v-dialog v-model="isOpen" persistent max-width="500" style="z-index: 9999 !important;" content-class="question-type-list-dialog">
    <template v-if="isEditable" v-slot:activator="{ on }">
      <v-list v-if="sideBar" subheader two-line>
        <v-subheader>질문 유형 선택</v-subheader>
        <v-list-tile v-on="on" v-for="(type, index) in typeList" :key="index">
          <v-list-tile-content class="typeList" @click="clickQuestion(type.typeId)">
          <v-list-tile-title>{{type.title}}</v-list-tile-title>
          <v-list-tile-sub-title>{{type.subTitle}}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </template>
    <create-question
      :question-list="questionList"
      :question="tempQuestion"
      :current-question="currentQuestion"
      :origin-question="originQuestion"
      :is-answers="isAnswers"
      :change-question-content="changeQuestionContent"
      :is-open="isOpen"
      :change-open-value="changeOpenValue"
      :section-list="sectionList"
      :current-section="currentSection"
      :set-sequence="setSequence"
      :change-menu-dialog="changeMenuDialog"
      :is-editable="isEditable"
      :sorted-question-list="sortedQuestionList"
      :reset-link="resetLink"
      :survey="survey"
    ></create-question>
  </v-dialog>
</template>

<script>
import CreateQuestion from '@/components/admin/CreateQuestion.vue'
import { surveyMapGetters } from '@/store/modules/survey/survey'
import _ from 'lodash'

const type = [
  {
    title: '글 정보',
    subTitle: '단순 텍스트 제공',
    typeId: 5
  },
  {
    title: '이미지 정보',
    subTitle: '단순 이미지 제공',
    typeId: 9
  },
  {
    title: '객관식 한 개 선택',
    subTitle: '여러 보기 중 한 개의 답변 선택',
    typeId: 1
  },
  {
    title: '객관식 복수 선택',
    subTitle: '여러 보기 중 하나 이상의 답변 선택',
    typeId: 2
  },
  {
    title: '객관식 버튼 선택',
    subTitle: '주어진 질문에 대한 점수를 버튼형으로 선택',
    typeId: 4
  },
  {
    title: '드롭다운',
    subTitle: '여러 보기 중 한 개의 답변을 드롭다운으로 선택',
    typeId: 6
  },
  {
    title: '주관식 긴 답변',
    subTitle: '답변을 긴 문장으로 직접 입력',
    typeId: 7
  },
  {
    title: '주관식 짧은 답변',
    subTitle: '답변을 짧은 문장으로 직접 입력',
    typeId: 3
  },
  {
    title: '구분선',
    subTitle: '선 모양의 장식',
    typeId: 8
  },
  {
    title: '핸드폰 번호',
    subTitle: '핸드폰 번호 양식의 텍스트 필드',
    typeId: 31
  }
]

export default {
  name: 'QuestionTypeList',
  props: {
    changeBoolean: Function,
    sideBar: Boolean,
    sortedQuestionList: Array,
    questionList: Array,
    question: Object,
    currentQuestion: Number,
    isAnswers: Boolean,
    changeQuestionContent: Function,
    isClick: Boolean,
    currentSection: Number,
    sectionList: Array,
    setSequence: Function,
    changeMenuDialog: Function,
    createQuestion: Function,
    isEditable: Boolean,
    resetLink: Function,
    survey: Object
  },
  data () {
    return {
      typeList: type,
      isOpen: false,
      tempQuestion: {}
    }
  },
  methods: {
    clickQuestion (typeId) {
      if (!this.sideBar) {
        this.changeBoolean(false)
      }
      this.tempQuestion = this.createQuestion(typeId)
    },
    changeOpenValue (isBoolean) {
      this.isOpen = isBoolean
    }
  },
  computed: {
    ...surveyMapGetters(['getOriginQuestionList']),
    originQuestion () {
      let q
      if (this.question !== undefined) {
        q = this.getOriginQuestionList.filter(oq => { return oq.questionId === this.question.questionId })
      }
      if (q !== undefined) {
        return _.cloneDeep(q[0])
      } else {
        return null
      }
    }    
  },
  watch: {
    isClick () {
      this.isOpen = true
      if (this.question !== undefined) {
        this.tempQuestion = this.question
      }
    }
  },
  components: {
    'create-question': CreateQuestion
  }
}
</script>

<style>
.typeList{
  cursor: pointer;
}
</style>

<style lang="scss" scope>
  .question-type-list-dialog{
    background: #fff;
    z-index: 9990 !important;
  }
</style>

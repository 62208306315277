<template>
  <v-layout class="layout-container">
    <create-survey
      :question-list.sync="questionList"
      :section-list.sync="sectionList"
      :select-survey-id.sync="selectSurveyId"
      :select-survey.sync="selectSurvey"
      :is-copy-survey="$route.params.isCopy"
      :is-editable.sync="isEditable"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      @setDateRange="setDateRange"
      :input-cnt.sync="inputCnt"
      @setSelectedId="setSelectedId"
      @readyMethod="readyMethod"
      ref="surveyInfo"
    ></create-survey>
    <v-layout v-if="!isReady || isLoading" class="progress-circular" style="position: fixed; width: 100%; height: 100%; left: 0; top: 0; z-index: 999; background-color: rgba(0,0,0,0.5);">
      <div class="text-xs-center" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-layout>
    <v-layout v-if="isReady" class="min-layout-wrap">
      <div v-if="autoSaveBoolean" class="text-xs-center" style="position: fixed; z-index: 98; width: 100vw; height: 100vh; left: 0; top: 0; background-color: rgba(0,0,0,0.5);">
        <v-progress-circular
          style="position: absolute; z-index: 99; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          :size="50"
          color="white"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-flex v-if="selectId" class="flex-side-bar">
        <v-dialog
          v-model="menuDialog"
          hide-overlay
          class="mobile-menu-dialog"
        >
          <template v-slot:activator="{ on }">
            <v-layout class="mobile-only" color="primary" wrap>
              <v-flex xs6>
                <div @click="$router.go(-1)" class="mobile-menu-back-btn cursor-pointer">
                  <i class="fas fa-chevron-left" style="font-size: 30px;"></i>
                </div>
              </v-flex>
              <v-flex xs6 text-xs-right>
                <v-btn
                  class="mobile-menu-dialog-btn"
                  v-on="on"
                >
                  <v-icon>fas fa-bars</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <v-card class="side-bar">
            <v-toolbar color="primary lighten-2" dark>
              <v-icon style="font-size: 20px;">far fa-file-alt</v-icon>
              <v-toolbar-title>설문 입력</v-toolbar-title>
              <v-flex @click="movePageMain" class="text-xs-right" offset-1><v-icon class="cursor-pointer">home</v-icon></v-flex>
            </v-toolbar>
            <v-layout wrap>
              <v-flex xs12>
                <v-expansion-panel v-model="panel" expand>
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <v-flex class="title mb-0 grey--text p-x-10 text-xs-left m-y-8 m-t-16 font-weight-bold" xs12>설문 옵션 관리</v-flex>
                    </template>
                    <v-card>
                      <v-card-title primary-title>
                        <v-text-field
                          :class="{null: (selectSurvey.contents === '')}"
                          style="padding-top:0;"
                          :rules="titleRules"
                          v-model.trim="selectSurvey.contents"
                        ></v-text-field>
                        <v-flex class="p-x-10" xs12 text-xs-left>
                          <v-checkbox class="m-t-0" v-model="selectSurvey.isUse" label="설문지 공개"></v-checkbox>
                          <div class="d-block m-t-0 m-b-10">
                            <v-checkbox hide-details v-model="selectSurvey.isMaxResponseCnt" @change="selectSurvey.maxResponseCnt = maxCount" class="d-inline-block m-t-0 m-r-10" label=" 답변횟수 제한"></v-checkbox>
                            <v-text-field
                              v-if="selectSurvey.isMaxResponseCnt"
                              :class="{null: (selectSurvey.maxResponseCnt === '')}"
                              class="d-inline-block m-t-0 p-t-0"
                              style="max-width: 200px;"
                              v-model.trim.number="inputCnt"
                              type="number"
                              min="0"
                              label="답변자별 최대 답변횟수"
                              hide-details
                            ></v-text-field>
                          </div>
                          <v-checkbox v-model="selectSurvey.isAutoSeq" class="m-t-0" label="질문 번호 자동 생성"></v-checkbox>
                        </v-flex>
                        <survey-date-range
                          :start-date="startDate"
                          :end-date="endDate"
                          @setDateRange="setDateRange"
                        ></survey-date-range>
                      </v-card-title>
                    </v-card>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <v-flex v-if="selectSurvey.isEditable" class="title mb-0 grey--text p-x-10 text-xs-left p-y-8 p-t-16 font-weight-bold" xs12>질문 추가</v-flex>
                    </template>
                    <v-card>
                      <question-type-list
                        :sideBar="true"
                        :question-list="questionList"
                        :question="questionList[currentQuestion]"
                        :sorted-question-list="questionListSorted"
                        :currentQuestion="currentQuestion"
                        :is-answers="isAnswers"
                        :change-question-content="changeQuestionContent"
                        :is-click="isClick"
                        :section-list="sectionList"
                        :current-section="currentSection"
                        :set-sequence="setSequence"
                        :change-menu-dialog="changeMenuDialog"
                        :create-question="createQuestion"
                        :reset-link="resetLink"
                        :is-editable="isEditable"
                        :survey="selectSurvey"
                      >
                      </question-type-list>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex v-if="originSurvey" class="flex-content">
        <v-flex class="question-list-box">
          <v-flex style="color: #999;" class="text-xs-right display-1 p-a-10 m-b-30 font-weight-medium">{{ originSurvey.contents }}</v-flex>
          <v-flex>
            <create-section
              :section-list.sync="sectionList"
              :current-section="currentSection"
              :change-section="changeSection"
              :reset-link="resetLink"
              :is-editable="isEditable"
            ></create-section>
            <steppers
              v-if="sectionList && sectionList.length > 0"
              :questions="questionList"
              :sections="sectionList"
              :current-section.sync="currentSection"
              :current-question="currentQuestion"
              :question-click="questionClick"
              :question-del-method="questionDelMethod"
              :section-del="sectionDel"
              :is-editable="isEditable"
              :question-copy="questionCopy"
              :reset-link="resetLink"
              :survey="selectSurvey"
            ></steppers>
            <v-flex class="m-y-16" text-xs-right>
              <v-btn
                v-if="autoSaveBoolean"
                class="v-btn-opacity-1"
              >저장중</v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="preview"
                    large outline dark
                    color="secondary lighten-4"
                    class="v-btn-opacity-1"
                  >미리보기</v-btn>
                </template>
                <span>미리보기시 자동 임시저장</span>
              </v-tooltip>
              <v-btn
                @click="autoSave(true, false)"
                large outline dark
                color="secondary lighten-4"
                class="v-btn-opacity-1"
              >임시저장</v-btn>
              <v-btn
                @click="hasSubmitDate ? submit() : isOpenCheckPassowrdDialog = true"
                large outline dark
                class="m-x-0 v-btn-opacity-1 sub-key-color"
              >제출하기</v-btn>
            </v-flex>
          </v-flex>
          <null-check-dialog
            v-if="isNull"
            :is-null="isNull"
            :sections="sectionList"
            :null-question="nullQuestion"
            :change-is-null="changeIsNull"
          ></null-check-dialog>
        </v-flex>
      </v-flex>
    </v-layout>
    <CheckPassword
      :isOpenCheckPassowrdDialog="isOpenCheckPassowrdDialog"
      @close="isOpenCheckPassowrdDialog = false"
      @submit="submit"
      @checkSubscribeCnt="checkSubscribeCnt"
    ></CheckPassword>
    <CheckCount
      :isOpenCheckCountDialog="isOpenCheckCountDialog"
      @close="isOpenCheckCountDialog = false"
      @checkSubscribeCnt="checkSubscribeCnt"
      :result="submitResult"
    ></CheckCount>
  </v-layout>
</template>

<script>
import { axiosConfig } from '../api/index'
import CreateQuestion from '@/components/admin/CreateQuestion.vue'
import CreateSection from '@/components/admin/CreateSection.vue'
import CreateSurvey from '@/components/admin/CreateSurvey.vue'
import EditSurvey from '@/components/admin/EditSurvey.vue'
import Steppers from '@/components/admin/Steppers.vue'
import QuestionTypeList from '@/components/admin/QuestionTypeList.vue'
import nullCheckDialog from '@/components/admin/nullCheckDialog.vue'
import SurveyDateRange from '@/components/admin/SurveyDateRange'
import { surveyMapActions, surveyMapGetters } from '@/store/modules/survey/survey'
import { accountMapGetters, accountMapActions } from '@/store/modules/account/account'
import _ from 'lodash'
import CheckPassword from '@/components/account/CheckPassword.vue'
import CheckCount from '@/components/account/CheckCount.vue'

export default {
  name: 'SurveyAdmin',
  data () {
    return {
      myAccount: {
        loginId: null,
        loginPw: null
      },
      isFirst: true,
      isOpenCheckCountDialog: false,
      isOpenCheckPassowrdDialog: false,
      titleRules: [ v => v.trim().length > 0 || '설문제목은 빈 값일 수 없습니다' ],
      isEditable: true,
      currentQuestion: 0,
      currentSection: 1,
      questionList: [],
      isAnswers: false,
      isClick: false,
      sectionList: [],
      selectSurveyId: 1,
      selectSurvey: {contents: '설문 선택 중..'},
      inputCnt: 1,
      nullQuestion: [],
      nullSection: [],
      isNull: false,
      menuDialog: false,
      submitResult: {
        isPossible: null,
        totalCnt: null,
        submitCnt: null,
        appRatePlanName: null,
        startDate: null,
        endDate: null
      },
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      panel: [false, true],
      isLoading: false,
      isReady: false,
      isSectionTitleNull: false,
      autoSaveCount: 0,
      endCount: 0,
      autoSaveBoolean: false,
      linkQuestion: [],
      selectId: 0
    }
  },
  computed: {
    ...surveyMapGetters(['getSurveyList', 'getQuestionList', 'getSectionList', 'getOriginQuestionList', 'getOriginSectionList']),
    ...accountMapGetters(['getToken', 'getLoginId']),
    hasSubmitDate () {
      return this.selectSurvey.hasOwnProperty('submitDate') && this.selectSurvey.submitDate
    },
    maxCount () {
      if (this.selectSurvey.isMaxResponseCnt) {
        return parseInt(this.inputCnt)
      } else {
        return 0
      }
    },
    originSurvey () {
      let origin = _.cloneDeep(this.getSurveyList.filter(survey => { return survey.surveyId === this.selectSurveyId })[0])
      if (origin) {
        if (origin.maxResponseCnt > 0) {
          this.selectSurvey.isMaxResponseCnt = true
          origin.isMaxResponseCnt = true
        } else {
          this.selectSurvey.isMaxResponseCnt = false
          origin.isMaxResponseCnt = false
        }
      }
      return origin
    },
    isAutoSeq () {
      return this.selectSurvey.isAutoSeq
    },
    questionListSorted () {
      return _.cloneDeep(this.questionList).sort((prev, next) => {
        if (this.sectionList[_.findIndex(this.sectionList, {sectionId: prev.sectionId})].sectionSeq >= this.sectionList[_.findIndex(this.sectionList, {sectionId: next.sectionId})].sectionSeq) return 1
        if (this.sectionList[_.findIndex(this.sectionList, {sectionId: prev.sectionId})].sectionSeq < this.sectionList[_.findIndex(this.sectionList, {sectionId: next.sectionId})].sectionSeq) return -1
        if (prev.seqInSection >= next.seqInSection) return 1
        if (prev.seqInSection < next.seqInSection) return -1
      })
    }
  },
  methods: {
    ...surveyMapActions(['insertSection', 'resetQuestionList', 'setOriginSectionListInStore', 'setSectionListInStore', 'setOriginQuestionListForVue', 'setQuestionListInStore', 'setLinkQuestionList', 'fetchSurveyList', 'fetchSectionList', 'fetchQuestionList', 'insertQuestion', 'insertChoices', 'fetchSurveyList', 'updateSurvey', 'updateSection', 'updateQuestion', 'updateChoices', 'deleteSection', 'deleteQuestion', 'deleteChoices']),
    ...accountMapActions(['fetchSurveySubscribeCnt']),
    setSelectedId (id) {
      this.selectId = parseInt(`${id}`)
      if (!this.$route.params.surveyId && this.$route.params.surveyId == 0) {
        this.$router.replace({ name: "SurveyAdmin", params: { surveyId: parseInt(`${id}`) } })
      }
      this.setSurvey(false)
    },
    async getSurveySubscribeCnt (params) {
      return await this.fetchSurveySubscribeCnt({
        params: params,
        config: axiosConfig.setHeader(this.getToken)
      }).catch((err) => {
        alert(err)
      })
    },
    checkSubscribeCnt (params, isNeedSubmit) {
      if (isNeedSubmit && this.myAccount.loginId && this.myAccount.loginPw) {
        this.getSurveySubscribeCnt(this.myAccount).then((response) => {
          if (response.data.isPossible) {
            this.submit()
          } else {
            this.submitResult = {
              isPossible: response.data.isPossible,
              totalCnt: response.data.totalCnt,
              submitCnt: response.data.submitCnt,
              appRatePlanName: response.data.appRatePlanName,
              startDate: response.data.startDate,
              endDate: response.data.endDate
            }
            this.isOpenCheckCountDialog = true
          }
        })
      } else {
        this.myAccount = _.cloneDeep(params)
        this.getSurveySubscribeCnt(params).then((response) => {
          this.submitResult = {
            isPossible: response.data.isPossible,
            totalCnt: response.data.totalCnt,
            submitCnt: response.data.submitCnt,
            appRatePlanName: response.data.appRatePlanName,
            startDate: response.data.startDate,
            endDate: response.data.endDate
          }
          this.isOpenCheckCountDialog = true
        })
      }
    },
    linkModal () {
      this.isLoading = true
      let linkQuestion = []
      let choiceQuestionList = this.questionList.filter(q => q.choiceItems && q.choiceItems.length > 0)
      if (choiceQuestionList && choiceQuestionList.length > 0) {
        let linkedQList = _.flattenDeep(choiceQuestionList.map(el => el.choiceItems)).filter(el => el.linkedQ.length > 0).map(el => el.linkedQ)
        if (linkedQList && linkedQList.length > 0) {
          let linkedQuestionIdLIst = _.flattenDeep(linkedQList)
          for (let i = 0; i < this.questionList.length; i++) {
            const q = this.questionList[i]
            if (linkedQuestionIdLIst.includes(q.questionId)) linkQuestion.push(_.cloneDeep(q))
          }
        }
      }
      this.setLinkQuestionList(linkQuestion).then(() => {
        this.isLoading = false
        this.isReady = true
      })
    },
    setDateRange (param) {
      if (param.start !== null) this.startDate = param.start
      if (param.end !== null) this.endDate = param.end
    },
    movePageMain () {
      let isTrue = confirm('메인 페이지로 이동하시겠습니까?\n변경된 내용은 저장되지 않습니다.')
      if (isTrue) {
        this.$router.push({name: 'MySurveyList'})
      }
    },
    changeMenuDialog (isBoolean) {
      this.menuDialog = isBoolean
    },
    createQuestion (typeId) {
      let dn = ''
      if (this.isAutoSeq) {
        dn = this.questionList.filter(q => {
          return q.questionType !== 5 && q.questionType !== 8 && q.questionType !== 9 && q.sectionId === this.currentSection
        }).length
        dn = dn > 0 ? dn.toString() : '1'
      }
      let question = {
        sectionId: this.currentSection,
        displayNo: dn,
        questionType: typeId,
        questionTitle: '',
        isRequired: true,
        isUse: true,
        isLinkedQuestion: false
      }
      this.currentQuestion = -1
      if (typeId === 1 || typeId === 2 || typeId === 4 || typeId === 6 || typeId > 40) {
        this.isAnswers = true
      } else {
        this.isAnswers = false
        if (typeId === 5 || typeId === 8 || typeId === 9) {
          question.isRequired = false
        }
      }
      question.seqInSection = this.questionList.filter(q => { return q.sectionId === this.currentSection }).length
      return question
    },
    readyMethod () {
      this.isReady = true
      this.isLoading = false
    },
    preview () {
      if (this.getOriginQuestionList.length > 0) {
        this.autoSave(true, true)
        let url = 'surveyId/' + this.selectSurveyId
        window.open('https://survey.u2cloud.co.kr/u2s-web/param/' + btoa(url).replaceAll('/', '_') + '/res/admin')
      } else {
        alert('문항이 없으므로 미리보기가 불가합니다')
      }
    },
    setDisplayNo (question) {
      let questionArrInCurrentSections = this.questionListSorted.filter(q => { return q.sectionId === question.sectionId })
      let questionArrInCurrentSectionsForDisplayNo = this.questionListSorted.filter(q => { return q.sectionId === question.sectionId && q.questionType !== 5 && q.questionType !== 8 && q.questionType !== 9})
      for (let i = 0; i < questionArrInCurrentSections.length; i++) {
        let targetIdx = _.findIndex(this.questionList, { questionId: questionArrInCurrentSections[i].questionId })
        let target = _.cloneDeep(this.questionList[targetIdx])
        if (this.selectSurvey.isAutoSeq && target) target.displayNo = (_.findIndex(questionArrInCurrentSectionsForDisplayNo, { questionId: target.questionId }) + 1).toString()
        target.seqInSection = i
        this.questionList.splice(targetIdx, 1, target)
      }
    },
    resetPrevLink (prevQuestionList, questionObj) {
      // 조건 1
        let questionListTmp = _.cloneDeep(this.getQuestionList)
        const targetQuestionIdx_1 = this.getQuestionList.findIndex(el => el.questionId === questionObj.questionId)
        const targetQuestionIdx_2 = this.questionList.findIndex(el => el.questionId === questionObj.questionId)
        const prevQuestionIdList = prevQuestionList.map(el => el.questionId)
        for (let i = 0; i < questionObj.choiceItems.length; i ++) {
          questionObj.choiceItems[i].linkedQ = (questionObj.choiceItems[i] && questionObj.choiceItems[i].linkedQ) ? questionObj.choiceItems[i].linkedQ.filter(l => !prevQuestionIdList.includes(l)) : questionObj.choiceItems[i].linkedQ
        }
        questionListTmp.splice(targetQuestionIdx_1, 1, questionObj)
        this.questionList.splice(targetQuestionIdx_2, 1, questionObj)
        this.setQuestionListInStore(questionListTmp)
    },
    resetNextLink (nextQuestionList, questionObj) {
      // 조건 2
      const needChangeQuestionList = nextQuestionList.filter(el => { return el && el.choiceItems && el.choiceItems.length > 0 && _.flattenDeep(el.choiceItems.map(l => l.linkedQ)).includes(questionObj.questionId) })
      let questionListTmp = _.cloneDeep(this.getQuestionList)
      for (let i = 0; i < needChangeQuestionList.length; i++) {
        const targetQuestionIdx_1 = this.getQuestionList.findIndex(el => el.questionId === needChangeQuestionList[i].questionId)
        const targetQuestionIdx_2 = this.questionList.findIndex(el => el.questionId === needChangeQuestionList[i].questionId)
        let targetQuestion = this.getQuestionList[targetQuestionIdx_1]
        targetQuestion.choiceItems = targetQuestion.choiceItems.map(el => { _.remove(el.linkedQ, function (n) { return n === questionObj.questionId }); return el })
        questionListTmp.splice(targetQuestionIdx_1, 1, targetQuestion)
        this.questionList.splice(targetQuestionIdx_2, 1, targetQuestion)
      }
      this.setQuestionListInStore(questionListTmp)
    },
    resetLink (question, isQuestion) {
      // 질문 혹은 section의 위치 이동, 질문 삭제 등의 변화가 생겼을 때 하위질문을 리셋함
      if (isQuestion) {
        // 조건 1. 현재 Q의 choiceItems의 linkedQ에서는 현재 Q 이전의 questionId가 없어야 함
        // 조건 2. 현재 Q 이후의 question list 들의 choiceItems 의 linkedQ에는 현재 Q의 questionId가 없어야 함
        const currentIdxBySortedList = _.findIndex(this.questionListSorted, {questionId: question.questionId})
        const prevQuestionList = _.cloneDeep(this.questionListSorted).slice(0, currentIdxBySortedList)
        const nextQuestionList = _.cloneDeep(this.questionListSorted).slice(currentIdxBySortedList, this.questionListSorted.length)
        if (question.choiceItems && question.choiceItems.length > 0 && prevQuestionList && prevQuestionList.length > 0) {
          this.resetPrevLink(prevQuestionList, question)
        }
        if (nextQuestionList && nextQuestionList.length > 0) {
          this.resetNextLink(nextQuestionList, question)
        }
        this.linkModal()
        this.setDisplayNo(question)
      } else {
        let section = question
        const currentIdxBySectionList = _.findIndex(this.sectionList, { sectionId: section.sectionId })
        const questionInCurrentSection = _.cloneDeep(this.questionListSorted.filter(el => el.sectionId === section.sectionId))
        const prevSectionList = _.cloneDeep(this.sectionList).slice(0, currentIdxBySectionList + 1)
        const nextSectionList = _.cloneDeep(this.sectionList).slice(currentIdxBySectionList + 1, this.sectionList.length)
        if (questionInCurrentSection && questionInCurrentSection.length > 0) {
          for (let i = 0; i < questionInCurrentSection.length; i++) {
            for (let j = 0; j < prevSectionList.length; j++) {
              const prevQuestionList = _.cloneDeep(this.questionListSorted.filter(el => el.sectionId === prevSectionList[j].sectionId && (el.sectionId === section.sectionId ? (questionInCurrentSection[i].seqInSection <= question.seqInSection) : true) ))
              if (questionInCurrentSection[i].choiceItems && questionInCurrentSection[i].choiceItems.length > 0 && prevQuestionList && prevQuestionList.length > 0) {
                this.resetPrevLink(prevQuestionList, questionInCurrentSection[i])
              }
            }
            for (let j = 0; j < nextSectionList.length; j++) {
              const nextQuestionList = _.cloneDeep(this.questionListSorted.filter(el => el.sectionId === nextSectionList[j].sectionId && (el.sectionId === section.sectionId ? (questionInCurrentSection[i].seqInSection >= question.seqInSection) : true) ))
              if (questionInCurrentSection[i] && nextQuestionList && nextQuestionList.length > 0) {
                this.resetNextLink(nextQuestionList, questionInCurrentSection[i])
              }
            }
          }
          this.linkModal()
          this.setDisplayNo(question)
        }
      }
    },
    changeQuestionContent (questionObj, questionIdx, isPageMove) {
      // 질문이 생성되면 insert api를, 편집되면 update api를 찌름
      if (questionObj.sectionId === undefined) questionObj.sectionId = this.currentSection
      let currentIdx = -1
      if (questionIdx === -1) {
        let endCount = 1
        questionObj.seqInSection = this.questionList.filter(q => { return q.sectionId === this.currentSection }).length
        this.insertQuestion({
          params: questionObj,
          config: axiosConfig.setHeader(this.getToken)
        }).then((response) => {
          questionObj.questionId = response.data
          const originQuestionListTmp = _.cloneDeep(this.getOriginQuestionList)
          currentIdx = _.findIndex(this.questionList, { questionId: questionObj.questionId })
          let originCurrentIdx = _.findIndex(originQuestionListTmp, { questionId: questionObj.questionId })
          if (!questionObj.choiceItems || questionObj.choiceItems.length === 0) {
            if (currentIdx === -1) {
              currentIdx = _.findLastIndex(this.questionList, { sectionId: questionObj.sectionId })
              originCurrentIdx = _.findLastIndex(originQuestionListTmp, { sectionId: questionObj.sectionId })
              this.questionList.splice((currentIdx + 1), 0, questionObj)
              originQuestionListTmp.splice((originCurrentIdx + 1), 0, questionObj)
            } else {
              this.questionList.splice(currentIdx, 1, questionObj)
              originQuestionListTmp.splice(originCurrentIdx, 1, questionObj)
            }
            this.setOriginQuestionListForVue({
              params: originQuestionListTmp
            })
            return ''
          }
          let cloneChoice = _.cloneDeep(questionObj.choiceItems)
          endCount += cloneChoice.length
          for (let i = 0; i < cloneChoice.length; i++) {
            let choice = _.cloneDeep(cloneChoice[i])
            let choiceItem = this.setChoiceItem(questionObj, choice, _.findIndex(questionObj.choiceItems, choice))
            this.submitChoiceMethod(choiceItem).then((res) => {
              choiceItem.lcid = res.data
              questionObj.choiceItems[i] = choiceItem
              endCount -= 1
              if (endCount === 0) {
                if (currentIdx === -1) {
                  currentIdx = _.findLastIndex(this.questionList, { sectionId: questionObj.sectionId })
                  originCurrentIdx = _.findLastIndex(this.questionList, { sectionId: questionObj.sectionId })
                  this.questionList.splice((currentIdx + 1), 0, questionObj)
                  originQuestionListTmp.splice((originCurrentIdx + 1), 0, questionObj)
                } else {
                  this.questionList.splice(currentIdx, 1, questionObj)
                  originQuestionListTmp.splice(originCurrentIdx, 1, questionObj)
                }
                this.setOriginQuestionListForVue({
                  params: originQuestionListTmp
                })
              }
            }).catch((err) => { throw Error(err) })
          }
        }).catch((err) => {
          alert('입력 실패')
          throw Error(err)
        }).finally(() => {
          endCount -= 1
        })
      } else {
        if (questionObj.sectionId && questionObj.sectionId !== this.currentSection) {
          this.setDisplayNo(questionObj)
          let currentQuestions = this.questionList.filter(q => { return q.sectionId === questionObj.sectionId }).sort((prev, next) => { return prev.seqInSection < next.seqInSection })
          let currentQuestionIdx = 0
          if (!currentQuestions || currentQuestions.length === 0) {
            let sectionIdx = _.findIndex(this.sectionList, {  sectionId: questionObj.sectionId })
            if (sectionIdx > 0) {
              currentQuestionIdx = _.findLastIndex(this.questionList, { sectionId: this.sectionList[sectionIdx - 1].sectionId })
            }
          } else {
            currentQuestionIdx = (_.findIndex(this.questionList, { questionId: currentQuestions[currentQuestions.length - 1].questionId }) + 1)
          }
          this.questionList.splice(questionIdx, 1)
          this.questionList.splice(currentQuestionIdx, 0, questionObj)
          this.sectionList.forEach((s, idx) => {
            if (s.sectionId === questionObj.sectionId) this.resetLink(s, false)
          })
        } else {
          this.questionList.splice(questionIdx, 1, questionObj)
        }
        currentIdx = _.findIndex(this.questionList, {questionId: questionObj.questionId})
        questionObj = this.resetPrevLinkForQuestion(questionObj, isPageMove)
        this.questionList.splice(currentIdx, 1, questionObj)
        this.setQuestionListInStore(_.cloneDeep(this.questionList))
        if (currentIdx !== -1 && isPageMove) this.resetLink(this.questionList[currentIdx], true)
        const qIdx = this.getOriginQuestionList.map(el => el.questionId).indexOf(questionObj.questionId)
        if (isPageMove) {
          const originQuestionListTmp = _.cloneDeep(this.getOriginQuestionList)
          originQuestionListTmp.splice(qIdx, 1, questionObj)
          this.updateQuestionForSubPage(questionObj).then(() => {
            this.submitChoiceItems(questionObj, currentIdx, originQuestionListTmp)
          })
        }
        const questionIncurrentSection = this.questionList.filter(el => el.sectionId === this.currentSection && el.questionId !== questionObj.questionId)
        if (questionIncurrentSection && questionIncurrentSection.length > 0) {
          for (let i = 0; i < questionIncurrentSection.length; i++) {
            this.setDisplayNo(questionIncurrentSection[i])
          }
        }
        this.linkModal()
      }
    },
    resetPrevLinkForQuestion (questionObj, isPageMove) {
      const currentIdxBySortedList = _.findIndex(this.questionListSorted, {questionId: questionObj.questionId})
      if (questionObj.choiceItems && questionObj.choiceItems.length > 0) {
        const prevQuestionList = _.cloneDeep(this.questionListSorted).slice(0, currentIdxBySortedList)
        const prevQuestionIdList = prevQuestionList.map(el => el.questionId)
        if (isPageMove) {
          for (let i = 0; i < questionObj.choiceItems.length; i ++) {
            questionObj.choiceItems[i].linkedQ = (questionObj.choiceItems[i] && questionObj.choiceItems[i].linkedQ) ? questionObj.choiceItems[i].linkedQ.filter(l => !prevQuestionIdList.includes(l)) : questionObj.choiceItems[i].linkedQ
          }
        }
      }
      return questionObj
    },
    submitChoiceItems (questionObj, currentIdx, originQuestionListTmp) {
      if (!questionObj.choiceItems || questionObj.choiceItems.length === 0) return ''
      let cloneChoice = _.cloneDeep(questionObj.choiceItems)
      let localEndCount = cloneChoice.length
      for (let i = 0; i < cloneChoice.length; i++) {
        let choice = _.cloneDeep(cloneChoice[i])
        let choiceItem = this.setChoiceItem(questionObj, choice, _.findIndex(questionObj.choiceItems, { lcid: choice.lcid }))
        if (!choice.lcid) {
          this.submitChoiceMethod(choiceItem).then(() => {
            localEndCount -= 1
            if (localEndCount === 0) {
              this.questionList.splice(currentIdx, 1, questionObj)
              this.setOriginQuestionListForVue({
                params: originQuestionListTmp
              })
            }
          }).catch((err) => { throw Error(err) })
        } else {
          this.updateChoiceItemForVue(choiceItem).then(() => {
            localEndCount -= 1
            if (localEndCount === 0) {
              this.questionList.splice(currentIdx, 1, questionObj)
              this.setOriginQuestionListForVue({
                params: originQuestionListTmp
              })
            }
          }).catch((err) => { throw Error(err) })
        }
      }
    },
    questionClick (typeId, idx) {
      // createQuestion dialog 세팅을 위한 메소드
      if (typeId === 1 || typeId === 2 || typeId === 4 || typeId === 6 || typeId > 40) {
        this.isAnswers = true
      } else {
        this.isAnswers = false
      }
      this.changeClickValue()
      this.currentQuestion = idx
    },
    async asyncDelChoice (choice) {
      if (choice.lcid !== undefined) {
        return await this.deleteChoices({
          choiceId: choice.lcid,
          config: axiosConfig.setHeader(this.getToken)
        }).catch((err) => { throw Error(err) })
      } else {
        return true
      }
    },
    async asyncDelQuestion (question, idx) {
      return await this.deleteQuestion({
        questionId: question.questionId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.endCount -= 1
      }).catch((err) => { throw Error(err) })
    },
    questionDelMethod (question, idx) {
      this.isLoading = true
      this.linkQuestionDel(question)
      if (!question.questionId) {
        let idx = _.findIndex(this.questionList, { questionId: question.questionId })
        this.questionList.splice(idx, 1)
        this.isLoading = false
      } else {
        this.endCount = 1
        this.asyncDelQuestion(question, idx)
      }
    },
    linkQuestionDel (question) {
      let delQuestionList = []
      for (const q of this.questionList) {
        if (q.choiceItems !== undefined && q.choiceItems.length > 0) {
          q.choiceItems.forEach((choice) => {
            if (choice.linkedQ !== undefined && choice.linkedQ.length > 0) {
              choice.linkedQ.forEach(link => {
                if (link === question.questionId && !delQuestionList.includes(q.questionId)) {
                  delQuestionList.push(q.questionId)
                }
              })
            }
          })
        }
      }
      if (delQuestionList.length > 0) {
        let targetQuestion = this.questionList.filter(q => { return delQuestionList.includes(q.questionId) })
        if (targetQuestion.length > 0) {
          for (const target of targetQuestion) {
            if (target.choiceItems !== undefined && target.choiceItems.length > 0) {
              target.choiceItems.forEach(choice => {
                if (choice.linkedQ !== undefined && choice.linkedQ.length > 0) {
                  choice.linkedQ.forEach((link, index) => {
                    if (link === question.questionId) {
                      choice.linkedQ.splice(index, 1)
                    }
                  })
                }
              })
            }
          }
          this.autoSave(null, true)
        }
      }
    },
    questionCopy (question, typeId) {
      this.isLoading = true
      let copyQuestion = _.cloneDeep(question)
      if (copyQuestion.choiceItems !== undefined && copyQuestion.choiceItems.length > 0) {
        for (const choice of copyQuestion.choiceItems) {
          if (choice.linkedQ !== undefined && choice.linkedQ.length > 0) choice.linkedQ = []
        }
      }
      let currentQuestionArr = this.questionList.filter(q => { return q.sectionId === question.sectionId })
      copyQuestion.questionId = -1
      if (typeId === 1 || typeId === 2 || typeId === 4 || typeId === 6 || typeId > 40) {
        this.isAnswers = true
      } else {
        this.isAnswers = false
        if (typeId === 5 || typeId === 8 || typeId === 9) {
          copyQuestion.isRequired = false
        }
      }
      copyQuestion.seqInSection = currentQuestionArr.length
      this.insertQuestion({
        params: copyQuestion,
        config: axiosConfig.setHeader(this.getToken)
      }).then((response) => {
        let questionArr = this.questionList.filter(q => { return q.sectionId === this.currentSection })
        if (questionArr.length === 0) {
          this.currentQuestion = this.questionList.length
        } else {
          this.currentQuestion = _.findIndex(this.questionList, { questionId: questionArr[questionArr.length - 1].questionId }) + 1
        }
        this.questionList.splice(this.currentQuestion, 0, copyQuestion)
        this.questionList[this.currentQuestion].lqid = response.data
        this.questionList[this.currentQuestion].questionId = response.data
        if (this.questionList[this.currentQuestion].choiceItems !== undefined && this.questionList[this.currentQuestion].choiceItems.length > 0) {
          this.endCount += this.questionList[this.currentQuestion].choiceItems.length
          for (let i = 0; i < this.questionList[this.currentQuestion].choiceItems.length; i++) {
            let choice = _.cloneDeep(this.questionList[this.currentQuestion].choiceItems[i])
            delete choice.lcid
            choice.choiceItemSeq = i
            choice.choiceValue = i
            if (this.questionList[this.currentQuestion].questionType === 4 || this.questionList[this.currentQuestion].questionType > 40) choice.content = i + 1
            choice.questionId = copyQuestion.questionId
            choice.linkedQ = ''
            this.submitChoiceMethod(choice, this.currentQuestion, i).then((response) => {
              this.endCount -= 1
            }).catch((err) => { throw Error(err) })
          }
        } else {
          this.isLoading = false
        }
      }).catch((err) => { throw Error(err) })
    },
    async submitChoiceMethod (choice) {
      return await this.insertChoices({
        params: choice,
        config: axiosConfig.setHeader(this.getToken)
      })
    },
    questionSort () {
      if (!this.isAutoSeq) return ''
      for (const s of this.sectionList) {
        let myQuestionList = this.questionListSorted.filter(el => el.sectionId === s.sectionId)
        let cnt = 1
        for (const q of myQuestionList) {
          if (q.questionType === 5 || q.questionType === 8 || q.questionType === 9) continue
          q.displayNo = cnt.toString()
          cnt += 1
        }
      }
    },
    changeClickValue () {
      this.isClick = !this.isClick
    },
    changeSection (isAdd, idx) {
      if (isAdd === null) {
        this.currentSection = idx
      } else if (isAdd) {
        let seq = _.findIndex(this.sectionList, {sectionId: this.currentSection}) + 1
        this.insertSection({
          params: {
            surveyId: this.selectSurveyId,
            sectionSeq: seq,
            sectionTitle: '',
            isUse: true
          },
          config: axiosConfig.setHeader(this.getToken)
        }).then((response) => {
          const mySectionIdx = _.findIndex(this.sectionList, { sectionId: this.currentSection })
          this.currentSection = response.data
          const originSectionListTmp = _.cloneDeep(this.getOriginSectionList)
          this.sectionList.splice((mySectionIdx + 1), 0, { sectionId: response.data, isUse: true, sectionSeq: mySectionIdx + 1, sectionTitle: '', surveyId: this.selectId })
          originSectionListTmp.splice((mySectionIdx + 1), 0, { sectionId: response.data, isUse: true, sectionSeq: mySectionIdx + 1, sectionTitle: '', surveyId: this.selectId })
          this.setOriginSectionListInStore(originSectionListTmp)
          this.setSectionListInStore(this.sectionList)
        })
      }
    },
    async sectionDel (idx) {
      let sId = this.sectionList[idx].sectionId
      let delTargetQuestions = this.questionList.filter(q => { return q.sectionId === sId })
      this.autoSaveCount += delTargetQuestions.length
      return await this.deleteSection({
        sectionId: sId,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        for (const q of delTargetQuestions) {
          let hasSubQuestionIdx = _.findIndex(this.questionList, {choiceItems: [{ linkedQ: [q.questionId] }]})
          let findQ = _.findIndex(this.questionList, {questionId: q.questionId})
          if (findQ !== -1) this.questionList.splice(findQ, 1)
          if (hasSubQuestionIdx !== -1) this.questionList[hasSubQuestionIdx].choiceItems.forEach(c => { c.linkedQ = _.without(c.linkedQ, q.questionId) })
        }
        this.sectionList.splice(idx, 1)
        const originSectionListTmp = _.cloneDeep(this.getOriginSectionList)
        let originCurrentIdx = _.findIndex(originSectionListTmp, { sectionId: sId })
        originSectionListTmp.splice(originCurrentIdx, 1)
        this.setOriginSectionListInStore(originSectionListTmp)
        this.setSectionListInStore(this.sectionList)
        this.questionSort()
        this.autoSave(null, true)
      }).catch((err) => { throw Error(err) })
    },
    setChoiceItems (question, originQuestion) {
      let choiceStr, originChoiceStr, choice
      let index = 0
      this.autoSaveCount = question.choiceItems.length
      for (let i = 0; i < question.choiceItems.length; i++) {
        choice = question.choiceItems[i]
        index = i
        choiceStr = _.cloneDeep(choice)
        if (choiceStr.lcid === undefined) {
          choiceStr.choiceValue = index
          choiceStr.questionId = question.questionId
          if (question.questionType === 4 || question.questionType > 40) choiceStr.content = i + 1
          if (choiceStr.linkedQ !== undefined) {
            choiceStr.linkedQ = choiceStr.linkedQ.toString()
          } else {
            choiceStr.linkedQ = ''
          }
          let myIdx = _.findIndex(this.questionList, {questionId: question.questionId})
          this.submitChoiceMethod(choiceStr, myIdx, i).then((response) => {
            this.autoSaveCount -= 1
          }).catch((err) => { throw Error(err) })
        } else {
          originQuestion.choiceItems.filter((choiceItem) => {
            originChoiceStr = _.cloneDeep(choiceItem)
            if (_.find(question.choiceItems, {lcid: originChoiceStr.lcid}) === undefined) {
              this.autoSaveCount += 1
              if (originChoiceStr.lcid !== undefined) {
                this.deleteChoices({
                  choiceId: originChoiceStr.lcid,
                  config: axiosConfig.setHeader(this.getToken)
                }).then(() => {
                  this.autoSaveCount -= 1
                }).catch((err) => { throw Error(err) })
              }
            } else if (originChoiceStr.lcid === choiceStr.lcid) {
              if (_.find([choiceStr], originChoiceStr) === undefined || !_.isEqual(choiceStr.linkedQ, originChoiceStr.linkedQ)) {
                choiceStr.choiceItemSeq = index
                choiceStr.choiceValue = index
                if (question.questionType === 4 || question.questionType > 40) choiceStr.content = i + 1
                choiceStr.questionId = question.questionId
                if (choiceStr.linkedQ !== undefined) {
                  choiceStr.linkedQ = choiceStr.linkedQ.toString()
                } else {
                  choiceStr.linkedQ = ''
                }
                this.autoSaveCount += 1
                this.updateChoices({
                  params: choiceStr,
                  config: axiosConfig.setHeader(this.getToken)
                }).then(() => {
                  this.autoSaveCount -= 1
                }).catch((err) => { 
                  alert('선택지 저장 실패')
                  throw Error(err)
                })
              }
            }
          })
        }
      }
    },
    updateChoiceItemForVue (choiceItem) {
      return this.updateChoices({
        params: choiceItem,
        config: axiosConfig.setHeader(this.getToken)
      })
    },
    updateChoiceItem (choiceItem) {
      this.updateChoices({
        params: choiceItem,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.autoSaveCount -= 1
      }).catch((err) => { 
        alert('선택지 저장 실패')
        throw Error(err)
      })
    },
    setSequence (isLinked) {
      let idx = 0
      this.isSectionTitleNull = false
      for (const section of this.sectionList) {
        let sectionNum = 0
        if (section.sectionSeq !== (idx + 1)) { section.sectionSeq = idx + 1 }
        for (const question of this.questionList) {
          if (section.sectionId === question.sectionId) {
            if (question.seqInSection !== sectionNum) question.seqInSection = sectionNum
            sectionNum += 1
          }
          if (question.choiceItems !== undefined && question.choiceItems.length > 0) {
            for (let i = 0; i < question.choiceItems.length; i++) {
              if (question.choiceItems[i].choiceItemSeq !== i) {
                if (question.questionType === 4 || question.questionType > 40) question.choiceItems[i].content = i + 1
                question.choiceItems[i].choiceValue = i
                question.choiceItems[i].choiceItemSeq = i
              }
            }
          }
        }
        if ((section.sectionTitle === undefined || section.sectionTitle === '') && !isLinked) {
          this.isSectionTitleNull = true
          return ''
        }
        idx += 1
      }
    },
    setQuestionIdx () {
      let index = 0
      for (const question of this.questionList) {
        if (question.questionType === 5 || question.questionType === 8 || question.questionType === 9) continue
        question['answerIdx'] = index
        index += 1
      }
      this.setSequence(false)
      this.autoSave(true, true)
    },
    async updateQuestionForSubPage (question) {
      question.lqid = question.questionId
      return await this.updateQuestion({
        params: question,
        config: axiosConfig.setHeader(this.getToken)
      })
    },
    async updateQuestionMethod (question) {
      question.lqid = question.questionId
      return await this.updateQuestion({
        params: question,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.autoSaveCount -= 1
      }).catch((err) => { 
        throw Error(err)
      })
    },
    async updateSectionMethod (section) {
      return await this.updateSection({
        params: section,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.autoSaveCount -= 1
      }).catch((err) => {
        throw Error(err)
      })
    },
    submit () {
      this.setQuestionIdx()
      if (this.selectSurvey.isAutoSeq) { this.questionSort() }
      let isSubmit = true
      if (this.isSectionTitleNull) {
        isSubmit = confirm('제목이 없는 페이지가 있습니다. 이대로 제출하시겠습니까?')
      }
      if (!isSubmit) return ''
      this.nullQuestion = []
      this.nullSection = []
      this.nullChecker()
      if (this.nullSection.length > 0) {
        alert ('질문이 없는 페이지가 있습니다.')
        return '' 
      } else if (this.questionList.length === 0) {
        alert('최소 1개의 질문은 작성해야 합니다')
        return ''
      }
      let isTrue = confirm('한 번 제출이 끝나면 텍스트 편집 외의 수정이 불가합니다. 설문지를 제출하시겠습니까?')
      if (isTrue) {
        this.changeIsNull(false)
        if (this.nullQuestion.length === 0 && !this.isNull && this.nullSection.length === 0) {
          this.changeIsNull(true)
          this.selectSurvey.isEditable = false
          this.selectSurvey.lsid = this.selectSurveyId
          this.updateSurvey({
            params: this.selectSurvey,
            config: axiosConfig.setHeader(this.getToken)
          }).then(() => {
            this.autoSaveCount -= 1
          }).catch((err) => { throw Error(err) })
          if (this.$route.name !== 'SurveyAdminFinish') this.$router.push({name: 'SurveyAdminFinish', params: {surveyId: this.selectSurveyId}})
        } else {
          this.changeIsNull(true)
        }
      }
    },
    nullChecker () {
      this.nullSection = _.cloneDeep(this.sectionList)
      for (const question of this.questionList) {
        let idx = _.findIndex(this.nullSection, {sectionId: question.sectionId})
        if (idx !== -1 && idx !== undefined) this.nullSection.splice(idx, 1)
        if (question.questionTitle === undefined || question.questionTitle === '') {
          if (question.questionType === 5 || question.questionType === 8 || question.questionType === 9) {
            this.pushNullQuestion(question)
          } else if (!question.displayNo || question.displayNo === '') {
            this.pushNullQuestion(question)
          }
        } else if (question.questionType === 1 || question.questionType === 2 || question.questionType === 4 || question.questionType === 6 || question.questionType > 40) {
          if (question.choiceItems.length < 2) {
            this.pushNullQuestion(question)
          } else {
            question.choiceItems.filter(choice => {
              if (choice.content === undefined || choice.content === '') {
                this.pushNullQuestion(question)
              }
            })
          }
        }
      }
    },
    pushNullQuestion (question) {
      this.nullQuestion.push(question)
      return ''
    },
    changeIsNull (isBoolean) {
      this.isNull = isBoolean
    },
    async updateSurveyMethod (selectSurvey) {
      return await this.updateSurvey({
        params: selectSurvey,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.autoSaveCount -= 1
      }).catch((err) => {
        alert('설문지 저장 실패')
        is = false
        throw Error(err)
      })
    },
    setChoiceItem (question, item, choiceIdx) {
      let choiceItem = _.cloneDeep(item)
      choiceItem.questionId = question.questionId
      choiceItem.choiceValue = choiceIdx
      choiceItem.choiceItemSeq = choiceIdx
      choiceItem.linkedQ = (choiceItem.linkedQ && choiceItem.linkedQ.length > 0) ? choiceItem.linkedQ.toString() : ''
      if (question.questionType === 4 || question.questionType > 40) choiceItem.content = choiceIdx + 1
      return choiceItem
    },
    autoSave (isSubmitBtn, isPreview, isSilence) {
      if (isPreview === undefined || !isPreview) this.autoSaveBoolean = true
      this.autoSaveCount = 0
      this.setSequence(true)
      try {
        // survey 객체 비교
        this.selectSurvey.startDate = this.startDate
        this.selectSurvey.endDate = this.endDate
        let isNeedSurvey = !_.isEqual(this.originSurvey, this.selectSurvey)
        if (isNeedSurvey) {
          this.selectSurvey.lsid = this.selectSurveyId
          this.autoSaveCount += 1
          this.updateSurveyMethod(this.selectSurvey)
        }

        // section 객체 리스트 비교
        let updateSectionList = _.differenceWith(this.sectionList.filter(el => el.sectionId), this.getOriginSectionList, _.isEqual)
        this.autoSaveCount += updateSectionList.length
        for (const section of updateSectionList) {
          this.updateSectionMethod(section)
        }

        if (isSubmitBtn) {
          // question 객체 리스트 비교
          for (let i = 0; i < this.questionList.length; i++) {
            if (!this.questionList[i].choiceItems) continue
            for (let j = 0; j < this.questionList[i].choiceItems.length; j++) {
              const diffLinkedQ = _.difference(this.questionList[i].choiceItems[j].linkedQ, this.questionList.map(el => el.questionId))
              _.remove(this.questionList[i].choiceItems[j].linkedQ, function (n) {
                return diffLinkedQ.includes(n)
              })
            }
          }
          let insertQuestionList = this.questionList.filter(el => !el.hasOwnProperty('questionId') || !el.questionId)
          let deleteQuestionList = _.differenceBy(this.getOriginQuestionList, this.questionList, 'questionId')
          let updateQuestionList = _.differenceWith(this.questionList.filter(el => el.questionId), this.getOriginQuestionList, _.isEqual)

          if (insertQuestionList.length > 0) {
            this.autoSaveCount += insertQuestionList.length
            for (const insertQuestion of insertQuestionList) {
              this.insertQuestion({
                params: insertQuestion,
                config: axiosConfig.setHeader(this.getToken)
              }).then(() => {
                this.autoSaveCount -= 1
              })
            }
          }
          if (deleteQuestionList.length > 0) {
            this.autoSaveCount += deleteQuestionList.length
            for (const deleteQuestion of deleteQuestionList) {
              this.deleteQuestion({
                questionId: deleteQuestion.questionId,
                config: axiosConfig.setHeader(this.getToken)
              }).then(() => {
                this.autoSaveCount -= 1
              })
            }
          }
          if (updateQuestionList.length > 0) {
            for (const question of updateQuestionList) {
              let originQuestion = _.cloneDeep(this.getOriginQuestionList.filter(el => el.questionId === question.questionId)[0])
              let insertChoiceItemList = question.choiceItems ? question.choiceItems.filter(el => !el.hasOwnProperty('lcid') || !el.lcid) : []
              let deleteChoiceItemList = []
              let updateChoiceItemList = []
              if (originQuestion) {
                deleteChoiceItemList = question.choiceItems ? _.differenceBy(originQuestion.choiceItems, question.choiceItems, 'lcid') : []
                updateChoiceItemList = question.choiceItems ? _.differenceWith(question.choiceItems.filter(el => el.lcid), originQuestion.choiceItems, _.isEqual) : []
              }
              let simpleQuestionObj = _.cloneDeep(question)
              delete simpleQuestionObj.choiceItems
              let simpleOriginQuestionObj = _.cloneDeep(originQuestion)
              if (originQuestion) {
                delete simpleOriginQuestionObj.choiceItems
              }
              let isNeedUpdateQuestion = originQuestion && !_.isEqual(simpleQuestionObj, simpleOriginQuestionObj)
  
              this.autoSaveCount += (deleteChoiceItemList.length + insertChoiceItemList.length + updateChoiceItemList.length + isNeedUpdateQuestion ? 1 : 0)
  
              if (deleteChoiceItemList.length > 0) {
                for (const deleteChoiceItem of deleteChoiceItemList) {
                  this.asyncDelChoice(deleteChoiceItem)
                }
              }
              for (let choiceIdx = 0; choiceIdx < insertChoiceItemList.length; choiceIdx++) {
                let choiceItem = this.setChoiceItem(question, insertChoiceItemList[choiceIdx], _.findIndex(question.choiceItems, insertChoiceItemList[choiceIdx]))
                this.submitChoiceMethod(choiceItem).then(() => {
                  this.autoSaveCount -= 1
                }).catch((err) => { throw Error(err) })
              }
              for (let choiceIdx = 0; choiceIdx < updateChoiceItemList.length; choiceIdx++) {
                let choiceItem = this.setChoiceItem(question, updateChoiceItemList[choiceIdx], _.findIndex(question.choiceItems, { lcid: updateChoiceItemList[choiceIdx].lcid }))
                this.updateChoiceItem(choiceItem)
              }
              if (isNeedUpdateQuestion) {
                this.updateQuestionMethod(simpleQuestionObj)
              }
            }
          }

          if ((updateQuestionList.length + insertQuestionList.length + deleteQuestionList.length + updateSectionList.length) === 0 && !isNeedSurvey) {
            if (!isSilence) alert('변경된 내용이 없습니다.')
            this.autoSaveBoolean = false
          }
          if (isSilence) this.setSurvey()
        }
      } catch (err) {
        console.log(err)
        this.autoSaveBoolean = false
        alert ('임시저장 오류')
      }
    },
    async setSurvey () {
      let myPage = this.currentSection
      await this.fetchSurveyList({
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        let selectSurveyList = this.getSurveyList.filter(survey => { return survey.surveyId === this.selectId })
        if (selectSurveyList && selectSurveyList.length > 0) {
          this.survey = _.cloneDeep(selectSurveyList[0])
          if (this.survey.maxResponseCnt.length === 0) {
            this.survey.isMaxResponseCnt = false
          } else {
            this.survey.isMaxResponseCnt = true
          }
          this.titleContent = this.survey.contents
          this.$refs.surveyInfo.setIsVisible(true)
          this.inputCnt = this.survey.maxResponseCnt
          this.isEditable = this.survey.isEditable
          this.selectSurveyId = this.selectId
          this.startDate = this.survey.startDate
          this.endDate = this.survey.endDate
          this.selectSurvey = _.cloneDeep(this.survey)
        }
        this.currentSection = myPage
        this.setSection(this.selectId)
      })
    },
    setSection (id) {
      this.fetchSectionList({
        surveyId: id,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        if (this.getSectionList === undefined || this.getSectionList.length === 0) {
          this.insertSection({
            params: { surveyId: this.selectSurveyId, sectionTitle: '', isUse: true, sectionSeq: 0 },
            config: axiosConfig.setHeader(this.getToken)
          }).then((response) => {
            this.setSection(id)
          }).catch((err) => { throw Error(err) })
        } else {
          this.updateSectionList()
          this.setQuestion(id)
        }
      }).catch((err) => { throw Error(err) })
    },
    updateSectionList () {
      if (this.getSectionList.filter(el => el.sectionId === this.currentSection).length === 0) this.currentSection = _.cloneDeep(this.getSectionList[0].sectionId)
      this.sectionList = _.cloneDeep(this.getSectionList)
    },
    setQuestion (id) {
      this.fetchQuestionList({
        surveyId: id,
        config: axiosConfig.setHeader(this.getToken)
      }).then(() => {
        this.isLoading = true
        this.questionList = _.cloneDeep(this.getQuestionList)
        this.linkModal()
      }).catch((err) => { throw Error(err) })
    },
  },
  components: {
    CheckPassword,
    CheckCount,
    'create-question': CreateQuestion,
    'create-section': CreateSection,
    'create-survey': CreateSurvey,
    'edit-survey': EditSurvey,
    'steppers': Steppers,
    'question-type-list': QuestionTypeList,
    'null-check-dialog': nullCheckDialog,
    'survey-date-range': SurveyDateRange
  },
  mounted () {
    this.isReady = false
    this.resetQuestionList()
    if (this.$route.params.surveyId && this.$route.params.surveyId != 0) {
      this.selectId = parseInt(this.$route.params.surveyId)
    } else {
      this.isReady = true
    }
  },
  watch: {
    autoSaveCount () {
      if (this.autoSaveBoolean && this.autoSaveCount === 0) {
        this.setSurvey(false)
        alert ('임시저장 완료')
      } else if (this.autoSaveCount === 0) {
        this.setSurvey(false)
      }
      if (this.autoSaveCount === 0) {
        this.autoSaveBoolean = false
      }
    },
    endCount: {
      deep: true,
      handler () {
        if (this.endCount === 0) {
          this.autoSave(true, true, true)
        }
      }
    },
    maxCount () {
      this.selectSurvey.maxResponseCnt = this.maxCount
    },
    selectSurvey () {
      if (!this.selectSurvey.isEditable && this.isFirst) {
        alert('선택하신 설문지는 제출 완료된 설문지이므로 텍스트 편집만 가능합니다.')
        this.isFirst = false
      }
    },
    isAutoSeq () {
      this.questionSort()
    },
    questionList () {
      this.questionSort()
    },
    sectionList () {
      this.questionSort()
    }
  }
}
</script>

<style lang="scss">
@import '../assets/style/variable.scss';
  .theme--light.v-expansion-panel{
    .v-expansion-panel__header {
      padding-left: 10px;
      .v-icon{
        font-size: 16px;
      }
    }
    .v-expansion-panel__body {
      padding-left: 20px;
    }
  }
  /* .list-header.header {
    position: relative;
    width: calc(100% + 72px);
    max-width: 100%;
    &:before {
      position: absolute;
      width: 100%;
      left: auto; right: 0;
    }
    .header-content {
      position: absolute;
      width: 100%; max-width: 1000px;
      padding-left: 16px;
      .nav {
        right: 0;
      }
    }
  } */
  @media (max-width: $tablet) {
    .list-header.header { display: none; }
    .layout-container { margin-top: 0 !important; }
  }
</style>

<style lang="scss" scoped>
@import '../assets/style/variable.scss';
  .layout-container{
    /* overflow-x: auto; */
    position: relative;
    /* &:before{
      display: block; content: ''; clear: both;
      position: absolute; width: 100%; height: 100%;
      background: url('../../static/images/high-angle-multiple-election-questionnaires.jpg') center no-repeat;
      background-size: cover;
      opacity: 0.5;
    } */
    .min-layout-wrap{
      width: 100%;
      margin: 0 auto;
      position: relative;
      max-width: 1185px;
      .flex-side-bar{
        max-width: 400px;
        box-sizing: border-box;
      }
      .flex-content{
        margin: 50px;
        margin-right: 0;
        .question-list-box{
          max-width: 1000px;
          box-sizing: content-box;
          margin-left: auto;
        }
      }
    }
  }

  .mobile-menu-back-btn,
  .mobile-menu-dialog-btn,
  .mobile-only{
    display: none;
  }

  @media (min-width: $tablet) {
    .layout-container {
      display: block;
      .min-layout-wrap {
        .flex-content {
          width: calc(100% - 32px) !important;
          margin: 24px 16px;
          box-sizing: border-box;
        }
        .flex-side-bar {
          display: none;
          height: 100vh;
        }
      }
    }
    .v-menu__content{
      display: block !important;
      top: 12px;
      left: 0px;
      transform-origin: left top;
    }
    .v-dialog__content {
      position: relative;
      display: block !important;
      width: 370px;
      height: calc(100vh - 90px);
      margin-top: 90px;
      z-index: 1 !important;
      ::v-deep .v-dialog{
        position: fixed;
        left: 0; top: 0;
        display: block !important;
        width: 370px;
        height: calc(100vh - 90px);
        margin: 90px 0 0 0;
        transition: none !important;
        max-height: 100%;
        box-shadow: -4px 6px 7px -7px rgba(0,0,0,.2), 2px 1px 10px 3px rgba(0,0,0,.14);
        .side-bar{
          height: calc(100vh - 90px);
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .mobile-only{
      display: block;
      position: absolute; left: 0; top: 0;
      height: 50px; width: 100%;
      background: #5AA3DD;
    }
    .mobile-menu-back-btn,
    .mobile-menu-dialog-btn {
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: #5AA3DD !important;
      box-shadow: none !important;
      &::before{
        display: none;
      }
      ::v-deep .v-btn__content{
        display: inline-block;
        text-align: left;
        i {
          color: #fff !important;
        }
      }
    }
    .mobile-menu-back-btn {  left: 24px; top: 50%; padding: 10px; padding-left: 0; transform: translateY(-50%); color: #fff; text-align: left;}
    .mobile-menu-dialog-btn { right: 0; text-align: right; }
    .v-btn--large {
      font-size: 14px;
      height: 34px;
      padding: 0px 0px;
    }
    .layout-container {
      margin-top: -42px;
      .min-layout-wrap {
        .flex-content {
          width: calc(100% - 32px) !important;
          margin: 70px 16px;
          box-sizing: border-box;
        }
      }
    }
  }
</style>

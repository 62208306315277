<template>
<v-container class="footer align-y-center">
  <div class="footer-content" text-xs-center>
    <span class="cursor-pointer p-r-10" @click="isPrivacyDialogOpen = true">이용약관</span>
    <span class="cursor-pointer" @click="isServiceDialogOpen = true">개인정보처리방침</span>
  </div>
  <v-dialog content-class="dialog-default bg-white scroll-design" max-width="500" max-height="400" v-model="isPrivacyDialogOpen">
    <v-card class="p-a-16 scroll-design" style="max-height: 400px;" flat>
      <privacy></privacy>
    </v-card>
  </v-dialog>
  <v-dialog content-class="dialog-default bg-white scroll-design" max-width="500" max-height="400" v-model="isServiceDialogOpen">
    <v-card class="p-a-16 scroll-design" style="max-height: 400px;" flat>
      <service></service>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import { accountMapGetters, accountMapActions } from '@/store/modules/account/account'
import privacy from '@/components/common/policy/privacy.vue'
import service from '@/components/common/policy/service.vue'

export default {
  components: {
    privacy,
    service
  },
  data () {
    return {
      isPrivacyDialogOpen: false,
      isServiceDialogOpen: false
    }
  },
  computed: {
    ...accountMapGetters(['getToken', 'getLogin']),
    items () {
      return [
        {
          title: '설문지 리스트',
          link: this.getLogin ? 'MySurveyList' : 'AdminLogin'
        },
        {
          title: this.getLogin ? 'Logout' : 'Login',
          link: this.getLogin ? 'AdminLogout' : 'AdminLogin'
        }
      ]
    }
  },
  methods: {
    ...accountMapActions(['logout']),
    movePage (pageName) {
      if (this.$route.name === pageName) return ''
      if (pageName === 'AdminLogout') {
        this.logout().then(() => {
          this.$router.push({name: 'SurveyMain'})
        }).catch((err) => { throw Error(err) })
      } else {
        this.$router.push({name: pageName})
      }
    }
  },
  mounted () {
    if (!this.getLogin) {
      this.$router.push({name: 'SurveyMain'})
    }
  }
}
</script>

<style lang="scss">
.menu-el{
  translate: ease-in-out 0.2s;
  &:hover {
    background-color: #ddd;
  }
}
.footer {
  height: 50px;
  flex: initial;
  &::before{
    display: block; content: ''; clear: both;
    position: fixed;
    width: 100vw; height: 50px;
    left: 0; bottom: 0;
    z-index: 9990;
  }
  &-content {
    position: fixed;
    left: 50%; bottom: 12px;
    width: 100vw;
    max-width: 1185px;
    transform: translate(-50%, -0%);
    z-index: 9999;
    .flat-btn {
      position: relative;
      top: 50%; right: 0;
      transform: translateY(-50%);
    }
    .nav{
      width: 100%;
      position: relative;
      li {
        display: inline-block;
        margin-left: 20px;
        font-size: 18px;
      }
    }
  }
}
</style>

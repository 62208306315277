<template>
  <v-dialog persistent
    :value="isOpenCheckCountDialog"
    v-if="isOpenCheckCountDialog"
    class="dialog-default"
    max-width="400"
  >
    <div class="bg-white p-x-16 p-b-16 p-t-10 border-radius-20" flat>
      <v-layout row class="fullscreen-dialog__header width-p-100">
        <div class="header-side-left visibility-hidden cursor-pointer">
        </div>
        <v-spacer></v-spacer>
        <div class="header-title headline">{{ `설문지 제출 ${ result.isPossible ? '완료' : '실패'}` }}</div>
        <v-spacer></v-spacer>
      </v-layout>
      <div v-if="result.isPossible">
        <p class="subheading text-align-center m-t-30">설문지를 제출하시겠습니까?</p>
      </div>
      <p v-else class="subheading text-align-center m-t-10">설문지를 제출할 수 없습니다.<br>이번달 제출 횟수를 모두 사용하셨습니다.</p>
      <div class="m-t-16 p-a-16" style="border: 1px solid #ddd; border-radius: 12px;">
        <p class="title p-b-16" style="border-bottom: 1px solid #ddd;">이번달 제출 건수: {{ `${result.submitCnt} / ${result.totalCnt} 회` }}</p>
        <p class="subheading m-b-0">요금제: {{ result.appRatePlanName }}</p>
        <p class="subheading m-b-0">기간: {{ `${result.startDate} ~ ${result.endDate}` }}</p>
      </div>
      <!-- <v-layout class="p-t-10">
        <v-flex class="m-r-16" xs12>
          <v-btn large class="width-p-100" @click="result.isPossible ? $emit('checkSubscribeCnt', null, true) : $emit('close')" color="primary" depressed>{{ result.isPossible ? '제출' : '닫기' }}</v-btn>
        </v-flex>
      </v-layout> -->
      <v-layout class="p-t-10">
        <v-flex v-if="result.isPossible" class="m-r-16" xs6>
          <v-btn large class="width-p-100 bg-lightgray" @click="$emit('close')" depressed>취소</v-btn>
        </v-flex>
        <v-flex :class="`m-r-16 ${result.isPossible ? 'xs6' : 'xs12'}`">
          <v-btn large class="width-p-100" @click="result.isPossible ? $emit('checkSubscribeCnt', null, true) : $emit('close')" color="primary" depressed>{{ result.isPossible ? '제출' : '닫기' }}</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-dialog>
</template>

<script>
import { accountMapGetters } from '@/store/modules/account/account'

export default {
  props: {
    isOpenCheckCountDialog: Boolean,
    result: Object
  },
  data () {
    return {
      isPasswordMode: true,
      password: null
    }
  },
  watch: {
    isOpenCheckCountDialog () {
      if (!this.isOpenCheckCountDialog) {
        this.isPasswordMode = true
        this.password = null
      }
    }
  },
  computed: {
    ...accountMapGetters(['getToken', 'getLoginId'])
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
</style>

<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card class="p-y-16">
        <div v-for="section in sections" :key="'dialog' + section.sectionId">
          <!-- <p class="m-y-16" v-if="section.sectionTitle === '' || section.sectionTitle === undefined">
            {{section.sectionSeq}} 페이지의 제목이 미완성 상태입니다.
          </p> -->
          <v-flex v-for="question in nullQuestion" :key="'dialog' + question.questionId">
            <p class="m-y-16" v-if="question.sectionId === section.sectionId">
              {{section.sectionSeq}} 페이지의 {{question. seqInSection + 1}} 번째 질문이 미완성 상태입니다.
            </p>
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'nullCheckDialog',
  props: {
    nullQuestion: Array,
    isNull: Boolean,
    sections: Array,
    changeIsNull: Function
  },
  data () {
    return {
      dialog: this.isNull
    }
  },
  watch: {
    dialog () {
      this.changeIsNull(this.dialog)
    }
  }
}
</script>

<template>
  <v-layout style="padding: 10px 0 0 !important;" class="input-flex">
    <v-flex class="input-flex" v-if="question.answers === undefined" wrap>
      <v-flex class="input-flex">
        <v-text-field v-if="question.questionType === 31"
          class="text-field"
          label="핸드폰 번호를 입력하세요"
        ></v-text-field>
      </v-flex>
    </v-flex>
    <v-flex class="input-flex" v-else-if="question.questionType === 31" wrap>
      <v-text-field
        outline
        @change="insertTxt(question, true)"
        :rules="textRules"
        v-model.trim="question.answers"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'AnswerPhoneText',
  props: {
    question: Object,
    subAnswer: Object,
    insertTxt: Function,
    currentAnswer: Array
  },
  data () {
    return {
      textRules: [
        v => !(/[¸|ː|§]/.test(v)) || '해당 특수문자는 사용할 수 없습니다.'
      ]
    }
  },
  watch: {
    currentAnswer () {
      this.$set(this.question, 'answers', this.currentAnswer)
      this.$forceUpdate()
    }
  }
}
</script>

<style scope>
  .text-input{
    box-sizing: border-box;
  }
  .v-text-field--outline input {
    margin-top: 10px;
  }
</style>
